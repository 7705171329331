const getPending = (data) => {
  return data.filter(
    (lr) =>
      lr.reviewerDecision === null ||
      (lr.reviewerDecision === 1 && lr.businessTripRequest.isProjectManagerApproved === null)
  );
};

const getApproved = (data) => {
  return data?.filter((lr) => lr.reviewerDecision === 1 && lr.businessTripRequest.isProjectManagerApproved === 1);
};

const getRejected = (data, projectManagerFlag) => {
  if (projectManagerFlag) {
    return data?.filter((lr) => lr.isProjectManagerApproved === 0);
  } else {
    return data?.filter((lr) => lr.reviewerDecision === 0 || lr.businessTripRequest?.isProjectManagerApproved === 0);
  }
};

export default {
  getPending,
  getApproved,
  getRejected
};
