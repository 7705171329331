import { Card, Row, Typography } from 'antd';
import { message } from 'app';
import { useSelector } from 'react-redux';
import { ConnectionBadge } from './websocket-connection-badge';

const { Text } = Typography;

const MenuCard = () => {
  const { entityName, mainColor } = useSelector((state) => state.appConfiguration);
  const status = useSelector((state) => state.common.socketConnection);

  return (
    <Card
      size="small"
      bordered
      style={{ fontSize: 16 }}
      headStyle={{ backgroundColor: mainColor }}
      title={
        <Text style={{ color: 'white' }}>{process.env.REACT_APP_APP_VERSION + window.env.REACT_APP_APP_TYPE}</Text>
      }
    >
      <Card.Meta
        title={
          <Row style={{ width: '200px' }} justify="space-between" align="middle">
            <Text style={{ color: mainColor }}>{entityName}</Text>
            <ConnectionBadge status={status} />
          </Row>
        }
      />
    </Card>
  );
};

export default MenuCard;
