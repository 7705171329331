import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const pendingEmployeeServices = {
  getPendingEmployees() {
    return axios.get(API_PATH + '/ldap/getListOfLdapAndDbUsers');
  },
  registerEmployee(payload) {
    return {
      data: axios.post(API_PATH + '/employee', payload.data),
      nav: payload.nav
    };
  },
  disactivateEmployee(payload) {
    return {
      data: axios.put(API_PATH + '/employee/deactivate', payload.data),
      nav: payload.nav
    };
  }
};
