import { all, put, takeLatest } from 'redux-saga/effects';
import { ssoUrl } from 'store/service';
import { fetchSaga } from 'utils/fetch-saga';
import { currentUserServices } from './current-user.services';
import { currentUserActions } from './current-user.slice';

export function* currentUserRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getUserInfo',
      actions: currentUserActions,
      api: {
        method: currentUserServices.getUserInfo,
        callback: (res) => {
          return res.data.data;
        }
      }
    }),
    fetchSaga({
      pattern: 'getMySubordinates',
      actions: currentUserActions,
      api: {
        method: currentUserServices.getMySubordinates,
        callback: (res) => {
          return res.data.data;
        }
      }
    }),
    fetchSaga({
      pattern: 'validateSession',
      actions: currentUserActions,
      api: {
        method: currentUserServices.validateSession,
        callback: (res) => res
      }
    }),
    fetchSaga({
      pattern: 'validateTicket',
      actions: currentUserActions,
      api: {
        method: currentUserServices.validateTicket,
        callback: (res) => res
      }
    }),
    takeLatest(currentUserActions.validateTicketFulfilled.type, dispatchPatchValidateSession),
    takeLatest(currentUserActions.validateSessionFulfilled.type, dispatchRedirectOrEnter)
  ]);
}

function* dispatchPatchValidateSession() {
  yield put(currentUserActions.validateSession());
}

function* dispatchRedirectOrEnter({ payload }) {
  if (!payload.data) {
    window.location.assign(ssoUrl);
  }
}
