import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { disciplinesServices } from './disciplines.services';
import { disciplinesActions } from './disciplines.slice';

export function* disciplinesRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getDisciplinesAll',
      actions: disciplinesActions,
      api: {
        method: disciplinesServices.getDisciplinesAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postDiscipline',
      actions: disciplinesActions,
      api: {
        method: disciplinesServices.postDiscipline,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchDiscipline',
      actions: disciplinesActions,
      api: {
        method: disciplinesServices.patchDiscipline,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteDiscipline',
      actions: disciplinesActions,
      api: {
        method: disciplinesServices.deleteDiscipline,
        callback: (res) => res.data.data
      }
    })
  ]);
}
