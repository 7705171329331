import { InfoCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { notificationsActions } from 'store/notifications';
import { APP_PATH } from 'store/service';

const showDesktopNotification = (firstNotification) => {
  const notification = new Notification('New Notification from ERP!', {
    body: firstNotification
      ? "You've enabled ERP desktop notifications, Thank You!"
      : "You've got a new notification in ERP",
    icon: 'https://www.assystem.com/wp-content/themes/assystem/assets/images/logo-header.svg'
  });
  // navigate to a URL when clicked
  notification.addEventListener('click', () => {
    notification.close();
    window.parent.focus();
  });
};

const openNotification = () => {
  notification.open({
    message: 'New Notification',
    description: 'You have recieved a new notification.',
    // TODO: Open notification list onClick
    onClick: () => {
      console.log('Notification Clicked!');
    },
    duration: 5,
    placement: 'bottomRight',
    icon: (
      <InfoCircleOutlined
        style={{
          color: '#108ee9'
        }}
      />
    )
  });
};

export const newNotificationHook = (notification, dispatch) => {
  const message = JSON.parse(notification._body);
  openNotification();
  if (Notification.permission === 'granted') {
    showDesktopNotification();
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        showDesktopNotification('firstNotification');
      }
    });
  }
  dispatch(notificationsActions.receiveNewNotification(message));
  return;
};
