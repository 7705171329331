import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { currencyExchangeServices } from './currency-exchange.service';
import { currencyExchangeActions } from './currency-exchange.slice';

export function* currencyExchangeRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getExchanges',
      actions: currencyExchangeActions,
      api: {
        method: currencyExchangeServices.getExchanges,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'addExchange',
      actions: currencyExchangeActions,
      api: {
        method: currencyExchangeServices.postExchange,
        callback: (res) => res.data.data
      },
      config: {
        disableLoading: true
      }
    }),
    fetchSaga({
      pattern: 'editExchange',
      actions: currencyExchangeActions,
      api: {
        method: currencyExchangeServices.patchExchange,
        callback: (res) => res.data.data
      },
      config: {
        disableLoading: true
      }
    })
  ]);
}
