import { Divider, Skeleton } from 'antd';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { notificationsActions } from 'store/notifications';
import NotificationList from './notifications-list';

// ==============================|| NOTIFICATION LIST WITH INFINITE SCROLL ||============================== //

const NotificationsWithInfiniteScroll = ({
  unreadNotificationsAll,
  unreadNotifications,
  notificationsPaging,
  handleClose
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const loadMoreData = () => {
    if (loading) {
      return true;
    }
    setLoading(true);
    dispatch(
      notificationsActions.getAllUnreadNotifications({
        page:
          notificationsPaging.pageNumber < notificationsPaging.totalPages - 1
            ? notificationsPaging.pageNumber + 1
            : notificationsPaging.totalPages - 1,
        size: 10
      })
    );

    setLoading(false);
  };

  return (
    <div
      id="scrollableDiv"
      style={{
        height: unreadNotifications.length > 0 ? 400 : 200,
        overflow: 'auto'
      }}
    >
      <InfiniteScroll
        dataLength={unreadNotificationsAll.length}
        next={loadMoreData}
        hasMore={unreadNotificationsAll.length < notificationsPaging.totalItems}
        loader={
          <Skeleton
            avatar
            paragraph={{
              rows: 1
            }}
            active
          />
        }
        endMessage={unreadNotificationsAll.length > 0 ? <Divider plain>No more new notifications</Divider> : ''}
        scrollableTarget="scrollableDiv"
      >
        <NotificationList
          unreadNotificationsAll={unreadNotificationsAll}
          unreadNotifications={unreadNotifications}
          handleClose={handleClose}
        />
      </InfiniteScroll>
    </div>
  );
};

export default NotificationsWithInfiniteScroll;
