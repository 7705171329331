import { projectStatusServices } from './project-status.services';
import { fetchSaga } from 'utils/fetch-saga';
import { all } from 'redux-saga/effects';
import { projectStatusActions } from './project-status.slice';

export function* projectStatusRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getProjectStatuses',
      actions: projectStatusActions,
      api: {
        method: projectStatusServices.getProjectStatuses,
        callback: (res) => res.data.data
      }
    })
  ]);
}
