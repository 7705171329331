import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const managerDelegationServices = {
  getManagerDelegationsAll() {
    return axios.get(API_PATH + '/managerDelegation');
  },
  postManagerDelegation(payload) {
    return axios.post(API_PATH + '/managerDelegation', payload);
  },
  patchManagerDelegation(payload) {
    return axios.patch(API_PATH + '/managerDelegation/' + payload.id, payload);
  }
};
