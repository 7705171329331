import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { projectVerticalOptionsServices } from './project-vertical-option.services';
import { projectVerticalOptionsActions } from './project-vertical-option.slice';

export function* projectVerticalOptionRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getProjectVerticalOptionsAll',
      actions: projectVerticalOptionsActions,
      api: {
        method: projectVerticalOptionsServices.getProjectVerticalOptionsAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postProjectVerticalOption',
      actions: projectVerticalOptionsActions,
      api: {
        method: projectVerticalOptionsServices.postProjectVerticalOption,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchProjectVerticalOption',
      actions: projectVerticalOptionsActions,
      api: {
        method: projectVerticalOptionsServices.patchProjectVerticalOption,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteProjectVerticalOption',
      actions: projectVerticalOptionsActions,
      api: {
        method: projectVerticalOptionsServices.deleteProjectVerticalOption,
        callback: (res) => res.data.data
      }
    })
  ]);
}
