import { Checkbox } from 'antd';
import { useDispatch } from 'react-redux';
import { caslRolesPrivilegesActions } from 'store/casl-roles-privileges/casl-roles-privileges.slice';

const OnePrivilegeAllRolesCheckbox = ({ object, permission, data, roles, isChecked }) => {
  const dispatch = useDispatch();

  // checkbox to dispatch one privilege for all roles
  const handleOnePrivilegeForAllRolesSelect = (evt) => {
    const allChecked = isChecked[permission.privilege] === roles.length;

    if (!allChecked) {
      let finalObjects = [];

      data.forEach((role) => {
        const isRoleChecked = role.permissions.some(
          (perm) =>
            perm.object === object &&
            perm.privileges.some((permissionPrivilege) => permissionPrivilege.privilege === permission.privilege)
        );
        if (
          !isRoleChecked &&
          role.caslRoleName !== 'ROLE_SUPERADMIN' &&
          (object !== 'rolesSettings' || object !== 'permissionSettings')
        )
          finalObjects.push({
            object,
            caslRoleId: role.role,
            privilege: permission.privilege
          });
      });

      dispatch(caslRolesPrivilegesActions.postCaslRolesPrivileges(finalObjects));
      return;
    }
    // send here list of objects to delete
    const relationIds = [];
    roles.map((role) => {
      const relationid = data
        .find((v) => v.role === role.caslRoleId)
        ?.permissions.find((p) => p.object === object)
        ?.privileges.find((priv) => priv.privilege === permission.privilege).id;
      if (role.caslRoleName !== 'ROLE_SUPERADMIN' && (object !== 'rolesSettings' || object !== 'permissionSettings')) {
        relationIds.push(relationid);
      }
    });
    dispatch(caslRolesPrivilegesActions.deleteCaslRolesPrivilege(relationIds));
  };

  return (
    <Checkbox
      onChange={handleOnePrivilegeForAllRolesSelect}
      checked={isChecked[permission.privilege] === roles.length}
      indeterminate={isChecked[permission.privilege] > 0 && isChecked[permission.privilege] < roles.length}
    >
      {permission.title}
    </Checkbox>
  );
};

export default OnePrivilegeAllRolesCheckbox;
