import { PlusCircleOutlined } from '@ant-design/icons';
import { useFormikContext } from 'formik';
import { AddRowButton } from 'formik-antd';
import { getNewEmptyTimesheetEntryPerActivity } from '../../utils';

export const AddEntry = () => {
  const { values } = useFormikContext();

  const entryPerActivitiesId = values.timeSheetEntryPerActivities?.map((activity) => activity.id) || [];
  const lastActivityId = entryPerActivitiesId[entryPerActivitiesId.length - 1];

  return (
    <AddRowButton
      name="timeSheetEntryPerActivities"
      style={{ display: 'block', margin: '12px auto' }}
      createNewRow={() => {
        return getNewEmptyTimesheetEntryPerActivity(lastActivityId < 0 ? lastActivityId - 1 : -1);
      }}
      icon={<PlusCircleOutlined style={{ color: 'green' }} />}
    >
      AddEntry
    </AddRowButton>
  );
};
