import { ToolOutlined } from '@ant-design/icons';
import { Line } from '@ant-design/plots';
import { Select, Statistic } from 'antd';
import { format } from 'date-fns';
import { currenciesinfo } from 'utils/currency-list';
import { HistoryOpen } from './../history-open';

const getCurrencyObj = (id, currencies) => {
  return currencies.find((currency) => currency.id === id);
};

export const getColumns = (currencies, currenciesOptions, selectedCurrency, onChange) => [
  {
    title: (
      <Select
        value={selectedCurrency}
        options={currenciesOptions.map((currency) => ({ value: currency.id, label: currency.name }))}
        onChange={onChange}
        style={{ width: '100%' }}
      />
    ),
    width: 170,
    dataIndex: 'toCurrency',
    render(item) {
      const currency = getCurrencyObj(item, currencies);
      return (
        <Statistic
          title={currency.name}
          value={
            currency.code + ' ' + currenciesinfo.find((currencyInfo) => currencyInfo.code === currency.code).symbol
          }
        />
      );
    }
  },
  {
    title: 'Rate',
    dataIndex: 'conversionRate'
  },
  {
    title: 'Valid from',
    dataIndex: 'date',
    render(date) {
      return format(new Date(date), 'dd-MM-yyyy');
    }
  },
  {
    title: 'History',
    dataIndex: 'history',
    width: '40%',
    align: 'center',
    render(history) {
      return (
        <div style={{ height: 100 }}>
          <Line
            autoFit={false}
            stepType="vh"
            data={history.map((item) => ({
              ...item,
              rate: item.conversionRate,
              date: format(new Date(item.date), 'dd-MM-yyyy')
            }))}
            tooltip={{ fields: ['date', 'rate'], showTitle: false, showMarkers: false }}
            xField="date"
            yField="rate"
            seriesField="isActive"
            legend={false}
            point={{
              style: (item) => ({
                fill: item.isActive ? '#ffeeb5' : '#6395F9',
                stroke: item.isActive ? '#ffc400' : ''
              })
            }}
            lineStyle={{ stroke: '#6395F9' }}
          />
        </div>
      );
    }
  },
  {
    title: <ToolOutlined />,
    align: 'center',
    render(_, item) {
      return <HistoryOpen item={item} />;
    }
  }
];
