import { Client } from '@stomp/stompjs';
import { AppLoader } from 'app-loader';
import { AppPrivate } from 'app-private';
import { AppPublic } from 'app-public';
import useAuthHook from 'hooks/useAuthHook';
import { newNotificationHook } from 'hooks/useNewNotificationHook';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appConfigurationActions } from 'store/app-configuration';
import { commonActions } from 'store/common/common.slice';

const App = () => {
  const dispatch = useDispatch();

  const ready = useSelector((state) => state.common.ready);
  const user = useSelector((state) => state.currentUser.user);
  const { sessionValid } = useAuthHook();

  // websocket connection
  const [message, setMessage] = useState('You server message here.');

  const onConnected = () => {
    dispatch(commonActions.setSocketConnection(true));
    client.subscribe('/user/specific', function (msg) {
      if (msg.body) {
        newNotificationHook(msg, dispatch);
      } else {
        setMessage('No Message');
      }
    });
  };

  const onDisconnect = () => {
    dispatch(commonActions.setSocketConnection(false));
  };

  const onMessageReceived = (msg) => {
    setMessage(msg.message);
  };

  const client = new Client({
    brokerURL: window.env.REACT_APP_API_WEBSOCKET_BROKER,
    reconnectDelay: 5000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
    onConnect: onConnected,
    onDisconnect: onDisconnect
  });

  useEffect(() => {
    // Websocket activate connection
    client.activate();

    // App configuration
    dispatch(appConfigurationActions.getAppConfiguration());

    // Destroy Webscoket connection on app close
    return () => client.deactivate();
  }, []);
  if (!sessionValid || !user) {
    return <AppPublic />;
  }

  if (!ready) {
    return <AppLoader />;
  }

  return (
    // <StompSessionProvider url={`http://localhost/api/notification`}>
    <AppPrivate />
    // </StompSessionProvider>
  );
};

export default App;
