import { Checkbox, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { caslRolesPrivilegesActions } from 'store/casl-roles-privileges/casl-roles-privileges.slice';
import { checkCheckedOneRoleAllObjectPermissions } from '../utils/check-checked-checkbox';
import { checkIndeterminateOneRoleAllObjectPermissions } from '../utils/check-indeterminate-checkbox';

const OneRoleAllObjectPermissionsCheckbox = ({ group, record, data, groupKeys }) => {
  const dispatch = useDispatch();

  // checkbox to dispatch all privileges in one object for one role
  const handleObjectSelect = (evt) => {
    const object = group.id;
    const role = record.role;
    const relationId = data?.permissions.find((p) => p.object === group.id)?.privileges.map((pr) => pr.id);

    const pers = groupKeys.find((group) => group.id === object).permissions;
    let finalObjects = [];

    const allChecked = group.permissions.every((pr) => {
      const perm = record.permissions.find((pe) => pe.object === group.id);
      return perm?.privileges.find((priv) => priv.privilege === pr.privilege);
    });

    const initialValues = data?.permissions.find((p) => p.object === object)?.privileges.map((priv) => priv.privilege);

    if (!allChecked) {
      pers.map((per) => {
        finalObjects.push({
          object: object,
          caslRoleId: role,
          privilege: per.privilege
        });
      });
      let finalValues = finalObjects.filter((x) => !initialValues?.includes(x.privilege));
      dispatch(caslRolesPrivilegesActions.postCaslRolesPrivileges(finalValues));
    } else {
      dispatch(caslRolesPrivilegesActions.deleteCaslRolesPrivilege(relationId));
    }
  };

  return (
    <Checkbox
      onClick={(e) => e.stopPropagation()}
      onChange={handleObjectSelect}
      disabled={
        record.caslRoleName === 'ROLE_SUPERADMIN' && (group.id === 'rolesSettings' || group.id === 'permissionSettings')
      }
      checked={
        record.permissions.find((pe) => pe.object === group.id)
          ? checkCheckedOneRoleAllObjectPermissions(record, group)
          : false
      }
      indeterminate={
        record.permissions.find((pe) => pe.object === group.id)
          ? checkIndeterminateOneRoleAllObjectPermissions(record, group)
          : false
      }
    >
      <Typography.Text>{group.name}</Typography.Text>
    </Checkbox>
  );
};

export default OneRoleAllObjectPermissionsCheckbox;
