// material-ui
import { Chip, ClickAwayListener, Divider, Grid, Paper, Popper, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/main-card';
import Transitions from 'ui-component/extended/transitions';

// assets
import { useState } from 'react';
import { useSelector } from 'react-redux';
import NotificationsWithInfiniteScroll from './components/notification-list-infinite-scroll';
import useNotificationHook from './hooks/notificationHook';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = ({ handleClose, anchorRef, open, setOpen }) => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const [showAll, setShowAll] = useState(false);
  const { unreadNotificationsAll, unreadNotifications, notificationsPaging } = useNotificationHook();

  const toggleShowAll = (value) => {
    setShowAll((value) => !value);
  };

  return (
    <Popper
      placement={matchesXs ? 'bottom' : 'bottom-end'}
      open={open}
      style={{ width: '325px' }}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      popperOptions={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [matchesXs ? 5 : 0, 20]
            }
          }
        ]
      }}
    >
      {({ TransitionProps }) => (
        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                      <Grid item>
                        <Stack alignItems="center" style={{ height: '10px' }} direction="row" spacing={2}>
                          <Typography variant="subtitle1">Notifications</Typography>
                          {notificationsPaging.totalItems > 0 && (
                            <Chip
                              size="small"
                              // TODO: uncomment if 'View All Notifications' feature will become needed
                              // label={showAll ? allNotifications.length : notificationsPaging.totalItems}
                              label={notificationsPaging.totalItems}
                              sx={{
                                color: theme.palette.background.default,
                                bgcolor: theme.palette.warning.dark
                              }}
                            />
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 205px)', overflowX: 'hidden' }}>
                      <Grid item xs={12} p={0}>
                        <Divider sx={{ my: 0 }} />
                      </Grid>
                      <NotificationsWithInfiniteScroll
                        unreadNotificationsAll={unreadNotificationsAll}
                        unreadNotifications={unreadNotifications}
                        notificationsPaging={notificationsPaging}
                        showAll={showAll}
                        handleClose={handleClose}
                      />
                    </PerfectScrollbar>
                  </Grid>
                </Grid>
                {/* TODO: uncomment if 'View All Notifications' feature will become needed
                <Divider />
                <CardActions sx={{ p: 1.25, justifyContent: 'center' }}>
                  <Button
                    size="small"
                    disableElevation
                    onClick={() => {
                      toggleShowAll(true);
                      if (!showAll) {
                        dispatch(notificationsActions.getAllReadNotifications());
                      }
                    }}
                  >
                    {showAll ? 'Show New' : 'View All'}
                  </Button>
                </CardActions> */}
              </MainCard>
            </ClickAwayListener>
          </Paper>
        </Transitions>
      )}
    </Popper>
  );
};

export default NotificationSection;
