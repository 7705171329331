import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  paymentsByInvoice: [],
  editingPayment: null
};

export const { actions: invoicePaymentsActions, reducer: invoicePaymentsReducer } = createSlice({
  name: 'invoicePayments',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getInvoicePaymentsByInvoiceId(state) {
      state.loading = true;
    },
    getInvoicePaymentsByInvoiceIdFulfilled(state, action) {
      state.paymentsByInvoice = action.payload;
    },
    postInvoicePayment(state) {
      state.loading = true;
    },
    postInvoicePaymentFulfilled(state, action) {
      state.paymentsByInvoice = [...state.paymentsByInvoice, action.payload];
    },
    setEditingInvoicePayment(state, { payload }) {
      state.editingPayment = payload;
    },
    patchInvoicePayment(state) {
      state.loading = true;
    },
    patchInvoicePaymentFulfilled(state, action) {
      const arr = [...state.paymentsByInvoice];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      state.editingPayment = null;
      state.paymentsByInvoice = arr;
    },
    deleteInvoicePayment(state) {
      state.loading = true;
    },
    deleteInvoicePaymentFulfilled(state, action) {
      const updatedPayments = state.paymentsByInvoice.filter((payment) => payment.id != action.payload.id);
      return {
        ...state,
        paymentsByInvoice: [...updatedPayments]
      };
    }
  }
});
