import { Form, InputNumber } from 'formik-antd';
import { useTimesheet } from '../../hooks';

export const HoursCell = ({ entryIndex, dayIndex }) => {
  const { weekInfo } = useTimesheet();

  return (
    <Form.Item
      style={{ margin: 0 }}
      name={`timeSheetEntryPerActivities.${entryIndex}.dayActivityLinks.${dayIndex}.manHours`}
    >
      <InputNumber
        name={`timeSheetEntryPerActivities.${entryIndex}.dayActivityLinks.${dayIndex}.manHours`}
        max={weekInfo[dayIndex].maxHoursPerDay}
        min={0}
        disabled={!weekInfo[dayIndex].status}
      />
    </Form.Item>
  );
};
