import OBJECTS from 'casl/objects';
import { all, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { budgetServices } from './budget.services';
import { budgetActions } from './budget.slice';

export function* budgetRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getAllBudgetsByWorkpackageId',
      actions: budgetActions,
      api: {
        method: budgetServices.getAllBudgetsByWorkpackageId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'submitNewBudget',
      actions: budgetActions,
      api: {
        method: budgetServices.submitNewBudget,
        callback: (res) => {
          return { data: res.data, nav: res.nav, setStep: res.setStep };
        }
      }
    }),
    fetchSaga({
      pattern: 'deleteBudget',
      actions: budgetActions,
      api: {
        method: budgetServices.deleteBudget,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postSubcontractorTechnicalProgress',
      actions: budgetActions,
      api: {
        method: budgetServices.postSubcontractorTechnicalProgress,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'post3rdPartyRealValue',
      actions: budgetActions,
      api: {
        method: budgetServices.post3rdPartyRealValue,
        callback: (res) => res.data.data
      }
    }),
    takeLatest(budgetActions.submitNewBudgetFulfilled.type, dispatchSubmitSuccess)
  ]);
}

function* dispatchSubmitSuccess(action) {
  const setStep = action.payload.setStep;
  const navigate = action.payload.nav;
  const budget = yield action.payload.data;
  setStep(1);
  navigate(
    OBJECTS({}).WORKPACKAGES_GROUP.childrenObjects.WORKPACKAGE_DETAILS.path + '/' + budget.data.data.subproject.id,
    {
      replace: true
    }
  );
}
