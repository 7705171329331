export const calculateWeekTargetHours = (weekSettings, timesheetEntries) => {
  const weekInfo = weekSettings.map((day, dayIndex) => ({
    ...day,
    workedHours: timesheetEntries
      ? timesheetEntries.reduce((accumulator, activity) => {
          return accumulator + activity.dayActivityLinks[dayIndex]?.manHours || 0;
        }, 0)
      : 0
  }));

  const weekTargetHours =
    weekSettings?.filter((d) => d.status).reduce((partialSum, day) => partialSum + day.dayTargetHours, 0) +
    (weekInfo
      ? weekInfo.reduce((accumulator, day) => (day.isOpen ? accumulator : accumulator + day.workedHours), 0)
      : 0);

  return weekTargetHours;
};
