import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const timesheetSettingsServices = {
  getMyTimesheetSettings() {
    return axios.get(API_PATH + '/timesheetSetting/myTimesheetSettings');
  },
  getMasterTimesheetSettings(employeeId) {
    return axios.get(API_PATH + '/timesheetSetting/timesheetSettingsByEmployeeId', {
      params: {
        employeeId,
        isMaster: true
      }
    });
  },
  getImpersonateTimesheetSettings(employeeId) {
    return axios.get(API_PATH + '/timesheetSetting/timesheetSettingsByEmployeeId', {
      params: {
        employeeId,
        isMaster: false
      }
    });
  },
  getTimesheetSettings() {
    return axios.get(API_PATH + '/timesheetSetting/all');
  },
  postNewSettings(payload) {
    return axios.post(API_PATH + '/timesheetSetting', payload);
  },
  putSettings(payload) {
    return axios.patch(API_PATH + '/timesheetSetting', payload);
  },
  patchTimesheetSettingDays({ timesheetSettings }) {
    return axios.patch(API_PATH + '/timesheetSettingDay', { timesheetSettings });
  },
  deleteSetting(payload) {
    return axios.delete(API_PATH + '/timesheetSetting', {
      data: payload
    });
  },
  updateMyTimesheetSettingsFromNotificationResponse(payload) {
    return payload;
  }
};
