import { fetchSaga } from 'utils/fetch-saga';
import { overtimeRequestServices } from './overtime-request.services';
import { overtimeRequestActions } from './overtime-request.slice';
import { all, put, takeLatest } from 'redux-saga/effects';
import { timesheetActions } from 'store/time-sheet/time-sheet.slice';

export function* overtimeRequestRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getAllApprovedOvertimeRequests',
      actions: overtimeRequestActions,
      api: {
        method: overtimeRequestServices.getAllApprovedOvertimeRequests,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getAllPendingOvertimeRequestsByManagerId',
      actions: overtimeRequestActions,
      api: {
        method: overtimeRequestServices.getAllPendingOvertimeRequestsByManagerId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getNonPendingOvertimeRequestsByManagerIId',
      actions: overtimeRequestActions,
      api: {
        method: overtimeRequestServices.getNonPendingOvertimeRequestsByManagerIId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getAllOvertimeRequestsByEmployeeId',
      actions: overtimeRequestActions,
      api: {
        method: overtimeRequestServices.getAllOvertimeRequestsByEmployeeId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getOvertimeRequestById',
      actions: overtimeRequestActions,
      api: {
        method: overtimeRequestServices.getOvertimeRequestById,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'submitOvertimeRequest',
      actions: overtimeRequestActions,
      api: {
        method: overtimeRequestServices.submitOvertimeRequest,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchOvertimeRequest',
      actions: overtimeRequestActions,
      api: {
        method: overtimeRequestServices.patchOvertimeRequest,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'managerDecision',
      actions: overtimeRequestActions,
      api: {
        method: overtimeRequestServices.managerDecision,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteOvertime',
      actions: overtimeRequestActions,
      api: {
        method: overtimeRequestServices.deleteOvertime,
        callback: (res) => res.data.data
      }
    }),
    takeLatest(overtimeRequestActions.managerDecisionFulfilled.type, dispatchReloadOnManagerDecision),
    takeLatest(overtimeRequestActions.getOvertimeRequestByIdFulfilled.type, dispatchDaysByWeekIdToDisplayInRangePicker)
  ]);
}

function* dispatchReloadOnManagerDecision(action) {
  const managerId = action.payload.approvedBy;
  yield put(overtimeRequestActions.getAllPendingOvertimeRequestsByManagerId(managerId));
}

function* dispatchDaysByWeekIdToDisplayInRangePicker(action) {
  const fromWeekId = action.payload.fromWeek;
  const toWeekId = action.payload.toWeek;
  yield put(timesheetActions.getDaysByWeeksId([fromWeekId, toWeekId]));
}
