import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const sellingPriceServices = {
  getSellingPriceByworkpackageId(subprojectId) {
    return axios.get(API_PATH + `/subprojectSellingPrice?subprojectId=${subprojectId}`);
  },
  createSellingPrice(sellingPrice) {
    return axios.post(API_PATH + '/subprojectSellingPrice', sellingPrice);
  },
  patchSellingPrice(payload) {
    return axios.patch(API_PATH + '/subprojectSellingPrice', payload);
  },
  deleteSellingPrice(payload) {
    return axios.delete(API_PATH + '/subprojectSellingPrice/' + payload);
  }
};
