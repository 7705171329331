import dashboard from './dashboard';
import EntitiesSpace from './entities';
import HumanResourcesSpace from './human-resources';
import PersonalSpace from './my-personal-space';
import TeamAdministrationSpace from './my-team-administration';
import ProjectSpace from './project-space';

// ==============================|| MENU ITEMS ||============================== //
export default function menuItems() {
  return {
    items: [
      dashboard,
      ...(ProjectSpace().children?.length !== 0 ? [ProjectSpace()] : []),
      ...(HumanResourcesSpace().children?.length !== 0 ? [HumanResourcesSpace()] : []),
      ...(EntitiesSpace().children?.length !== 0 ? [EntitiesSpace()] : []),
      // ...(Can(CASL.CASL_READ, OBJECTS({}).VARIOUS_GROUP_SETTINGS.childrenObjects.ENTITY.id) ? [EntitiesSpace] : []),
      ...(PersonalSpace().children?.length !== 0 ? [PersonalSpace()] : []),
      ...(TeamAdministrationSpace().children?.length !== 0 ? [TeamAdministrationSpace()] : [])
      // ...(Can(CASL.CASL_READ, 'teamAdministration') ? [TeamAdministrationSpace()] : [])
      
    ]
  };
}
