import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const forecastServices = {
  getForecasts() {
    return axios.get(API_PATH + '/forecast');
  },
  postForecast(poscat) {
    return axios.post(API_PATH + '/forecast', poscat);
  },
  patchForecast(payload) {
    return axios.patch(API_PATH + '/forecast', payload);
  },
  deleteByForecastId(payload) {
    return axios.delete(API_PATH + '/forecast', { headers: {}, data: { id: payload } });
  },
  deleteByActivityTypeId(payload) {
    return axios.delete(API_PATH + '/forecast/byActivityType', { headers: {}, data: { id: payload } });
  }
};
