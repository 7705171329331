export const checkCheckedOneRoleAllObjectPermissions = (record, group) => {
  const perm = record.permissions.find((pe) => pe.object === group.id);
  if (!perm) return false;

  return group.permissions.every((pr) => {
    return perm.privileges.find((priv) => priv.privilege);
  });
};

export const checkCheckedOneRoleAllGroupPermissions = (recordPermissions, groupKeys) => {
  if (recordPermissions.length === 0) {
    return false;
  }
  if (recordPermissions.length !== groupKeys.length) {
    return false;
  }

  let flag = true;
  groupKeys.map((group, index) => {
    const groupPermissions = group.permissions.length;
    const rolePrivileges = recordPermissions.find((rp) => rp.object === group.id)?.privileges.length;
    if (groupPermissions !== rolePrivileges) {
      flag = false;
    }
  });

  return flag;
};
