import axios from "services/axios-interceptors";
import { getAllEmployees } from "store/actions";
import { API_PATH } from "store/service";

export const maxBudgetServices = {
  getAllEmployess(projectId, subprojectId, targetKeys) {
    if (projectId != undefined) {
      return axios.get(API_PATH + `/embd/${targetKeys}` + `/${projectId}` + `/${subprojectId}`);
    }
  },
  getYear() {
    return axios.get(API_PATH + "/tsy");
  },
  postData(sendUser) {
    return axios.post(API_PATH + "/embd/save", sendUser);
  },
  deleteData(payload) {
    return axios.get(API_PATH + `/embd/delete/${payload}`);
  },
  getByProjectIdAndSubprojectId(projectId, subProjectId) {
    return axios.get(API_PATH + `/embd/${projectId}` + `/${subProjectId}`);
  }
};
