import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const activityTypeServices = {
  getActivityTypesAll() {
    return axios.get(API_PATH + '/activityType');
  },
  postActivityType(payload) {
    return axios.post(API_PATH + '/activityType', payload);
  },
  patchActivityType(payload) {
    return axios.patch(API_PATH + '/activityType/', payload);
  },
  deleteActivityType(payload) {
    return axios.delete(API_PATH + '/activityType', { headers: {}, data: { id: payload } });
  }
};
