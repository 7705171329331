import { Col, Row, Tag, Typography } from 'antd';
import { format, parseISO } from 'date-fns';
import { useEffect } from 'react';
import { useState } from 'react';
import { timesheetServices } from 'store/time-sheet/time-sheet.services';

const { Text } = Typography;

export const FromTo = ({ weekId, status }) => {
  const [week, setWeek] = useState(null);

  const statusRender =
    status === 1 ? ['success', 'Approved'] : status === 0 ? ['error', 'Rejected'] : ['processing', 'Pending'];

  let subscribed = true;
  useEffect(() => {
    (async () => {
      const res = await timesheetServices.getDaysByWeekId({ weekId: weekId });
      if (subscribed) {
        setWeek(res.data.data);
      }
    })();
  }, [weekId]);

  useEffect(() => {
    return () => (subscribed = false);
  }, []);

  return week ? (
    <Row gutter={[16, 0]}>
      <Col>
        {'FROM '}
        <Text code>{format(parseISO(week?.days[0].theDate), 'dd-MM-yyyy')}</Text> {' TO '}
        <Text code>{format(parseISO(week?.days.at(-1).theDate), 'dd-MM-yyyy')}</Text>{' '}
        <Text mark>({week.theISOWeek})</Text>
      </Col>
      <Col>{<Tag color={statusRender[0]}>{statusRender[1]}</Tag>}</Col>
    </Row>
  ) : (
    'N/A'
  );
};
