import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectContractTypeById } from 'store/contract-types';
import { selectEmployeeById } from 'store/employee/employee.slice';
import { timesheetSettingsActions } from 'store/timesheet-settings';
import { EmployeeTimesheetWithDays } from '../../components';
import { EmployeeButtons } from '../../components/employee-buttons';
import { defaultTimesheetDaysSettings } from '../../consts';
import { useFetchWeekByDate } from '../../hooks';
import { checkEmployeeTimesheetEditable, getTimesheetDisabledActivities, getWeekInfo } from '../../utils';

export const ImpersonateTimesheet = ({ employeeId, day }) => {
  const dispatch = useDispatch();

  useFetchWeekByDate(employeeId, day);

  const weekSettings =
    useSelector((state) => state.timesheetSettings.impersonateTimesheetSettings) || defaultTimesheetDaysSettings;

  const singleApproval = useSelector((state) => state.appConfiguration.timesheetAutoReview);

  const employee = useSelector((state) => selectEmployeeById(state, employeeId));
  const contractType = useSelector((state) => selectContractTypeById(state, employee.typeOfContract.id));
  const dayTargetHours = contractType?.hoursPerDay || 0;

  const week = useSelector((state) => state.timesheet.days);
  const isInOvertime = week?.isInOvertime ?? false;

  const weekInfo = getWeekInfo(weekSettings?.timesheetSettingDays, isInOvertime, dayTargetHours);

  const isEditable = (timesheet) => {
    return checkEmployeeTimesheetEditable(timesheet, weekSettings?.timesheetSettingDays);
  };

  useEffect(() => {
    dispatch(timesheetSettingsActions.getImpersonateTimesheetSettings(employeeId));
  }, [employeeId]);

  return (
    <EmployeeTimesheetWithDays
      employeeId={employeeId}
      weekInfo={weekInfo}
      dayTargetHours={dayTargetHours}
      footer={<EmployeeButtons employeeId={employeeId} weekSettings={weekSettings} />}
      editable={isEditable}
      pdCommentShown={(timesheet) => {
        return !singleApproval && timesheet && !timesheet.isDraft;
      }}
      disabledActivities={(timesheet) => getTimesheetDisabledActivities(timesheet, weekInfo)}
      filtersByStatus={false}
    />
  );
};
