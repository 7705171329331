import { Menu, Space } from 'antd';
import OnePrivilegeAllRolesCheckbox from './components/one-privilege-all-roles-checkbox';

function checkRoles(data, object) {
  let result = {
    create: 0,
    read: 0,
    delete: 0,
    update: 0,
    approve: 0,
    updateStatus: 0
  };

  data.forEach((role) => {
    role.permissions.forEach((permission) => {
      if (permission.object === object) {
        permission.privileges.forEach((privilege) => {
          switch (privilege.privilege) {
            case 'create':
              result.create += 1;
              break;
            case 'read':
              result.read += 1;
              break;
            case 'delete':
              result.delete += 1;
              break;
            case 'update':
              result.update += 1;
              break;
            case 'approve':
              result.approve += 1;
              break;
            case 'update-status':
              result.updateStatus += 1;
              break;
          }
        });
      }
    });
  });

  return result;
}

export const PrivilegesOptions = ({ object, permissions, data, roles }) => {
  const isChecked = checkRoles(data, object);

  return (
    <Menu
      items={permissions.map((permission) => ({
        key: permission.privilege,
        label: (
          <Space>
            <OnePrivilegeAllRolesCheckbox
              object={object}
              permission={permission}
              data={data}
              roles={roles}
              isChecked={isChecked}
            />
          </Space>
        )
      }))}
    />
  );
};
