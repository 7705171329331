import { createSlice } from '@reduxjs/toolkit';
import { compareAsc, parseISO } from 'date-fns';

const initialState = {
  loading: false,
  error: false,
  humanResourcesRequests: [],
  myOvertimeRequests: [],
  directManagerListOfRequestsPending: [],
  directManagerListOfRequestsAll: [],
  detailsOfRequest: null,
  employeeColumnsActiveTab: ''
};

export const { actions: overtimeRequestActions, reducer: overtimeRequestReducer } = createSlice({
  name: 'overtimeRequest',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    changeActiveTabEmployeeColumns(state, action) {
      state.employeeColumnsActiveTab = action.payload;
    },
    getAllApprovedOvertimeRequests: (state) => {
      state.loading = true;
    },
    getAllApprovedOvertimeRequestsFulfilled(state, action) {
      state.humanResourcesRequests = action.payload.sort((a, b) =>
        compareAsc(parseISO(b.createdAt), parseISO(a.createdAt))
      );
    },
    getAllPendingOvertimeRequestsByManagerId: (state) => {
      state.loading = true;
    },
    getAllPendingOvertimeRequestsByManagerIdFulfilled(state, action) {
      state.directManagerListOfRequestsPending = action.payload;
    },
    getNonPendingOvertimeRequestsByManagerIId: (state) => {
      state.loading = true;
    },
    getNonPendingOvertimeRequestsByManagerIIdFulfilled(state, action) {
      state.directManagerListOfRequestsAll = action.payload;
    },
    getAllOvertimeRequestsByEmployeeId: (state) => {
      state.loading = true;
    },
    getAllOvertimeRequestsByEmployeeIdFulfilled(state, action) {
      state.myOvertimeRequests = action.payload;
    },
    resetRecord(state) {
      state.detailsOfRequest = null;
    },
    getOvertimeRequestById: (state) => {
      state.loading = true;
    },
    getOvertimeRequestByIdFulfilled(state, action) {
      state.detailsOfRequest = action.payload;
    },
    submitOvertimeRequest(state) {
      state.loading = true;
    },
    submitOvertimeRequestFulfilled(state, action) {
      state.myOvertimeRequests = [...state.myOvertimeRequests, action.payload];
    },
    patchOvertimeRequest: (state) => {
      state.loading = true;
    },
    patchOvertimeRequestFulfilled(state, action) {
      let newULR = [...state.myOvertimeRequests];
      const index = newULR.findIndex((item) => item.id === action.payload.id);
      newULR.splice(index, 1, action.payload);
      state.myOvertimeRequests = [...newULR];
    },
    managerDecision: (state) => {
      state.loading = true;
    },
    managerDecisionFulfilled(state, action) {},
    deleteOvertime: (state) => {
      state.loading = true;
    },
    deleteOvertimeFulfilled(state, action) {
      const updatedOvertimeReuqestsList = state.humanResourcesRequests.filter(
        (overtime) => overtime.id !== action.payload
      );
      const updatedDirectManagerRequestsList = state.directManagerListOfRequestsAll.filter(
        (overtime) => overtime.id !== action.payload
      );
      const updatedEmployeeReuqestsList = state.myOvertimeRequests.filter((overtime) => overtime.id !== action.payload);
      return {
        ...state,
        humanResourcesRequests: [...updatedOvertimeReuqestsList],
        directManagerListOfRequestsAll: [...updatedDirectManagerRequestsList],
        myOvertimeRequests: [...updatedEmployeeReuqestsList]
      };
    }
  }
});
