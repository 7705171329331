import OBJECTS from 'casl/objects';
import { all, put, takeLatest } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { projectServices } from './project.services';
import { projectActions } from './project.slice';

export function* projectRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getMyProjects',
      actions: projectActions,
      api: {
        method: projectServices.getMyProjects,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getProjects',
      actions: projectActions,
      api: {
        method: projectServices.getProjects,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getProjectsForTimesheet',
      actions: projectActions,
      api: {
        method: projectServices.getProjectsForTimesheet,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getProjectsForBusinessTripRequests',
      actions: projectActions,
      api: {
        method: projectServices.getProjectsForBusinessTripRequests,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getLatestProjectCode',
      actions: projectActions,
      api: {
        method: projectServices.getLatestProjectCode,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postProject',
      actions: projectActions,
      api: {
        method: projectServices.postProject,
        callback: (res) => {
          return { data: res.data, nav: res.nav };
        }
      }
    }),
    fetchSaga({
      pattern: 'patchProject',
      actions: projectActions,
      api: {
        method: projectServices.patchProject,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'changeProjectStatus',
      actions: projectActions,
      api: {
        method: projectServices.changeProjectStatus,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteProject',
      actions: projectActions,
      api: {
        method: projectServices.deleteProject,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'updateProjectDirectors',
      actions: projectActions,
      api: {
        method: projectServices.updateProjectDirectors,
        callback: (res) => res
      }
    }),
    takeLatest(projectActions.postProjectFulfilled.type, dispatchPatchPostProject)
  ]);
}

// for default workpackage on project creation
function* dispatchPatchPostProject(action) {
  const nav = action.payload.nav;
  const res = yield action.payload.data;
  const project = res.data.data;
  yield put(projectActions.patchPostProject(project));
  nav(OBJECTS({}).PROJECT_SETTINGS_GROUP.childrenObjects.PROJECTS.path + '/' + project.id);
}
