import { Divider, List } from 'antd';
import useNotificationHook from '../hooks/notificationHook';
import { DefaultLineManagerNotificationsListItem } from './default-line-managers-notifications-list-item';
import { ListItems } from './notifications-list-items';

// ==============================|| NOTIFICATION LIST ||============================== //

const NotificationList = ({ unreadNotificationsAll, unreadNotifications, handleClose }) => {
  const isNotificationReviewed = unreadNotifications.map((notification) => notification.isReviewed);
  const { sortedNotificationsForLineManager } = useNotificationHook();
  return (
    <>
      {sortedNotificationsForLineManager.length !== 0 && (
        <DefaultLineManagerNotificationsListItem
          unreadNotificationsAll={unreadNotificationsAll}
          handleClose={handleClose}
        />
      )}
      <Divider style={{ margin: 0 }} />
      <List
        className={!isNotificationReviewed[0] && `notification-list-items`}
        itemLayout="vertical"
        dataSource={unreadNotifications}
        renderItem={(item) => {
          return <ListItems item={item} handleClose={handleClose} />;
        }}
      />
    </>
  );
};

export default NotificationList;
