import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import { store } from 'store';

export const myProjectsTeamsServices = {
  getAllProjectsTeamsTimesheets() {
    return Promise.all([
      ...(Can(CASL.CASL_READ, OBJECTS({}).PROJECT_TEAM_GROUP.childrenObjects.PROJECT_MANAGER_TIMESHEETS.id)
        ? [axios.get(API_PATH + '/get/all/projectTeamPendingTimesheetsByProjectsIds')]
        : []),
      ...(store.getState().currentUser.responsibilities.projectDirector
        ? [axios.get(API_PATH + '/get/all/pendingTimesheetsByProjectDirector')]
        : [])
    ]);
  },
  reviewOnePendingProjectTeamsTimesheets(payload) {
    return axios.post(API_PATH + '/post/oneReviewProjectDirectorTimesheetPerActivities', payload);
  },
  submitSelectedPendingProjectTeamsTimesheets(payload) {
    return axios.post(API_PATH + '/post/massReviewProjectDirectorTimesheetPerActivities', payload);
  },
  makeRefreshButtonEnable(enable) {
    return enable;
  }
};
