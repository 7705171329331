import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const projectTeamMemberServices = {
  
  getProjectTeamMembersByProjectId(projectId) {
    return axios.get(API_PATH + '/projectTeamEmployee/' + projectId);
  },
  postProjectTeamMember(payload) {
    return axios.post(API_PATH + '/projectTeamEmployee/add/newMembers', payload);
  },
  removeAllTeamMembers(payload) {
    return axios.post(API_PATH + '/projectTeamEmployee/remove/members/all', { ids: payload });
  },
  deactivateProjectTeamMember(payload) {
    return axios.patch(API_PATH + '/projectTeamEmployee/change/status/' + payload, payload);
  },
  deleteProjectTeamMembers(projectTeamEmployeeId) {
    return axios.delete(API_PATH + '/projectTeamEmployee/' + projectTeamEmployeeId);
  }
};
