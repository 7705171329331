import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const positionCategoryServices = {
  getPositionCategories() {
    return axios.get(API_PATH + '/positionCategory/all');
  },
  getActivePositionCategories() {
    return axios.get(API_PATH + '/positionCategory/all/active');
  },
  postPositionCategory(poscat) {
    return axios.post(API_PATH + '/positionCategory', poscat);
  },
  patchPositionCategory(payload) {
    return axios.patch(API_PATH + '/positionCategory', payload);
  },
  deletePositionCategory(payload) {
    return axios.delete(API_PATH + '/positionCategory', { headers: {}, data: { id: payload } });
  },
  postPositionCategoryHistory(poscathistory) {
    return axios.post(API_PATH + '/positionCategoryHistory', poscathistory);
  },
  patchPositionCategoryHistory(payload) {
    return axios.patch(API_PATH + '/positionCategoryHistory/', payload);
  },
  deletePositionCategoryHistory(payload) {
    return axios.delete(API_PATH + '/positionCategoryHistory/' + payload);
  }
};
