export const Weeks = {
  PREVIOUS_WEEK: 'PREVIOUS_WEEK',
  CURRENT_WEEK: 'CURRENT_WEEK',
  NEXT_WEEK: 'NEXT_WEEK'
};

export const WeekKeys = {
  [Weeks.PREVIOUS_WEEK]: 'PREVIOUS_WEEK',
  [Weeks.CURRENT_WEEK]: 'CURRENT_WEEK',
  [Weeks.NEXT_WEEK]: 'NEXT_WEEK'
};

export const WeekTexts = {
  [Weeks.PREVIOUS_WEEK]: 'Previous',
  [Weeks.CURRENT_WEEK]: 'This',
  [Weeks.NEXT_WEEK]: 'Next'
};

export const WeekMovingFromCurrentWeek = {
  [Weeks.PREVIOUS_WEEK]: -1,
  [Weeks.CURRENT_WEEK]: 0,
  [Weeks.NEXT_WEEK]: 1
};

export const WEEK_DAYS = 7;

export const weekDaysEnum = [
  {
    name: 'MONDAY',
    description: 'Monday'
  },
  {
    name: 'TUESDAY',
    description: 'Tuesday'
  },
  {
    name: 'WEDNESDAY',
    description: 'Wednesday'
  },
  {
    name: 'THURSDAY',
    description: 'Thursday'
  },
  {
    name: 'FRIDAY',
    description: 'Friday'
  },
  {
    name: 'SATURDAY',
    description: 'Saturday'
  },
  {
    name: 'SUNDAY',
    description: 'Sunday'
  }
];
