// material-ui
// assets
import { IconUserPlus, IconUserX } from '@tabler/icons';
import { Avatar, Badge, Button, List, Tabs, Typography } from 'antd';
import OBJECTS from 'casl/objects';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Can from 'casl/can';
import * as CASL from 'casl/constants';

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ setOpen }) => {
  const employeesToBeAdded = useSelector((state) => state.pendingEmployees.toBeAdded);
  const employeesToBeRemoved = useSelector((state) => state.pendingEmployees.toBeRemoved);
  const loadingSync = useSelector((state) => state.pendingEmployees.loading);
  const navigate = useNavigate();

  const CASL_OBJECTS = OBJECTS({});

  const { TabPane } = Tabs;
  const { Text } = Typography;
  return (
    <Tabs type="card">
      <TabPane
        tab={
          <>
            <Text>To Activate</Text>{' '}
            <Badge style={{ backgroundColor: '#2db7f5' }} count={employeesToBeAdded.length} showZero />
          </>
        }
        key="1"
      >
        <Scrollbars style={{ width: 400, height: 300 }}>
          <List
            itemLayout="vertical"
            dataSource={employeesToBeAdded}
            loading={loadingSync}
            renderItem={(item) => (
              <List.Item
                actions={
                  Can(CASL.CASL_CREATE, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.id) && [
                    <Button
                      onClick={() => {
                        navigate(CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.path + '/0', {
                          state: { employeeInfo: item }
                        });
                        setOpen(false);
                      }}
                      type="primary"
                    >
                      Activate
                    </Button>
                  ]
                }
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        color: '#0050b3',
                        backgroundColor: '#69c0ff',
                        border: 'none',
                        borderColor: '#91d5ff'
                      }}
                    >
                      <IconUserPlus stroke={1.5} size="1.8rem" />
                    </Avatar>
                  }
                  title={item.fullName}
                  description={
                    Can(CASL.CASL_CREATE, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.id) &&
                    "Click on the button below to activate employee's profile"
                  }
                />
              </List.Item>
            )}
          />
        </Scrollbars>
      </TabPane>

      <TabPane
        tab={
          <>
            <Text>To Deactivate</Text>{' '}
            <Badge style={{ backgroundColor: '#f5222d' }} count={employeesToBeRemoved.length} showZero />
          </>
        }
        key="2"
      >
        <Scrollbars style={{ width: 400, height: 300 }}>
          <List
            itemLayout="vertical"
            dataSource={employeesToBeRemoved}
            loading={loadingSync}
            renderItem={(item) => (
              <List.Item
                actions={
                  Can(CASL.CASL_DELETE, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.id) && [
                    <Button
                      onClick={() => {
                        navigate(
                          CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.path + item.username,
                          {
                            state: { username: item.username }
                          }
                        );
                        setOpen(false);
                      }}
                      type="primary"
                      danger
                    >
                      Deactivate
                    </Button>
                  ]
                }
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        color: '#f5222d',
                        backgroundColor: '#ffa39e',
                        border: 'none',
                        borderColor: '#ffa39e'
                      }}
                    >
                      <IconUserX stroke={1.5} size="1.8rem" />
                    </Avatar>
                  }
                  title={item.fullName}
                  description={
                    Can(CASL.CASL_CREATE, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.id) &&
                    "Click on the button below to deactivate employee's profile"
                  }
                />
              </List.Item>
            )}
          />
        </Scrollbars>
      </TabPane>
    </Tabs>
  );
};

export default NotificationList;
