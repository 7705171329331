import { entityServices } from './entity.services';
import { fetchSaga } from 'utils/fetch-saga';
import { all } from 'redux-saga/effects';
import { entityActions } from './entity.slice';

export function* entityRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getEntities',
      actions: entityActions,
      api: {
        method: entityServices.getEntities,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getEntitiesByTypeName',
      actions: entityActions,
      api: {
        method: entityServices.getEntitiesByTypeName,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postEntities',
      actions: entityActions,
      api: {
        method: entityServices.postEntities,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchEntities',
      actions: entityActions,
      api: {
        method: entityServices.patchEntities,
        callback: (res) => res.data.data
      }
    })
  ]);
}
