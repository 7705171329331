import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { timesheetServices } from './time-sheet.services';
import { timesheetActions } from './time-sheet.slice';

export function* timesheetRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'submitTimesheet',
      actions: timesheetActions,
      api: {
        method: timesheetServices.submitTimesheet,
        callback: (res) => res.data
      }
    }),
    fetchSaga({
      pattern: 'deleteTimesheet',
      actions: timesheetActions,
      api: {
        method: timesheetServices.deleteTimesheet,
        callback: (res) => res
      }
    }),
    fetchSaga({
      pattern: 'getMyTimeSheet',
      actions: timesheetActions,
      api: {
        method: timesheetServices.getMyTimeSheet,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getTeamPendingTimeSheet',
      actions: timesheetActions,
      api: {
        method: timesheetServices.getTeamPendingTimeSheet,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getProjectTeamTimeSheetsAll',
      actions: timesheetActions,
      api: {
        method: timesheetServices.getProjectTeamTimeSheetsAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getDaysByDate',
      actions: timesheetActions,
      api: {
        method: timesheetServices.getDaysByDate,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getDaysByWeekId',
      actions: timesheetActions,
      api: {
        method: timesheetServices.getDaysByWeekId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'getDaysByWeeksId',
      actions: timesheetActions,
      api: {
        method: timesheetServices.getDaysByWeeksId,
        callback: (res) => res.map((resItem) => resItem.data.data.theISOWeek)
      }
    }),
    fetchSaga({
      pattern: 'getTimeSheetByUserAndWeek',
      actions: timesheetActions,
      api: {
        method: timesheetServices.getTimeSheetByUserAndWeek,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'approveTimeSheet',
      actions: timesheetActions,
      api: {
        method: timesheetServices.approveTimeSheet,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchTimesheet',
      actions: timesheetActions,
      api: {
        method: timesheetServices.patchTimesheet,
        callback: (res) => res.data
      }
    }),
    fetchSaga({
      pattern: 'getUserTimeSheetsForMaster',
      actions: timesheetActions,
      api: {
        method: timesheetServices.getUserTimeSheetsForMaster,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'totalhour',
      actions:timesheetActions,
      api:{
        method:timesheetServices.totalhour,
        callback:(res)=>res.data.data
      }
    }),
    fetchSaga({
      pattern:'empManHours',
      actions:timesheetActions,
      api:{
        method:timesheetServices.empManHours,
        callback:(res)=>res.data.data
      }
    }),
  ]);
}
