import { Tooltip } from 'antd';
import PulseDot from 'react-pulse-dot';
import 'react-pulse-dot/dist/index.css';

export const ConnectionBadge = ({ status }) => {
  if (status) {
    return (
      <Tooltip title="Connected to Websocket">
        <PulseDot style={{ fontSize: '6px' }} color={'success'} />
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Disconnected from Websocket">
      <PulseDot style={{ fontSize: '6px' }} color={'danger'} />
    </Tooltip>
  );
};
