import { format } from 'date-fns';

const formatter = 'yyyy-MM-dd';

export const formatEntryPerActivityByDays = (days, dayActivityLinks) => {
  return days.map((day) => {
    return (
      dayActivityLinks.find((dayLink) =>
        dayLink.date && day.theDate
          ? format(new Date(dayLink.date), formatter) === format(new Date(day.theDate), formatter)
          : false
      ) || { manHours: 0 }
    );
  });
};
