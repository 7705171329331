import { useSelector } from 'react-redux';

import { Formik, useFormikContext } from 'formik';
import { Table } from 'formik-antd';

import { AddEntry } from '../add-entry/';

import { getColumns, getNewEmptyTimesheetEntryPerActivity, mergeDaysArray } from '../../utils';

import { editableTimesheetCols } from './editable-timesheet.columns';

import { useEffect } from 'react';
import * as Yup from 'yup';
import { useTimesheet } from '../../hooks';
import useTableScrollHeight from 'hooks/useTableScrollHeight';

const InnerComponent = () => {
  const { setTimesheetValues } = useTimesheet();

  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    setTimesheetValues(values);

    values.timeSheetEntryPerActivities.forEach((activity, activityIndex) =>
      activity.dayActivityLinks.forEach((day, dayIndex) => {
        if (day.manHours === null) {
          setFieldValue(`timeSheetEntryPerActivities.${activityIndex}.dayActivityLinks.${dayIndex}.manHours`, 0);
        }
      })
    );
  }, [values]);

  return null;
};

export const EditableTimesheet = ({ days, tableTitle, columns = {}, loading }) => {
  const [width, tableY] = useTableScrollHeight({ tableTitle });
  const { calculateWeekHours, weekInfo, pdCommentShown, formRef, timesheet } = useTimesheet();

  const timeSheetEntryPerActivities = timesheet?.timeSheetEntryPerActivities;

  const twoStepReview = !useSelector((state) => state.appConfiguration.timesheetAutoReview);

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        timeSheetEntryPerActivities: timeSheetEntryPerActivities?.length
          ? timeSheetEntryPerActivities
          : [getNewEmptyTimesheetEntryPerActivity(-1)]
      }}
      enableReinitialize
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        timeSheetEntryPerActivities: Yup.array().of(
          Yup.object().shape({
            projectId: Yup.number().required('Please Choose a project'),
            activityCascader: Yup.array()
              .typeError('Please Choose an activity!')
              .required('Please Choose an activity!')
              // .of(Yup.number().required('Please Choose an activity!'))
              .length(2, 'Please Choose an activity!')
              .test('activityCascader', 'This activity has no hours', (_, { parent }) => {
                return parent.dayActivityLinks?.some((day) => day.manHours > 0) ?? true;
              })
          })
        )
      })}
      validate={() => {
        const errors = {};
        const { hoursPerDay } = calculateWeekHours();

        const weekInfoWithDays = mergeDaysArray([weekInfo, days]);
        const daysWithLessHours = weekInfoWithDays.map((day, dayIndex) => {
          return {
            minHoursMatch: day.isOpen ? hoursPerDay?.[dayIndex].manHours >= day.dayTargetHours : true,
            maxHoursMatch: day.isOpen ? hoursPerDay?.[dayIndex].manHours <= day.maxHoursPerDay : true
          };
        });

        if (daysWithLessHours.some((day) => !day.maxHoursMatch || !day.minHoursMatch)) {
          errors.days = daysWithLessHours;
        }

        return errors;
      }}
    >
      {({ values, errors }) => (
        <>
          <Table
            pagination={false}
            name="timeSheetEntryPerActivities"
            columns={getColumns(
              { ...editableTimesheetCols, ...columns },
              {
                weekInfo,
                days,
                twoStepReview,
                values,
                pdCommentShown,
                editable: true,
                errors
              }
            )}
            loading={loading}
            rowKey="id"
            title={() => tableTitle}
            scroll={{
              x: 'auto',
              y: `${tableY}px`
            }}
          />
          <AddEntry />

          <InnerComponent />
        </>
      )}
    </Formik>
  );
};
