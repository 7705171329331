import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: projectVerticalOptionsActions, reducer: projectVerticalOptionsReducer } = createSlice({
  name: 'projectVerticalOptions',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getProjectVerticalOptionsAll(state) {
      state.loading = true;
    },
    getProjectVerticalOptionsAllFulfilled(state, action) {
      state.all = action.payload;
    },
    postProjectVerticalOption(state) {
      state.loading = true;
    },
    postProjectVerticalOptionFulfilled(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) => a.name.localeCompare(b.name));
    },
    patchProjectVerticalOption(state) {
      state.loading = true;
    },
    patchProjectVerticalOptionFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      state.all = arr;
    },
    deleteProjectVerticalOption(state) {
      state.loading = true;
    },
    deleteProjectVerticalOptionFulfilled(state, action) {
      state.all = state.all.filter((d) => d.id !== action.payload);
    }
  }
});
