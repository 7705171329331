export const removeIndices = (array, indicesToRemove) => {
  indicesToRemove.forEach(item => {
    const index = array.indexOf(item);
    if (index !== -1) {
      array.splice(index, 1);
    }
  });
}
export const  removeKeys = (obj, keysToRemove) =>  {
    const newObj = { ...obj };
    keysToRemove.forEach(key => delete newObj[key]);
    return newObj;
  }
 
 
 
 