import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip, Typography } from 'antd';
import ShowMoreText from 'react-show-more-text';
import { DeleteRow } from '../components';
import { getDayColumns } from './getDayColumns';

export const getColumns = (
  { activityProps, dayProps, commentsProps, pdComment } = {
    activityProps: { render: () => {} },
    dayProps: { render: () => {} },
    commentsProps: { render: () => {} }
  },
  { days, weekInfo, pdCommentShown, values, editable, errors } = {}
) => {
  return [
    {
      title: 'Activity',
      width: 350,
      fixed: 'left',
      key: 'activityId',
      ...activityProps
    },
    ...getDayColumns(dayProps, weekInfo, days, errors),
    {
      title: 'Comments',
      dataIndex: 'comments',
      ...commentsProps
    },
    ...(pdCommentShown
      ? [
          {
            title: 'PM Comment',
            width: 250,
            render(_, __, i) {
              const currentActivity = values?.timeSheetEntryPerActivities?.[i];
              return (
                <Row gutter={[16, 16]} align="baseline">
                  <Col span={4}>
                    {currentActivity?.reviewedByProjectDirector ? (
                      <Tooltip title="This Activitiy was reviewed by Project Manager">
                        <CheckCircleOutlined style={{ color: '#52C41A' }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Project Manager Review Is Pending">
                        <ExclamationCircleOutlined style={{ color: '#1890ff' }} />
                      </Tooltip>
                    )}
                  </Col>
                  <Col span={20}>
                    <ShowMoreText
                      lines={2}
                      more="show more"
                      less="show less"
                      expanded={false}
                      truncatedEndingComponent={'...'}
                    >
                      <Typography.Paragraph>
                        {currentActivity?.projectDirectorComments ||
                          !currentActivity?.reviewedByProjectDirector ||
                          'No Project Manager Comments'}
                      </Typography.Paragraph>
                    </ShowMoreText>
                  </Col>
                </Row>
              );
            },
            ...pdComment
          }
        ]
      : []),
    ...(editable
      ? [
          {
            title: '',
            width: 40,
            render(_, __, index) {
              return <DeleteRow index={index} />;
            }
          }
        ]
      : [])
  ];
};
