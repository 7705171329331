import { IconMenu2 } from '@tabler/icons';
import { Avatar, ButtonBase } from '@mui/material';
import { useHeaderHook } from '../useHeaderHook';

export const MenuToggleButton = ({ handleLeftDrawerToggle }) => {
  const { theme } = useHeaderHook();
  return (
    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
      <Avatar
        variant="rounded"
        sx={{
          ...theme.typography.commonAvatar,
          ...theme.typography.mediumAvatar,
          transition: 'all .2s ease-in-out',
          background: theme.palette.secondary.main,
          color: theme.palette.secondary.dark,
          '&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.light
          }
        }}
        onClick={handleLeftDrawerToggle}
        color="inherit"
      >
        <IconMenu2 stroke={1.5} size="1.3rem" />
      </Avatar>
    </ButtonBase>
  );
};
