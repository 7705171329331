import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { managerDelegationServices } from './manager-delegation.services';
import { managerDelegationActions } from './manager-delegation.slice';

export function* managerDelegationRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getManagerDelegationsAll',
      actions: managerDelegationActions,
      api: {
        method: managerDelegationServices.getManagerDelegationsAll,
        callback: (res) => res.data
      }
    }),
    fetchSaga({
      pattern: 'postManagerDelegation',
      actions: managerDelegationActions,
      api: {
        method: managerDelegationServices.postManagerDelegation,
        callback: (res) => res.data
      }
    }),
    fetchSaga({
      pattern: 'patchManagerDelegation',
      actions: managerDelegationActions,
      api: {
        method: managerDelegationServices.patchManagerDelegation,
        callback: (res) => res.data
      }
    })
  ]);
}
