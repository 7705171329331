import { ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Steps, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { useTimesheet } from '../../hooks';

const { Step } = Steps;

const employeeStepStatuses = {
  submitting: {
    icon: <SyncOutlined spin />,
    color: 'processing',
    title: 'Submitting',
    isCurrent: true
  },
  submitted: {
    color: 'success',
    title: 'Submitted',
    isCurrent: false
  }
};

const projectDirectorStepStatuses = {
  waiting: {
    icon: <ClockCircleOutlined />,
    color: 'default',
    title: 'Waiting',
    isCurrent: false
  },
  processing: {
    icon: <SyncOutlined spin />,
    color: 'processing',
    title: 'Reviewing',
    isCurrent: true
  },
  done: {
    color: 'success',
    title: 'Reviewed',
    isCurrent: false
  }
};

const directManagerStepStatuses = {
  waiting: {
    icon: <ClockCircleOutlined />,
    color: 'default',
    title: 'Waiting',
    isCurrent: false
  },
  processing: {
    icon: <SyncOutlined spin />,
    color: 'processing',
    title: 'Processing',
    isCurrent: true
  },
  rejected: {
    color: 'error',
    title: 'Rejected',
    isCurrent: false
  },
  approved: {
    color: 'success',
    title: 'Approved',
    isCurrent: true
  }
};

const getSteps = (timesheet, isDraft, isApproved, timesheetAutoReview) => {
  const getEmployeeStep = () =>
    !timesheet || isDraft || isApproved === 0 ? employeeStepStatuses['submitting'] : employeeStepStatuses['submitted'];
  const getEmployeeMessage = () => (isDraft ? 'This timesheet is a draft' : null);

  const allEntriesReviewed = timesheet?.reviewedByProjectDirector;

  const getProjectDirectorStep = () => {
    if (allEntriesReviewed) return projectDirectorStepStatuses['done'];
    if (!timesheet || isDraft || (isApproved == 0 && !allEntriesReviewed))
      return projectDirectorStepStatuses['waiting'];
    if (!allEntriesReviewed) return projectDirectorStepStatuses['processing'];
    return projectDirectorStepStatuses['done'];
  };

  const getDirectManagerStep = () => {
    if (isApproved === 0) return directManagerStepStatuses['rejected'];
    if (!timesheet || isDraft || !allEntriesReviewed) return directManagerStepStatuses['waiting'];
    if (isApproved && !isDraft) return directManagerStepStatuses['approved'];
    if (timesheet && !isDraft && allEntriesReviewed) return directManagerStepStatuses['processing'];
    return '';
  };

  return [
    { ...getEmployeeStep(), description: getEmployeeMessage() },
    ...(!timesheetAutoReview ? [{ ...getProjectDirectorStep(), description: 'Project Manager' }] : []),
    { ...getDirectManagerStep(), description: 'Line Manager' }
  ];
};

export const TimesheetTimeline = () => {
  const { timesheet } = useTimesheet();

  const timesheetAutoReview = useSelector((state) => state.appConfiguration.timesheetAutoReview);

  const steps = getSteps(timesheet, timesheet?.isDraft, timesheet?.isApproved, timesheetAutoReview);
  const activeStep = timesheet?.isApproved ? steps.length : steps.findIndex((step) => step.isCurrent);

  // this one is controlled by isDraft in status 3 and 0
  return (
    <Steps current={activeStep} size={'small'}>
      {steps.map((step, index) => (
        <Step
          key={index}
          title={
            <Tag icon={step.icon} color={step.color}>
              {step.title}
            </Tag>
          }
          description={step.description}
        />
      ))}
      {/* <Step title={DirectManagerStep} description="Line Manager Decision" /> */}
    </Steps>
  );
};
