import OBJECTS from 'casl/objects';

export const ORIGIN = {
  EMPLOYEE: {
    id: 'EMPLOYEE',
    path: '/my-personal-space/my-leave-requests/request-details',
    editBusinessTripPath: '/my-personal-space/my-leave-requests/submit-business-trip',
    editUnpaidLeavePath: '/my-personal-space/my-leave-requests/submit-unpaid-leave',
    editPaidLeavePath: '/my-personal-space/my-leave-requests/submit-paid-leave'
  },
  HR: {
    id: 'HR',
    path: OBJECTS({}).EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_LEAVE_REQUEST_DETAILS.path
  },
  LINE_MANAGER: {
    id: 'LINE_MANAGER',
    path: '/my-team-administration/team-leave-requests/request-details'
  },
  WP_MANAGER: {
    id: 'WP_MANAGER',
    path: OBJECTS({}).VARIOUS_GROUP_SETTINGS.childrenObjects.PM_LEAVE_REQUESTS_DETAILS.path
  }
};
