// assets
import {
  IconUsers,
  IconUserPlus,
  IconClipboardList,
  IconReport,
  IconCrown,
  IconCoin,
  IconUserSearch,
  IconUserCircle,
  IconClockHour7
} from '@tabler/icons';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import { useSelector } from 'react-redux';

// constant
const icons = {
  IconUsers,
  IconUserPlus,
  IconClipboardList,
  IconReport,
  IconCrown,
  IconCoin,
  IconUserSearch,
  IconUserCircle,
  IconClockHour7
};

// ==============================|| EMPLOYEES INFORMATION MENU ITEMS ||============================== //
export default function HumanResourcesSpace() {
  const { moduleEnableLeaves, moduleEnableOvertime } = useSelector((state) => state.appConfiguration);
  const CASL_OBJECTS = OBJECTS({});

  return {
    id: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.id,
    title: 'Employees Management',
    type: 'group',
    children: [
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES_SALARIES.id) ||
      Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.id) ||
      Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_OVERTIME_REQUESTS.id)
        ? [
            {
              id: 'employees',
              title: 'Employees',
              type: 'collapse',
              icon: icons.IconUsers,
              children: [
                ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.id)
                  ? [
                      {
                        id: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.id,
                        title: 'Employees Info',
                        type: 'item',
                        url: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.path,
                        icon: icons.IconUserSearch,
                        breadcrumbs: false
                      }
                    ]
                  : []),
                ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES_SALARIES.id)
                  ? [
                      {
                        id: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES_SALARIES.id,
                        title: 'Employees Salaries',
                        type: 'item',
                        url: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES_SALARIES.path,
                        icon: icons.IconCoin,
                        breadcrumbs: false
                      }
                    ]
                  : []),
                ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_DELEGATE_MANAGER.id)
                  ? [
                      {
                        id: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_DELEGATE_MANAGER.id,
                        title: 'Delegate Manager',
                        type: 'item',
                        url: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_DELEGATE_MANAGER.path,
                        icon: icons.IconUserCircle,
                        breadcrumbs: false
                      }
                    ]
                  : []),
                ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_OVERTIME_REQUESTS.id)
                  ? [
                      {
                        id: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_OVERTIME_REQUESTS.id,
                        title: 'Overtime Requests',
                        type: 'item',
                        url: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_OVERTIME_REQUESTS.path,
                        icon: icons.IconClockHour7,
                        breadcrumbs: false,
                        isHidden: !moduleEnableOvertime
                      },
                      {
                        id: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_OVERTIME_REQUEST_DETAILS.id,
                        title: 'Overtime Requests',
                        type: 'hidden',
                        url: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_OVERTIME_REQUEST_DETAILS.path,
                        icon: icons.IconClipboardList,
                        breadcrumbs: false,
                        isHidden: !moduleEnableOvertime
                      }
                    ]
                  : [])
              ]
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.id)
        ? [
            {
              id: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.id,
              title: 'Employees List',
              type: 'hidden',
              url: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.path + ':username',
              icon: icons.IconUsers,
              breadcrumbs: false
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_LEAVE_REQUESTS_ALL.id)
        ? [
            {
              id: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_LEAVE_REQUESTS_ALL.id,
              title: 'Leave Requests',
              type: 'item',
              url: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_LEAVE_REQUESTS_ALL.path,
              icon: icons.IconClipboardList,
              breadcrumbs: false,
              isHidden: !moduleEnableLeaves
            },
            {
              id: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_LEAVE_REQUESTS_ALL.id,
              title: 'Leave Requests',
              type: 'hidden',
              url: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_LEAVE_REQUEST_DETAILS.path,
              icon: icons.IconClipboardList,
              breadcrumbs: false,
              isHidden: !moduleEnableLeaves
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_IMPERSONATE.id) ||
      Can(CASL.CASL_READ, CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_MASTER_APPROVAL.id)
        ? [
            {
              id: 'general-timebooking',
              title: 'General Timebooking',
              type: 'collapse',
              icon: icons.IconReport,
              children: [
                ...(Can(
                  CASL.CASL_READ,
                  CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_IMPERSONATE.id
                )
                  ? [
                      {
                        id: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_IMPERSONATE.id,
                        title: 'Impersonate Employee',
                        type: 'item',
                        url: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_IMPERSONATE.path,
                        icon: icons.IconUsers,
                        breadcrumbs: false
                      }
                    ]
                  : []),
                ...(Can(
                  CASL.CASL_READ,
                  CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_MASTER_APPROVAL.id
                )
                  ? [
                      {
                        id: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_MASTER_APPROVAL.id,
                        title: 'Master Approval',
                        type: 'item',
                        url: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_MASTER_APPROVAL
                          .path,
                        icon: icons.IconCrown,
                        breadcrumbs: false
                      },
                      {
                        id: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_MASTER_APPROVAL.id,
                        title: 'Master Approval',
                        type: 'hidden',
                        url:
                          CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_MASTER_APPROVAL.path +
                          '/timesheet',
                        icon: icons.IconCrown,
                        breadcrumbs: false
                      }
                    ]
                  : [])
              ]
            }
          ]
        : [])
      // {
      //     id: 'addEmployees',
      //     title: 'Add Employee',
      //     type: 'item',
      //     url: 'employees/add-employee',
      //     icon: icons.IconUserPlus,
      //     breadcrumbs: false
      // }
    ]
  };
}
