import React, { useEffect, useState, useRef } from "react";
import { Table, Button, Card, Space, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { maxBudgetServices } from "store/budget-settings/max-budget.services";
import MonthInputComponent from "./MonthInputComponent";
import { Switch, Tooltip, Typography } from "antd";
import { IconArrowBigLeftLine } from "@tabler/icons";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";
import { InfoCircleOutlined } from "@ant-design/icons";
import { maxBudgetActions } from "store/budget-settings/max-budget.slice";
import maxBudgetReducer from "store/budget-settings/max-budget.slice";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Warning } from "@mui/icons-material";
import {Transfer} from "antd";
function ProfileBudgetSettingsPage() {
  const dispatch = useDispatch();
  let isDataEdited = false;
  const updatedUser = useSelector((state) => state.maxBudgets.updatedUser);
  const [totalHoursEditable, setTotalHoursEditable] = useState(false); //for
  const [selectedEmployeeModalOpen, setSelectedEmployeeModalOpen] = useState(false);//for modal selected employee
  let [user, setUserData] = useState([]);
  // const [updateduser, setUserupdatedData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const childRef = useRef(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const [childData, setChildData] = useState(null);
  let flag = false;
  const handleChildData = (data) => {
    console.log("Data received from child:", data);
    setChildData(data);
    //setUserupdatedData(data);
  };

  user = useSelector((state) => state.maxBudgets.user.data);
  let TitleString = "Time Budget Managment";
  if (user != null && user != undefined && user.length > 0) {
    TitleString = "Time Budget  for Project: " + user[0].projectName + " and Sub project: " + user[0].subProjectName;
  }

  let postdata = useSelector((state) => state.maxBudgets.updatedUser);
  //setUserupdatedData(user);
  var confirmationMessage =
    "It looks like you have been editing something. " + "If you leave before saving, your changes will be lost.";
  //for year
  useEffect(() => {
    const fetchyear = async () => {
      try {
        dispatch(maxBudgetActions.getYearAll());
        const response = await maxBudgetServices.getYear();
        const data = response.data;
        dispatch(maxBudgetActions.getYearAllFulfilled(data));
      } catch (error) {
        console.log("error fetching data", error);
        dispatch(maxBudgetActions.setError(error));
      }
    };
    fetchyear();
  }, []);

  useEffect(() => {
    dispatch(maxBudgetActions.updatedUserFulfilled(user));
  }, [user]);

  const fetchData = (mockData) => {
    let obj = {};
    obj.data = mockData;
    dispatch(maxBudgetActions.getAllEmployeesAllFulfilled(obj));
    dispatch(maxBudgetActions.updatedUserFulfilled(obj.data));
  };
  //switch

  const handleInputChange = (e, index) => {
    let datatosend = postdata.map((entry) => ({ ...entry }));
    let val = 0;
    for (let i = 0; i < user.length; i++) {
      if (datatosend[i].empId == index.empId) {
        val = i;
        break;
      }
    }

    if (datatosend[val].empManHours.length > 0) {
      let emp = datatosend[val].empManHours.map((entry) => ({ ...entry }));
      emp[0].isDirty = true;
      datatosend[val].empManHours = emp;
    }
    if (datatosend[val].isEnabled == true) {
      datatosend[val].isEnabled = false;
    } else {
      datatosend[val].isEnabled = true;
    }
    fetchData(datatosend);
    setUserData(datatosend);
  };

  const columns = [
    {
      title: "Employee Code",
      dataIndex: "empCode",
      key: "empCode",
      width: "15.8%",
      editable: false,
      render: (text, record) => {
        return {
          props: {
            style: { backgroundColor: "#fafafa" }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Full Name",
      dataIndex: "empName",
      key: "empName",
      width: "15.8%",
      editable: false,
      render: (text, record) => {
        const isEnable = record.isEnabled;
        const backgroundColor = isEnable ? "#000000" : "#faad14";
        return {
          props: {
            style: { backgroundColor: "#fafafa", color: backgroundColor }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Discipline",
      dataIndex: "disciplineName",
      key: "disciplineName",
      width: "15.8%",
      editable: false,
      render: (text, record) => {
        return {
          props: {
            style: { backgroundColor: "#fafafa" }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Position Category/Grade",
      dataIndex: "positionCategoryName",
      key: "positionCategoryName",
      width: "15.8%",
      editable: false,
      render: (text, record) => {
        return {
          props: {
            style: { backgroundColor: "#fafafa" }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Cost (Per Hour)",
      dataIndex: "contractType",
      key: "contractType",
      width: "15.8%",
      editable: false,
      render: (text, record) => {
        return {
          props: {
            style: { backgroundColor: "#fafafa" }
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: "Enable/Disable",
      dataIndex: "Enable Disable",
      key: "Enable Disable",
      width: "19%",
      editable: false,
      render: (text, record) => {
        return {
          props: {
            style: { backgroundColor: "#fafafa" }
          },
          children: (
            <div className="Switch_parent">
              {text}
              <div className="switch">
                {console.log("check vallefu", record)}
                <Switch checked={record.isEnabled} onClick={(e) => handleInputChange(e, record)} />
              </div>
            </div>
          )
        };
      }
    }
  ];

  const expandedRowRender = (record) => {
    console.log("records in function", record);

    return (
      <div>
        <div style={{ justifyContent: "space-around", marginBottom: "15px", backgroundColor: "white" }}>
          <MonthInputComponent
            ref={childRef}
            user={user}
            cost={record.contractType}
            record={record}
            onData={handleChildData}
          />
        </div>
      </div>
    );
  };

  //to access the Text inside the Space and Space is inside the Card
  const { Text } = Typography;

  const handleSubmit = () => {
    if (childRef.current == null) {
      if (postdata !== null && postdata !== undefined && postdata.length > 0) {
        let datatosend = postdata.map((entry) => ({ ...entry }));
        let filterpostdata;
        for (let i = 0; i < postdata.length; i++) {
          filterpostdata = postdata[i].empManHours.filter((yy) => {
            return yy.year !== null;
          });
          datatosend[i].empManHours = filterpostdata;
        }

        let dataend = {};
        dataend.data = datatosend;
        saveData(dataend);
      }
    } else {
      childRef.current && childRef.current.handleChildFunction();
    }
  };

  const showModal = () => {
    let showPopup = false;
    postdata.map((item, i) => {
      for (let i = 0; i < item.empManHours.length; i++) {
        if (item.empManHours[i].isDirty) {
          showPopup = true;
          break;
        }
      }
    });
    if (showPopup) {
      setIsModalOpen(true);
    } else {
      navigate(-1);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    navigate(-1);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const saveData = async (dataend) => {
    try {
      const response = await maxBudgetServices.postData(dataend);

      let subproject = dataend.data[0].subProjectId;
      let project = dataend.data[0].projectId;
      let initialEmployeesList = dataend.data ? dataend.data?.map((emp) => emp.empId) : [];
      let empllist = initialEmployeesList.toString();

      const responseEmployes = await maxBudgetServices.getAllEmployess(project, subproject, empllist);
      let dataEmployee = responseEmployes.data.data;

      fetchData(dataEmployee);
      setUserData(dataEmployee);
      console.log("Success");
    } catch (error) {}
  };

  return (
    <div>
      <Modal
        title="Warning"
        icon={<ExclamationCircleOutlined />}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          <b>{confirmationMessage}</b>
        </p>
      </Modal>
      <Card
        title={
          <Space>
            <Typography.Link>
              <IconArrowBigLeftLine
                style={{ verticalAlign: "middle" }}
                color={theme.palette.secondary.main}
                onClick={() => showModal()}
                size={22}
              />
            </Typography.Link>
            <Text>{TitleString}</Text>
          </Space>
        }
      >
        <div>
          <div className="Send_data_div">
            <Button type="primary" onClick={handleSubmit} style={{ marginBottom: "1rem" }}>
              Submit
            </Button>
          </div>
          <Table
            columns={columns}
            pagination={{ pageSize: 10 }}
            dataSource={user}
            expandable={{ expandedRowRender }}
            rowKey="empCode"
          />
        </div>
      </Card>
    </div>
  );
}

export default ProfileBudgetSettingsPage;
