import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: [],
  lastSequence: 0,
  myWorkpackages: []
};

export const selectWorkpackageById = (state, workpackageId) => {
  const sps = state.workpackages.all.find((sp) => sp.id === workpackageId);
  return sps;
};

export const selectWorkpackageByProjectId = (state, projectId) => {
  const sps = state.workpackages?.all.filter((sp) => sp.project.id === projectId);
  return sps;
};

export const { actions: workpackageActions, reducer: workpackageReducer } = createSlice({
  name: 'workpackage',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    resetWorkpackages(state, action) {
      state.all = [];
    },
    getWorkpackageById: (state) => {
      state.loading = true;
    },
    getWorkpackageByIdFulfilled(state, action) {
      state.all = [action.payload];
    },
    getMyWorkpackages: (state) => {
      state.loading = true;
    },
    getMyWorkpackagesFulfilled(state, action) {
      state.myWorkpackages = [...action.payload];
    },
    getWorkpackagesByProjectId: (state) => {
      state.loading = true;
    },
    getWorkpackagesByProjectIdFulfilled(state, action) {
      state.all = [...action.payload].sort((a, b) => a.sequence?.localeCompare(b.sequence));
    },
    patchDefaultActivity: (state) => {
      state.loading = true;
    },
    patchDefaultActivityFulfilled(state, action) {
      const sub = { ...action.payload, activities: [] };
      let newSub = [...state.all, sub];
      state.all = [...newSub];
    },
    postWorkpackage: (state) => {
      state.loading = true;
    },
    postWorkpackageFulfilled(state, action) {
      const workpackage = { ...action.payload };
      delete workpackage['activities'];
      state.all = [...state.all, workpackage].sort((a, b) => a.sequence.localeCompare(b.sequence));
    },
    patchWorkpackage: (state) => {
      state.loading = true;
    },
    patchWorkpackageFulfilled(state, action) {
      let newSub = [...state.all];
      const index = newSub.findIndex((item) => item.id === action.payload.id);
      newSub.splice(index, 1, {
        ...action.payload
      });
      state.all = [...newSub];
    },
    patchDefaultWorkpackage(state) {
      state.loading = true;
    },
    patchDefaultWorkpackageFulfilled(state, action) {
      state.all = [...state.all, action.payload];
    },
    changeWorkpackageStatus(state) {
      state.loading = true;
    },
    changeWorkpackageStatusFulfilled(state, action) {
      let newSub = [...state.all];
      const index = newSub.findIndex((item) => item.id === action.payload.id);
      newSub.splice(index, 1, {
        ...action.payload
      });
      state.all = [...newSub];
    },
    patchChangingStatus(state) {
      state.loading = true;
    },
    patchChangingStatusFulfilled(state, action) {
      let newStat = [...state.all];
      newStat.map((wp) => {
        if (wp.project.id === action.payload.id && wp.projectStatus.name === 'Ongoing') {
          wp.projectStatus = action.payload.projectStatus;
        }
      });
      state.all = [...newStat];
    },
    deleteWorkpackage: (state) => {
      state.loading = true;
    },
    deleteWorkpackageFulfilled(state, action) {
      const updatedWorkpackages = state.all.filter((workpack) => workpack.id !== action.payload);
      state.all = [...updatedWorkpackages];
    }
  }
});
