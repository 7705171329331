import { Col, Modal, Row, Space } from 'antd';
import { Formik } from 'formik';
import { Checkbox, DatePicker, Form, InputNumber, ResetButton, Select, SubmitButton } from 'formik-antd';
import { useDispatch, useSelector } from 'react-redux';
import { currencyExchangeActions } from 'store/currency-exchange/currency-exchange.slice';
import { numberFormatter } from 'utils/formatter';
import { exchangeScheme } from 'views/currency-exchange/constants';

const getCurrenciesOptions = (currencies, removeCurrency) =>
  currencies
    ?.filter((currency) => currency.id !== removeCurrency)
    .map((currency) => ({
      value: currency.id,
      label: currency.name
    }));

export const FormModal = () => {
  const dispatch = useDispatch();

  const {
    currencyExchanges: { isFormModalOpen, editingExchange },
    currencies: { all: currencies }
  } = useSelector((state) => state);

  const handleModalCancel = () => dispatch(currencyExchangeActions.closeFormModal());

  const handleFormSubmit = (values) => {
    dispatch(currencyExchangeActions.addExchange(values));
  };

  return (
    <Modal
      onCancel={handleModalCancel}
      open={isFormModalOpen}
      destroyOnClose
      title={`${editingExchange ? 'Edit' : 'Add'} exchange`}
      footer={null}
    >
      <Formik
        onSubmit={handleFormSubmit}
        validationSchema={exchangeScheme}
        initialValues={{ fromCurrency: null, toCurrency: null, conversionRate: null, date: null, isActive: false }}
      >
        {({ values, isSubmitting, dirty }) => (
          <Form layout="vertical">
            <Row gutter={[20, 0]}>
              <Col span={12}>
                <Form.Item label="From Currency" name="fromCurrency">
                  <Select
                    placeholder="From Currency"
                    options={getCurrenciesOptions(currencies, values.toCurrency)}
                    name="fromCurrency"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="To Currency" name="toCurrency">
                  <Select
                    placeholder="To Currency"
                    options={getCurrenciesOptions(currencies, values.fromCurrency)}
                    name="toCurrency"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Rate" name="conversionRate">
                  <InputNumber
                    formatter={(value) => numberFormatter(value)}
                    parser={(value) => value.replace(new RegExp(/\$\s?|(,*)/g), '')}
                    placeholder="Rate"
                    style={{ width: '100%' }}
                    name="conversionRate"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Valid From" name="date">
                  <DatePicker format={'DD-MM-yyyy'} placeholder="Valid From" style={{ width: '100%' }} name="date" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="isActive">
              <Checkbox name="isActive">Is Active</Checkbox>
            </Form.Item>
            <Space style={{ display: 'flex', justifyContent: 'flex-end' }} size={10}>
              <ResetButton>Reset</ResetButton>
              <SubmitButton disabled={!dirty || isSubmitting}>Submit</SubmitButton>
            </Space>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
