import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  projectsListActiveTab: '2',
  all: [],
  code: '',
  projectDetailActiveTab: '',
  timesheetProjects: [],
  businessTripProjects: [],
  myProjects: []
};

export const selectProjectById = (state, projectId) => {
  const p = state.projects.all.find((p) => p.id === projectId);
  return p;
};

export const { actions: projectActions, reducer: projectReducer } = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    changeProjectDetailsActiveTab(state, action) {
      state.projectDetailActiveTab = action.payload;
    },
    getMyProjects: (state) => {
      state.loading = true;
    },
    getMyProjectsFulfilled(state, action) {
      state.myProjects = [...action.payload].sort((a, b) => a.id - b.id);
    },
    getProjects: (state) => {
      state.loading = true;
    },
    getProjectsFulfilled(state, action) {
      state.all = [...action.payload].sort((a, b) => a.id - b.id);
    },
    getProjectsForTimesheet: (state) => {
      state.loading = true;
    },
    getProjectsForTimesheetFulfilled(state, action) {
      state.timesheetProjects = [...action.payload].sort((a, b) => a.value - b.value);
    },
    getProjectsForBusinessTripRequests: (state) => {
      state.loading = true;
    },
    getProjectsForBusinessTripRequestsFulfilled(state, action) {
      state.businessTripProjects = [...action.payload].sort((a, b) => a.value - b.value);
    },
    getLatestProjectCode: (state) => {
      state.loading = true;
    },
    getLatestProjectCodeFulfilled(state, action) {
      state.code = action.payload.message;
    },
    updateProjectDirectors: (state) => {
      state.loading = true;
    },
    updateProjectDirectorsFulfilled(state, action) {
      state.all = [...state.all].map((pro) => {
        if (action.payload.ids.includes(pro.id)) {
          return { ...pro, projectDirector: action.payload.manager };
        } else {
          return pro;
        }
      });
    },
    changeActiveTabProjectsList(state, action) {
      state.projectsListActiveTab = action.payload;
    },
    setChangeActiveTabProjectsList: (state, action) => {
      state.projectsListActiveTab = action.payload;
    },
    postProject: (state) => {
      state.loading = true;
    },
    postProjectFulfilled(state, action) {
      // state.all = [...state.all, action.payload.data].sort((a, b) => a.code.localeCompare(b.code));
    },
    patchPostProject(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) => a.id - b.id);
    },
    patchProject: (state) => {
      state.loading = true;
    },
    patchProjectFulfilled(state, action) {
      let newPrs = [...state.all];
      const index = newPrs.findIndex((item) => item.id === action.payload.id);
      newPrs.splice(index, 1, {
        ...action.payload
      });
      state.all = newPrs;
    },
    changeProjectStatus: (state) => {
      state.loading = true;
    },
    changeProjectStatusFulfilled(state, action) {
      let newStatus = [...state.all];
      const index = newStatus.findIndex((item) => item.id === action.payload.id);
      newStatus[index].projectStatus = action.payload.projectStatus;
      state.all = newStatus;
      state.projectsListActiveTab =
        newStatus.filter((project) => project.projectStatus === +state.projectsListActiveTab).length > 0
          ? state.projectsListActiveTab
          : (state.projectsListActiveTab = '2');
    },
    deleteProject: (state) => {
      state.loading = true;
    },
    deleteProjectFulfilled(state, action) {
      const updatedProjects = state.all.filter((proj) => proj.id !== action.payload);
      state.all = [...updatedProjects];
      state.projectsListActiveTab =
        updatedProjects.filter((project) => project.projectStatus.id === +state.projectsListActiveTab).length > 0
          ? state.projectsListActiveTab
          : (state.projectsListActiveTab = '2');
    }
  }
});
