import { sellingPriceServices } from './selling-price.services';
import { fetchSaga } from 'utils/fetch-saga';
import { all } from 'redux-saga/effects';
import { sellingPriceActions } from './selling-price.slice';

export function* sellingPriceRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getSellingPriceByworkpackageId',
      actions: sellingPriceActions,
      api: {
        method: sellingPriceServices.getSellingPriceByworkpackageId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'createSellingPrice',
      actions: sellingPriceActions,
      api: {
        method: sellingPriceServices.createSellingPrice,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchSellingPrice',
      actions: sellingPriceActions,
      api: {
        method: sellingPriceServices.patchSellingPrice,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteSellingPrice',
      actions: sellingPriceActions,
      api: {
        method: sellingPriceServices.deleteSellingPrice,
        callback: (res) => res.data.data
      }
    })
  ]);
}
