import OBJECTS from 'casl/objects';
// project imports
import MainLayout from 'layout/main-layout';
import { lazy } from 'react';
import Loadable from 'ui-component/loadable';

const AppSettings = Loadable(lazy(() => import('views/app-settings')));

// ==============================|| MAIN ROUTING ||============================== //
export default function AppSettingsRoutes() {
  const CASL_OBJECTS = OBJECTS({});
  return {
    path: CASL_OBJECTS.APPLICATION_SETTINGS_GROUP.path,
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <AppSettings />
      },

      {
        path: CASL_OBJECTS.APPLICATION_SETTINGS_GROUP.path + '/:setting',
        element: <AppSettings />
      }
    ]
  };
}
