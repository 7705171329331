// assets
import { IconFileInvoice } from '@tabler/icons';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';

// constant
const icons = { IconFileInvoice };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //
export default function EntitiesSpace() {
  const CASL_OBJECTS = OBJECTS({});

  return {
    id: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.ENTITY.id,
    title: 'Entities',
    type: 'group',
    children: [
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.ENTITY.id)
        ? [
            {
              id: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.ENTITY.id,
              title: 'Clients & Subcontractors',
              type: 'item',
              url: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.ENTITY.path,
              icon: icons.IconFileInvoice,
              breadcrumbs: false
            }
          ]
        : [])
    ]
  };
}
