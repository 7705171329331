import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { maxBudgetServices } from './max-budget.services';
import { maxBudgetActions } from './max-budget.slice';

export function* maxBudgetRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getAllEmployeesAll',
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.getAllEmployess,
        callback: (res) => res.data.data,
      },
    }),
    //....
    fetchSaga({
      pattern:'getYearAll',
      actions: maxBudgetActions,
      api: {
        method:maxBudgetServices.getYear,
        callback:(res)=>res.data.data,
      },
    }),
    //..other one 
    fetchSaga({
      pattern:'postDataAll',
      actions:maxBudgetActions,
      api:{
        method:maxBudgetServices.postData,
        callback:(res)=>res.data.data,
      },
    }),
    fetchSaga({
      pattern:'deleteData',//slice
      actions:maxBudgetActions,
      api:{
        method:maxBudgetServices.deleteData ,
        callback:(res)=>res.data.data
      }
    })
  ]);
}

    /*
    fetchSaga({
      pattern: "setMaxBudgetItem",
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.setMaxBudgetItem,
        callback: (res) => res.data.data.amount
      }
    }),
    fetchSaga({
      pattern: "getEmployeeById",
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.getEmployeeById,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "getAllUserEmployees",
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.getAllUserEmployees,
        callback: (res) => res
      }
    }),
    fetchSaga({
      pattern: "finalizeMaxMonthBudgetFlag",
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.finalizeMaxMonthBudgetFlag,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "unFinalizeMaxMonthBudgetFlag",
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.unFinalizeMaxMonthBudgetFlag,
        callback: (res) => res.data.data
      }
    }),
    
    fetchSaga({
      pattern: "getFinalizeMaxMonthBudgetFlag",
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.getFinalizeMaxMonthBudgetFlag,
        callback: (res) => res.data.data
      }
    }),

    fetchSaga({
      pattern: "setSelectedEmployees",
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.setSelectedEmployees,
        callback: (res) => res
      }
    }),

    fetchSaga({
      pattern: "hideModalOk",
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.hideModalOk,
        callback: (res) => res
      }
    }),
    fetchSaga({
      pattern: "getTotalHoursPerEmployee",
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.getTotalHoursPerEmployee,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: "hideModalCancel",
      actions: maxBudgetActions,
      api: {
        method: maxBudgetServices.hideModalCancel,
        callback: (res) => res.status
      }
    })
    */

