import {
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';
import Can from 'casl/can';
import { CASL_READ } from 'casl/constants';
// material-ui
import { useTheme } from '@mui/material/styles';
// assets
import { IconLogout } from '@tabler/icons';
import { Avatar, Card } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthService from 'services/auth.service';
import { pendingEmployeeActions } from 'store/pending-employees/pending-employees.slice';
import Transitions from 'ui-component/extended/transitions';
import OBJECTS from 'casl/objects';
import { notificationsActions } from 'store/notifications';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.currentUser.user);

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = () => {
    AuthService.logout();
    // navigate('/');
    // window.location.reload();
  };

  const CASL_OBJECTS = OBJECTS({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (Can(CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.id)) {
      dispatch(pendingEmployeeActions.getPendingEmployees());
    }
    dispatch(
      notificationsActions.getAllUnreadNotifications({
        page: 0,
        size: 10
      })
    );
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const intitials = (fullName) => {
    var matches = fullName.match(/\b(\w)/g); // ['J','S','O','N']
    var acronym = matches.join('');
    return acronym;
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Avatar
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size={'large'}
        style={{ backgroundColor: '#0088cc', cursor: 'pointer' }}
      >
        {intitials(currentUser?.fullName)}
      </Avatar>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{ zIndex: 99 }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Card hoverable elevation={16} shadow={theme.shadows[16]}>
                  <Box sx={{ p: 1, pb: 0 }}>
                    <Stack spacing={2} sx={{ mb: 2 }}>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Good Day,</Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                          {currentUser?.fullName}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider />
                  </Box>
                  <Box sx={{ p: 0 }}>
                    <List
                      component="nav"
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '10px',
                        [theme.breakpoints.down('md')]: {
                          minWidth: '100%'
                        },
                        '& .MuiListItemButton-root': {
                          mt: 0.5
                        }
                      }}
                    >
                      <ListItemButton
                        sx={{ borderRadius: `${customization.borderRadius}px` }}
                        selected={selectedIndex === 4}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                      </ListItemButton>
                    </List>
                  </Box>
                </Card>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
