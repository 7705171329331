import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const entityTypeServices = {
  getEntityTypes() {
    return axios.get(API_PATH + '/entityType');
  },
  postEntityTypes() {
    return axios.post(API_PATH + '/entityType');
  }
};
