import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { currencyServices } from './currency.services';
import { currencyActions } from './currency.slice';

export function* currencyRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getCurrenciesAll',
      actions: currencyActions,
      api: {
        method: currencyServices.getCurrenciesAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postCurrency',
      actions: currencyActions,
      api: {
        method: currencyServices.postCurrency,
        callback: (res) => res.data.data
      }
    })
  ]);
}
