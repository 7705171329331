import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const workpackageServices = {
  patchDefaultWorkpackage(workpackage) {
    return workpackage;
  },
  patchChangingStatus(status) {
    return status;
  },
  getMyWorkpackages() {
    return axios.get(API_PATH + `/subproject/mySubprojects`);
  },
  getWorkpackageById(workpackageId) {
    return axios.get(API_PATH + `/subproject/${workpackageId}`);
  },
  getWorkpackagesByProjectId(projectId) {
    return axios.get(API_PATH + `/subproject/project?projectId=${projectId}`);
  },
  postWorkpackage(workpackage) {
    return axios.post(API_PATH + '/subproject', workpackage);
  },
  patchWorkpackage(payload) {
    return axios.patch(API_PATH + '/subproject', payload);
  },
  changeWorkpackageStatus(payload) {
    return axios.post(
      API_PATH + '/subproject/subprojectChangeStatus/' + payload.workpackageId + '/' + payload.statusId
    );
  },
  deleteWorkpackage(payload) {
    return axios.delete(API_PATH + '/subproject/' + payload);
  }
};
