import OBJECTS from 'casl/objects';
import { useSelector } from 'react-redux';

export const useCaslHookObjectsHook = () => {
  const {
    timesheetAutoReview,
    moduleEnableProjectTeams,
    moduleEnableLeaves,
    moduleEnableOvertime,
    moduleEnableProjectInvoices
  } = useSelector((state) => state.appConfiguration);

  const CASL_OBJECTS = OBJECTS({
    timesheetAutoReview,
    moduleEnableLeaves,
    moduleEnableOvertime,
    moduleEnableProjectInvoices,
    moduleEnableProjectTeams
  });
  return {
    CASL_OBJECTS
  };
};
