import { Table } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTimesheet } from '../../hooks';
import { getColumns } from '../../utils';
import { nonEditableTimesheetCols } from './non-editable-timesheet.columns';

export const NonEditableTimesheet = ({ loading, timesheetEntries, tableTitle }) => {
  const { days } = useSelector((state) => state.timesheet);
  const { weekInfo, setTimesheetValues, timesheet, pdCommentShown } = useTimesheet();

  useEffect(() => {
    setTimesheetValues(timesheet);
  }, [timesheet]);

  return (
    <Table
      loading={loading}
      dataSource={timesheetEntries}
      columns={getColumns(nonEditableTimesheetCols, {
        days: days?.days,
        editable: false,
        weekInfo,
        pdCommentShown,
        values: timesheet
      })}
      rowKey="id"
      pagination={false}
      title={() => tableTitle}
    />
  );
};
