import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const currentUserServices = {
  getUserInfo() {
    return axios.get(API_PATH + '/auth/user/info');
  },
  getMySubordinates() {
    return axios.get(API_PATH + '/employee/all/active/mySubordinates');
  },
  validateTicket(ticket) {
    return axios.get(`${API_PATH}/auth/validate/ticket${ticket}`);
  },
  validateSession() {
    return axios.get(API_PATH + '/auth/validate/session');
  }
};
