import { all, fork } from 'redux-saga/effects';
import { entityTypeRootSaga } from 'store/entity-type/entity-type.saga';
import { activitiesRootSaga } from './activities';
import { activityTypeRootSaga } from './activity-type';
import { appConfigurationRootSaga } from './app-configuration';
import { budgetRootSaga } from './budget';
import { maxBudgetRootSaga } from './budget-settings';
import { caslRolesRootSaga } from './casl-roles';
import { caslRolesPrivilegesRootSaga } from './casl-roles-privileges/casl-roles-privileges.saga';
import { commonRootSaga } from './common/common.saga';
import { contractTypesRootSaga } from './contract-types';
import { vatsRootSaga } from './vat';
import { currencyRootSaga } from './currency';
import { currencyExchangeRootSaga } from './currency-exchange/currency-exchange.saga';
import { currentUserRootSaga } from './current-user/current-user.saga';
import { disciplinesRootSaga } from './disciplines';
import { employeeRootSaga } from './employee/employee.saga';
import { entityRootSaga } from './entity/entity.saga';
import { invoiceManagementRootSaga } from './invoice-management';
import { ismsClassificationRootSaga } from './isms-classification/isms-classification.saga';
import { jobTitlesRootSaga } from './job-titles';
import { leaveRequestRootSaga } from './leave-request/leave-request.saga';
import { managerDelegationRootSaga } from './manager-delegation';

import { myProjectsTeamsTimesheetsRootSaga } from './my-projects-teams-timesheets';
import { overtimeRequestRootSaga } from './overtime-request/overtime-request.saga';
import { pendingEmployeeRootSaga } from './pending-employees/pending-employees.saga';
import { positionCategoryRootSaga } from './position-categories/position-category.saga';
import { powerBiParamsRootSaga } from './power-bi';
import { projectStatusRootSaga } from './project-status/project-status.saga';
import { projectTeamMemberRootSaga } from './project-team-employees/project-team-employees.saga';
import { projectRootSaga } from './project/project.saga';
import { qualityRiskLevelRootSaga } from './quality-risk-level/quality-risk-level.saga';
import { salariesRootSaga } from './salaries';
import { sellingPriceRootSaga } from './selling-price/selling-price.saga';
import { workpackageProgressRootSaga } from './subproject-progress/subproject-progress.saga';
import { timesheetRootSaga } from './time-sheet/time-sheet.saga';
import { timesheetSettingsRootSaga } from './timesheet-settings';
import { workpackageRootSaga } from './workpackage/workpackage.saga';
import { invoicePaymentsRootSaga } from './invoice-payments';
import { notificationsRootSaga } from './notifications';
import { businessUnitsRootSaga } from './business-unit';
import { projectVerticalOptionRootSaga } from './project-vertical-option';
import { projectWorpakacageManagerDelegationRootSaga } from './project-workpackages-managers-delegation';
import { forecastRootSaga } from './forecast';

export default function* rootSaga() {
  yield all([
    // App configuration
    fork(appConfigurationRootSaga),

    // Current User
    fork(currentUserRootSaga),

    // leave request
    fork(leaveRequestRootSaga),

    // overtime requests
    fork(overtimeRequestRootSaga),

    // entity types
    fork(entityTypeRootSaga),
    fork(activitiesRootSaga),
    fork(activityTypeRootSaga),

    // power bi params
    fork(powerBiParamsRootSaga),

    // budget
    fork(budgetRootSaga),
    fork(contractTypesRootSaga),
    fork(currencyRootSaga),
    fork(disciplinesRootSaga),
    fork(vatsRootSaga),
    // budget
    fork(maxBudgetRootSaga),

    // position category
    fork(positionCategoryRootSaga),

    // project statuses
    fork(projectStatusRootSaga),

    // isms classification
    fork(ismsClassificationRootSaga),

    // job titles
    fork(jobTitlesRootSaga),

    // projects
    fork(projectRootSaga),

    // project team and project team members
    fork(projectTeamMemberRootSaga),

    // project invoices
    fork(invoiceManagementRootSaga),
    fork(invoicePaymentsRootSaga),

    // quality risk levels
    fork(qualityRiskLevelRootSaga),

    // selling price
    fork(sellingPriceRootSaga),

    // workpackage progress
    fork(workpackageProgressRootSaga),

    // workpackages
    fork(workpackageRootSaga),

    // pending employees
    fork(pendingEmployeeRootSaga),

    // timesheet
    fork(timesheetRootSaga),
    fork(timesheetSettingsRootSaga),
    fork(entityRootSaga),

    // employees
    fork(employeeRootSaga),
    fork(managerDelegationRootSaga),
    fork(caslRolesRootSaga),
    fork(caslRolesPrivilegesRootSaga),

    // salaries
    fork(salariesRootSaga),

    // activity types
    fork(commonRootSaga),

    // exchanges
    fork(currencyExchangeRootSaga),

    // myProjectsTeamsTimesheets
    fork(myProjectsTeamsTimesheetsRootSaga),

    // notifications
    fork(notificationsRootSaga),

    // business Units
    fork(businessUnitsRootSaga),

    // project vertical options
    fork(projectVerticalOptionRootSaga),

    // project and workpackage manager delegation
    fork(projectWorpakacageManagerDelegationRootSaga),

    // forecasts
    fork(forecastRootSaga)
  ]);
}
