import { all, put, takeLatest } from 'redux-saga/effects';
import { caslRolesPrivilegesActions } from 'store/casl-roles-privileges/casl-roles-privileges.slice';
import { fetchSaga } from 'utils/fetch-saga';
import { caslRolesServices } from './casl-roles.services';
import { caslRolesActions } from './casl-roles.slice';

export function* caslRolesRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getCaslRolesAll',
      actions: caslRolesActions,
      api: {
        method: caslRolesServices.getCaslRolesAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postCaslRole',
      actions: caslRolesActions,
      api: {
        method: caslRolesServices.postCaslRole,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchCaslRoleName',
      actions: caslRolesActions,
      api: {
        method: caslRolesServices.patchCaslRoleName,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchCaslRoleEmployees',
      actions: caslRolesActions,
      api: {
        method: caslRolesServices.patchCaslRoleEmployees,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchEmployeeCaslRoles',
      actions: caslRolesActions,
      api: {
        method: caslRolesServices.patchEmployeeCaslRoles,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteCaslRole',
      actions: caslRolesActions,
      api: {
        method: caslRolesServices.deleteCaslRole,
        callback: (res) => res.data.data
      }
    })
  ]);
}
