import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  unreadNotifications: [],
  readNotifications: [],
  broadcasts: [],
  paging: []
};

export const { actions: notificationsActions, reducer: notificationsReducer } = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    changeActiveTabEmployeeColumns(state, action) {
      state.employeeColumnsActiveTab = action.payload;
    },
    getAllUnreadNotifications: (state) => {
      state.loading = true;
    },
    getAllUnreadNotificationsFulfilled(state, action) {
      state.unreadNotifications = [...state.unreadNotifications, ...action.payload.content];
      state.paging = action.payload.paging;
    },
    getAllReadNotifications: (state) => {
      state.loading = true;
    },
    getAllReadNotificationsFulfilled(state, action) {
      state.readNotifications = action.payload.content;
      state.paging = action.payload.paging;
    },
    reviewNotification: (state) => {
      state.loading = true;
    },
    reviewNotificationFulfilled(state, action) {
      const arr = [...state.unreadNotifications];
      const readNotifications = state.readNotifications;
      action.payload.map((newSettings) => {
        const foundIndex = arr.findIndex((item) => item.id === newSettings.id);
        if (foundIndex > -1) {
          arr.splice(foundIndex, 1, ...action.payload);
          state.unreadNotifications = arr;
        }
      });
      state.readNotifications = [...readNotifications, ...action.payload];
    },
    receiveNewNotification: (state) => {
      state.loading = true;
    },
    receiveNewNotificationFulfilled(state, action) {
      const arr = [action.payload, ...state.unreadNotifications];
      state.unreadNotifications = arr;
    }
  }
});
