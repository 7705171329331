import OBJECTS from 'casl/objects';
import MainLayout from 'layout/main-layout';
import { lazy } from 'react';
import Loadable from 'ui-component/loadable';

const CreateInvoicePage = Loadable(lazy(() => import('views/invoice-management/create-invoice/create-invoice-page')));
const InvoiceDetailsPage = Loadable(lazy(() => import('views/invoice-management/invoice-details/index')));
const InvoicesListPage = Loadable(lazy(() => import('views/invoice-management/invoices-list/index')));

// ==============================|| MAIN ROUTING ||============================== //
const InvoiceRoutes = () => {
  const CASL_OBJECTS = OBJECTS({});

  return {
    path: CASL_OBJECTS.PROJECT_SETTINGS_GROUP.childrenObjects.PROJECTS.path,
    element: <MainLayout />,
    children: [
      {
        path: `${CASL_OBJECTS.INVOICE_MANAGEMENT_PERMISSIONS.childrenObjects.INVOICE_MANAGEMENT.path}/:projectId/invoice-management`,
        element: <CreateInvoicePage />
      },
      {
        path: '/projects/:projectId/invoice-management/:invoiceId',
        element: <InvoiceDetailsPage />
      },
      {
        path: '/projects/invoice-management',
        element: <InvoicesListPage />
      },
      ,
      {
        path: '/projects/pending-invoices',
        element: <InvoicesListPage />
      }
    ].filter((route) => !route.isHidden)
  };
};

export default InvoiceRoutes;
