import React, { useMemo, useState, forwardRef, useImperativeHandle } from "react";
import { Button } from "antd";
import { EditOutlined, PlusOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { width } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import style from "../../../../../src/assets/scss/style.scss";
import { maxBudgetActions } from "store/budget-settings";
import { removeKeys, removeIndices } from "../sub-project-budget/helper.js";
import { set, update } from "lodash";
import { maxBudgetServices } from "store/budget-settings/max-budget.services";
import { Spin, message, Tooltip, Popconfirm } from "antd";
import { useHistory } from "react-router-dom";

const MonthInputComponent = forwardRef(({ cost, record, user, onData }, ref) => {
  const [messageApi, contextHolder] = message.useMessage();
  const year = useSelector((state) => state.maxBudgets.year || []);

  const current_user_id = useSelector((state) => state.currentUser.user.id);

  console.log("record on child page", record);
  const [totalHoursEditable, setTotalHoursEditable] = useState(false);
  const [inputValue, setInputValue] = useState({}); //use State hook for input value
  const [data, setData] = useState(record);
  let [updatedUser, setUpdatedUser] = useState([user]); // State for updatedUser

  //let [users, setUserData] = useState([]);
  let postdata = useSelector((state) => state.maxBudgets.updatedUser);
  //setUpdatedUser(postdata);
  let [deepCopy, setDeepCopy] = useState([]);
  const [loading, setLoading] = useState(false);
  //let deepCopy;
  const handleChildFunction = async () => {
    // Logic you want to execute from the parent component
    console.log("Function called from parent in MonthInputComponent");
    console.log("updated user is:;", updatedUser);
    console.log("updated deep copy is:;", postdata);

    let datatosend = postdata.map((entry) => ({ ...entry }));
    let mapDataEmp = new Map();
    let flagpass = true;
    let flagchange = false;
    let filterpostdata;
    for (let i = 0; i < postdata.length; i++) {
      filterpostdata = postdata[i].empManHours.filter((yy) => {
        return yy.id !== null;
      });
      flagchange = true;
      datatosend[i].empManHours = filterpostdata;
      let empid = postdata[i].empId;
      for (let k = 0; k < filterpostdata.length; k++) {
        if (filterpostdata[k].year == undefined) {
          flagpass = false;
          messageApi.error("Year cannot be left blank");
          return;
          break;
        }
        let key = empid + "" + filterpostdata[k].year;
        if (mapDataEmp.has(key)) {
          flagpass = false;
          break;
        } else {
          mapDataEmp.set(key, "abc");
        }
      }
    }
    if (flagchange) {
      if (flagpass) {
        let data = {};
        data.data = datatosend;
        //  dispatch(maxBudgetServices.postData(data))
        saveData(data);
      } else {
        messageApi.error("Duplicate year under same employee is not allowed");
      }
    } else {
      messageApi.error("No Row updated or added");
    }
  };
  let dataEmployee = [];
  const saveData = async (dataend) => {
    try {
      if (dataend !== null && dataend !== undefined && dataend.data.length > 0) {
        const response = await maxBudgetServices.postData(dataend);
        if (response != undefined && response != null) {
          let subproject = dataend.data[0].subProjectId;
          let project = dataend.data[0].projectId;
          let initialEmployeesList = dataend.data ? dataend.data?.map((emp) => emp.empId) : [];
          let empllist = initialEmployeesList.toString();

          const responseEmployes = await maxBudgetServices.getAllEmployess(project, subproject, empllist);
          dataEmployee = responseEmployes.data;
          sendDataToParent(dataEmployee.data);
          setUpdatedUser(dataEmployee.data);
          dispatch(maxBudgetActions.getAllEmployeesAllFulfilled(dataEmployee));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = (mockData) => {
    dispatch(maxBudgetActions.updatedUserFulfilled(mockData));
  };
  const sendDataToParent = (updatedUser) => {
    fetchData(updatedUser);
  };

  useImperativeHandle(ref, () => ({
    handleChildFunction: handleChildFunction
  }));

  const dispatch = useDispatch();
  console.log("uygsuyfds", data);

  console.log("inputValue-->", inputValue);

  //used for make my cell editable or un-editable

  const handleButtonClick = (index, data) => {
    if (postdata != null && postdata != undefined && postdata.length > 0) {
      let upsetdata = postdata.filter((yy) => {
        return yy.empId == data.empId;
      });
      setData(upsetdata[0]);
    }

    setData((prevData) => {
      let newData = [...prevData.empManHours]; // Copy empManHours array

      newData = newData.map((item, i) => {
        if (i === index) {
          if (item.isEditable) {
            return { ...item, isEditable: false };
          } else {
            return { ...item, isEditable: true };
          }
          // If current index matches, update the year property
        }
        return item;
      });

      // Update the user data
      const updatedUser = postdata.map((u) => {
        if (u.empId === prevData.empId) {
          return { ...u, empManHours: newData };
        }
        return u;
      });

      console.log("updated user after changing year:", updatedUser);
      setUpdatedUser(updatedUser);
      sendDataToParent(updatedUser);
      return { ...prevData, empManHours: newData }; // Return the updated state
    });
  };
  //used to show the expandable row
  //let updatedUser=user;
  const handlePlusIconClick = (record) => {
    if (postdata != null && postdata != undefined && postdata.length > 0) {
      let datatoup = [...postdata];
      let upsetdata = datatoup.filter((yy) => {
        return yy.empId == data.empId;
      });
      let emp = upsetdata[0].empManHours.filter((yy) => {
        return yy.yearId != null && yy.yearId != undefined && yy.yearId != 0;
      });
      let updatedFirstElement = { ...upsetdata[0] };
      updatedFirstElement.empManHours = emp;
      setData(updatedFirstElement);
    }
    setData((prevData) => {
      // console.log("value", value, "name", name, "index", index);
      console.log("all prev datal", prevData);
      let newData = [...prevData.empManHours]; // Assuming prevData.emphour is the array you want to update
      var emptydata = {};
      //var empManHours=[];
      emptydata.feb = 0;
      emptydata.jan = 0;
      emptydata.mar = 0;
      emptydata.may = 0;
      emptydata.jun = 0;
      emptydata.jul = 0;
      emptydata.aug = 0;
      emptydata.sep = 0;
      emptydata.oct = 0;
      emptydata.apr = 0;
      emptydata.nov = 0;
      emptydata.dec = 0;
      emptydata.isDirty = true;
      emptydata.isEditable = false;
      emptydata.totalHours = 0;
      emptydata.totalCost = 0;
      newData.push(emptydata);

      updatedUser = postdata.map((u) => {
        if (u.empId === record.empId) {
          return { ...u, empManHours: newData };
        }
        return u;
      });

      console.log("updated user after adding:", updatedUser);
      setUpdatedUser(updatedUser);
      sendDataToParent(updatedUser);
      return { ...prevData, empManHours: newData }; // Return the updated state
    });
  };

  const createEmptyObj = () => {
    var emptydata = {};
    //var empManHours=[];
    emptydata.feb = 0;
    emptydata.jan = 0;
    emptydata.mar = 0;
    emptydata.may = 0;
    emptydata.jun = 0;
    emptydata.jul = 0;
    emptydata.aug = 0;
    emptydata.sep = 0;
    emptydata.oct = 0;
    emptydata.apr = 0;
    emptydata.nov = 0;
    emptydata.dec = 0;
    emptydata.isDirty = false;
    emptydata.isEditable = false;
    emptydata.totalHours = 0;
    emptydata.totalCost = 0;
    return emptydata;
  };
  const calulateTotal = (emp) => {
    var total = 0;
    total += emp.jan;
    total += emp.feb;
    total += emp.mar;
    total += emp.apr;
    total += emp.may;
    total += emp.jun;
    total += emp.jul;
    total += emp.aug;
    total += emp.sep;
    total += emp.oct;
    total += emp.nov;
    total += emp.dec;
    return total;
  };

  const handleInputChange = (e, index) => {
    if (postdata != null && postdata != undefined && postdata.length > 0) {
      let upsetdata = postdata.filter((yy) => {
        return yy.empId == data.empId;
      });
      setData(upsetdata[0]);
    }

    let value = e.target.value;
    let name = e.target.name;
    if (value != null && value != undefined && value > 999) {
      return;
    } else {
      //value = Math.floor(value * 100) / 100;
      value = parseFloat(value).toFixed(2);
    }

    setData((prevData) => {
      //console.log("value", value, "name", name, "index", index);
      let newData = [...prevData.empManHours]; // Assuming prevData.emphour is the array you want to update
      newData[index] = {
        ...newData[index], // Spread the previous object properties
        [name]: Number(value)
      };
      if (newData[index].id == null) {
        const keysToRemove = ["id"];
        newData[index] = removeKeys(newData[index], keysToRemove);
      }
      // created and modified user
      if (newData[index].id == null || newData[index].id == undefined) {
        newData[index].createdBy = current_user_id;
        newData[index].modifiedby = current_user_id;
      } else {
        newData[index].modifiedby = current_user_id;
      }

      newData[index].totalHours = calulateTotal(newData[index]);

      newData[index].totalCost = newData[index].totalHours * cost;
      newData[index].isDirty = true;

      updatedUser = postdata.map((u) => {
        if (u.empId === prevData.empId) {
          return { ...u, empManHours: newData };
        }
        return u;
      });

      console.log("updated user after adding@@@@@after edit:", updatedUser);
      setUpdatedUser(updatedUser);
      sendDataToParent(updatedUser);
      return { ...prevData, empManHours: newData }; // Return the updated state
    });
  };

  const handletotalcostChange = (e, index) => {
    if (postdata != null && postdata != undefined && postdata.length > 0) {
      let upsetdata = postdata.filter((yy) => {
        return yy.empId == data.empId;
      });
      setData(upsetdata[0]);
    }
    const value = parseFloat(e.target.value);
    const name = e.target.name;

    setData((prevData) => {
      const newData = prevData.empManHours.map((item, i) => {
        if (i === index) {
          const updatedItem = {
            ...item,
            totalHours: value,
            isDirty: true,
            totalCost: value * cost,
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0
          };

          if (updatedItem.id == null) {
            const keysToRemove = ["id"];
            return removeKeys(updatedItem, keysToRemove);
          }
          if (updatedItem.id == null || updatedItem.id == undefined) {
            updatedItem.createdBy = current_user_id;
            updatedItem.modifiedby = current_user_id;
          } else {
            updatedItem.modifiedby = current_user_id;
          }

          return updatedItem;
        }
        return item;
      });

      updatedUser = postdata.map((u) => {
        if (u.empId === prevData.empId) {
          return { ...u, empManHours: newData };
        }
        return u;
      });

      setUpdatedUser(updatedUser);
      sendDataToParent(updatedUser);

      return { ...prevData, empManHours: newData };
    });
  };

  const handledeleteapicall = async (index, item, data) => {
    if (postdata != null && postdata != undefined && postdata.length > 0) {
      let upsetdata = postdata.filter((yy) => {
        return yy.empId == data.empId;
      });
      setData(upsetdata[0]);
    }
    let filterupdata = postdata.filter((yy) => {
      return yy.empId == data.empId;
    });
    index = -1;
    let emp = filterupdata[0].empManHours.filter((yy, i) => {
      if (yy.year == item.year && yy.totalHours == item.totalHours) {
        index = i;
      }
      return yy.year == item.year && yy.totalHours == item.totalHours;
    });
    console.log("i is", +index);
    let itemid = emp[0].id;
    let yearid = emp[0].yearId;
    let totalhours = emp[0].totalHours;
    try {
      if (itemid !== null && itemid !== undefined) {
        const response = await maxBudgetServices.deleteData(itemid);
        if (response != undefined && response != null) {
          handledelete(index, item);
          message.success("Row deleted Sucessfully");
        }
      } else {
        handledelete(index, item);
        message.success("Row deleted Sucessfully");
        //handlePlusIconClick(filterupdata[0]);
      }
    } catch (error) {}
  };

  const handledelete = (index, item) => {
    setData((prevData) => {
      let newData = [...prevData.empManHours];
      let newdata = newData.filter((item, i) => i !== index);
      if (newdata.length == 0) {
        const emptyobj = createEmptyObj();
        newdata.push(emptyobj);
      }
      //  dispatch(maxBudgetActions.deleteData(item.id));
      updatedUser = postdata.map((u) => {
        if (u.empId === prevData.empId) {
          return { ...u, empManHours: newdata };
        }
        return u;
      });

      console.log("updated inside delte updated data:", updatedUser);
      setUpdatedUser(updatedUser);
      sendDataToParent(updatedUser);
      // Assuming these are the keys you want to remove from each object in the array

      return { ...prevData, empManHours: newdata }; // Return the updated state
    });
  };

  const confirm = (e, index, emp, data) => {
    // console.log(e);
    handledeleteapicall(index, emp, data);
  };
  const cancel = (e) => {
    console.log(e);
    // message.error('Click on No');
  };
  
  const handleYearChange = (event, index, emp) => {
    if (postdata != null && postdata != undefined && postdata.length > 0) {
      const upsetdata = postdata.filter((yy) => yy.empId == data.empId);
      setData(upsetdata[0]);
    }
    
    setData((prevData) => {
      const newData = prevData.empManHours.map((item, i) => {
        if (i === index) {
          let updatedItem = { ...item }; // Create a copy of the item to modify
          if (updatedItem.id == null) {
            const keysToRemove = ["id"];
            updatedItem = removeKeys(updatedItem, keysToRemove);
          }
          if (updatedItem.id == null || updatedItem.id == undefined) {
            updatedItem.createdBy = current_user_id;
            updatedItem.modifiedby = current_user_id;
          } else {
            updatedItem.modifiedby = current_user_id;
          }
          updatedItem.isDirty = true;

          // Update year and yearId properties
          const filterYear = year.find((yy) => yy.theyear == event);
          if (filterYear) {
            updatedItem.year = event;
            updatedItem.yearId = filterYear.id;
          }
          return updatedItem;
        }
        return item;
      });

        // Check if the selected year is duplicate for the same employee
        const hasDuplicateYear = prevData.empManHours.some((item, i) => {
          return i !== index && item.year === event;
        });
          
         // Update the border color based on duplicate
        const yearSelect = document.getElementById(emp.empId+'_'+index);
        if (yearSelect) {
          if (hasDuplicateYear) {
            
            const span = yearSelect.parentElement;
            const div = span.parentElement;
            div.style.borderColor = 'red';
           // messageApi.error('check yewr g')
            
          } else {
            const span = yearSelect.parentElement;
            const div = span.parentElement;
            div.style.borderColor = '#ccc';
          }
        }

      // Update the user data
      const updatedUser = postdata.map((u) => {
        if (u.empId === prevData.empId) {
          return { ...u, empManHours: newData };
        }
        return u;
      });

      console.log("updated user after changing year:", updatedUser);
      setUpdatedUser(updatedUser);
      sendDataToParent(updatedUser);
      return { ...prevData, empManHours: newData }; // Return the updated state
    });
  };

  // const onChange=()=>{

  // }

  return (
    <>
      {contextHolder}
      <div className="jan_dec_div">
        {data.empManHours.map((emp, index) => (
          <div key={index} id={index} className="jan_delte_inside_map">
            <div className="months">
              <p style={{ width: "2.5rem" }}>YEAR</p>
              <Select
                id={`${data.empId}_${index}`}
                className="select_year"
                value={emp.year}
                disabled={emp.isEditable}
                onChange={(event) => handleYearChange(event, index, data)}
              >
                {year.map((item, index) => (
                  <Option key={index} value={item.theyear}>
                    {item.theyear}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>JAN</p>

              <input
                name="jan"
                type="number"
                className="month-input"
                value={emp.jan}
                onChange={(e) => handleInputChange(e, index)}
                //  defaultValue={0}
                min="0"
                disabled={emp.isEditable}
                max="2"
              />
            </div>

            <div className="months">
              <p style={{ width: "2.5rem" }}>FEB</p>

              <input
                name="feb"
                type="number"
                className="month-input"
                value={emp.feb}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>MAR</p>

              <input
                name="mar"
                type="number"
                className="month-input"
                value={emp.mar}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>APR</p>

              <input
                name="apr"
                type="number"
                className="month-input"
                value={emp.apr}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>MAY</p>

              <input
                name="may"
                type="number"
                className="month-input"
                value={emp.may}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>JUN</p>

              <input
                name="jun"
                type="number"
                className="month-input"
                value={emp.jun}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>JUL</p>

              <input
                name="jul"
                type="number"
                className="month-input"
                value={emp.jul}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>AUG</p>

              <input
                name="aug"
                type="number"
                className="month-input"
                value={emp.aug}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>SEP</p>

              <input
                name="sep"
                type="number"
                className="month-input"
                value={emp.sep}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>OCT</p>

              <input
                name="oct"
                type="number"
                className="month-input"
                value={emp.oct}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>NOV</p>

              <input
                name="nov"
                type="number"
                className="month-input"
                value={emp.nov}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>
            <div className="months">
              <p style={{ width: "2.5rem" }}>DEC</p>

              <input
                name="dec"
                type="number"
                className="month-input"
                value={emp.dec}
                onChange={(e) => handleInputChange(e, index)}
                min="0"
                disabled={emp.isEditable}
              />
            </div>

            <div className="totalHour_TotalCost_edit_add_delete">
              <div className="Total_Hours">
                <p>Total Hours</p>
                <input
                  name="totalHours"
                  type="number"
                  className="total-input-hours"
                  min="0"
                  value={emp.totalHours}
                  //readOnly={true}
                  disabled={!emp.isEditable}
                  onChange={(e) => handletotalcostChange(e, index)}
                />
              </div>

              <div className="Total_cost">
                <p>Total Cost</p>
                <input
                  type="number"
                  className="total-input-cost"
                  placeholder=""
                  //value={totalCost}
                  //onChange={(e) => setTotalCost(e.target.value)}
                  value={emp.totalCost}
                  // readOnly={true}
                  disabled={true}
                />
              </div>
              <Tooltip title="Edit the Row ">
                <EditOutlined
                  className="Edit_Icon"
                  type="primary"
                  onClick={(e) => {
                    handleButtonClick(index, data);
                  }}
                >
                  {totalHoursEditable ? "Edit Months" : "Edit Total Hours"}
                </EditOutlined>
              </Tooltip>

              <Tooltip title="Add the Row ">
                <PlusCircleOutlined className="plus_Icon" onClick={(e) => handlePlusIconClick(data)} />
              </Tooltip>

              <Popconfirm
                title="Sure to delete ?"
                description="Are you sure to delete this row?"
                onConfirm={(e) => confirm(e, index, emp, data)}
                onCancel={(e) => cancel(e)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  className="delete_Icon"
                  style={{ color: "red", cursor: "pointer", marginLeft: "1.75rem", marginTop: "2.5rem" }}
                />
              </Popconfirm>
            </div>
          </div>
        ))}
      </div>
    </>
  );
});

export default MonthInputComponent;
