import { createSlice } from '@reduxjs/toolkit';
import { compareDesc, parseISO } from 'date-fns';

const initialState = {
  loading: false,
  error: false,
  all: [],
  allActive: []
};

export const { actions: positionCategoryActions, reducer: positionCategoryReducer } = createSlice({
  name: 'positionCategory',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getPositionCategories: (state) => {
      state.loading = true;
    },
    getPositionCategoriesFulfilled(state, action) {
      state.all = action.payload;
    },
    getActivePositionCategories: (state) => {
      state.loading = true;
    },
    getActivePositionCategoriesFulfilled(state, action) {
      state.allActive = action.payload;
    },
    postPositionCategory(state) {
      state.loading = true;
    },
    postPositionCategoryFulfilled(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) => a.name.localeCompare(b.name));
    },
    postPositionCategoryHistory(state, action) {
      state.loading = true;
    },
    postPositionCategoryHistoryFulfilled(state, action) {
      const poscathistory = action.payload;
      const data = [...state.all];
      data.find((pos) => pos.id === poscathistory.positionCategory.id).positionCategoryHistories.push(poscathistory);
      data
        .find((pos) => pos.id === poscathistory.positionCategory.id)
        .positionCategoryHistories.sort((a, b) => compareDesc(parseISO(a.applicableFrom), parseISO(b.applicableFrom)));
      state.all = [...data];
    },
    patchPositionCategory(state) {
      state.loading = true;
    },
    patchPositionCategoryFulfilled(state, action) {
      let newPosCat = [...state.all];
      const index = newPosCat.findIndex((e) => e.id === action.payload.id);
      newPosCat.splice(index, 1, {
        ...action.payload
      });
      state.all = [...newPosCat].sort((a, b) => a.name.localeCompare(b.name));
    },
    deletePositionCategory(state) {
      state.loading = true;
    },
    deletePositionCategoryFulfilled(state, action) {
      state.all = state.all.filter((d) => d.id !== action.payload);
    },
    patchPositionCategoryHistory(state) {
      state.loading = true;
    },
    patchPositionCategoryHistoryFulfilled(state, action) {
      const arr = [...state.all];
      const foundPostCat = arr.find((item) => item.id === action.payload.positionCategory.id);
      const foundIndex = foundPostCat.positionCategoryHistories.findIndex((item) => item.id === action.payload.id);
      foundPostCat.positionCategoryHistories.splice(foundIndex, 1, action.payload);
      state.all = [...arr];
    },
    deletePositionCategoryHistory: (state) => {
      state.loading = true;
    },
    deletePositionCategoryHistoryFulfilled(state, action) {
      const { positionCategoryId, positionCategoryHistoryId } = action.payload;
      const poscat = [...state.all];
      const index = poscat.findIndex((pc) => pc.id === positionCategoryId);
      poscat[index].positionCategoryHistories = poscat[index].positionCategoryHistories.filter(
        (pc) => pc.id !== positionCategoryHistoryId
      );
      state.all = poscat;
    }
  }
});
