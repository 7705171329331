import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const projectVerticalOptionsServices = {
  getProjectVerticalOptionsAll() {
    return axios.get(API_PATH + '/projectVerticalOption');
  },
  postProjectVerticalOption(projectVerticalOption) {
    return axios.post(API_PATH + '/projectVerticalOption', projectVerticalOption);
  },
  patchProjectVerticalOption(payload) {
    return axios.patch(API_PATH + '/projectVerticalOption/', payload);
  },
  deleteProjectVerticalOption(payload) {
    return axios.delete(API_PATH + '/projectVerticalOption', { headers: {}, data: { id: payload } });
  }
};
