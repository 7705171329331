import { Space } from "antd";
import { useFormikContext } from "formik";
import { Cascader, Form, Select } from "formik-antd";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTimesheet } from "../../hooks";

export const ActivitySelect = ({ index }) => {
  const { disabledActivities } = useTimesheet();

  const { values, initialValues, setFieldValue, setFieldTouched } = useFormikContext();

  const initialProjectId = initialValues?.timeSheetEntryPerActivities?.[index]?.projectId;
  const currentProjectId = values.timeSheetEntryPerActivities[index].projectId;

  const selectedActivities = values.timeSheetEntryPerActivities?.map((entry) => entry.activityCascader?.[1]) || [];
  const selectedActivityCascader = values.timeSheetEntryPerActivities[index].activityCascader;

  // const listOfProjectOptions = useSelector((state) => state.timesheet.listOfAllActivities);
  const listOfProjectOptions = useSelector((state) => state.projects.timesheetProjects);

  const showingListOfProjects = useMemo(() => {
    const selectedProjectInProjects = listOfProjectOptions.find((a) => a.value === currentProjectId);
    const selectedProject = values.timeSheetEntryPerActivities[index].project;

    if (!selectedProjectInProjects && selectedProject) {
      const projectWorkpackage = values.timeSheetEntryPerActivities[index].subproject;
      const projectActivity = values.timeSheetEntryPerActivities[index].activity;
      return [
        ...listOfProjectOptions,
        {
          value: selectedProject.id,
          label: selectedProject.name,
          children: [
            {
              value: projectWorkpackage.id,
              label: projectWorkpackage.name,
              children: [
                {
                  value: projectActivity.id,
                  label: projectActivity.name
                }
              ]
            }
          ]
        }
      ];
    }

    const selectedWorkpackage = selectedProjectInProjects?.children.find(
      (subproject) => subproject.value === selectedActivityCascader?.[0]
    );
    const projectWorkpackage = values.timeSheetEntryPerActivities[index].subproject;

    if (
      !selectedWorkpackage &&
      projectWorkpackage &&
      currentProjectId === values.timeSheetEntryPerActivities[index].project.id
    ) {
      const projectActivity = values.timeSheetEntryPerActivities[index].activity;

      return listOfProjectOptions.map((project) => {
        if (project.value === currentProjectId) {
          return {
            ...project,
            children: [
              {
                value: projectWorkpackage.id,
                label: projectWorkpackage.name,
                children: [
                  {
                    value: projectActivity.id,
                    label: projectActivity.name
                  }
                ]
              }
            ]
          };
        }
        return project;
      });
    }

    const selectedActivity = selectedProjectInProjects?.children
      .find((subproject) => subproject.value === selectedActivityCascader?.[0])
      ?.children.find((activity) => activity.value === selectedActivityCascader?.[1]);

    if (!selectedActivity) {
      const projectActivity = values.timeSheetEntryPerActivities[index].activity;

      return listOfProjectOptions.map((project) => {
        if (project.value === currentProjectId) {
          return {
            ...project,
            children: project.children.map((workpackage) => {
              if (workpackage.value === selectedActivityCascader?.[0]) {
                return {
                  ...workpackage,
                  children: [
                    ...workpackage.children,
                    {
                      value: projectActivity.id,
                      label: projectActivity.name
                    }
                  ]
                };
              }
              return workpackage;
            })
          };
        }
        return project;
      });
    }

    return [...listOfProjectOptions];
  }, [listOfProjectOptions, currentProjectId, selectedActivityCascader?.join(", ")]);

  const listOfActivityOptions = useMemo(() => {
    const selectedProjectInProjects = showingListOfProjects.find((a) => a.value === currentProjectId);

    return selectedProjectInProjects?.children.map((workpackage) => ({
      ...workpackage,
      children: workpackage?.children.map((activity) => ({
        ...activity,
        disabled: selectedActivities.includes(activity.value)
      }))
    }));
  }, [showingListOfProjects, selectedActivities.join(", "), currentProjectId]);

  useEffect(() => {
    if (initialProjectId !== values.timeSheetEntryPerActivities?.[index]?.projectId) {
      setFieldValue(`timeSheetEntryPerActivities.${index}.activityCascader`, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.timeSheetEntryPerActivities?.[index]?.projectId]);

  const isSelectionDisabled = disabledActivities.includes(values.timeSheetEntryPerActivities?.[index].id);

  return (
    <Space style={{ display: "flex" }} direction="vertical" size={8}>
      <Form.Item name={`timeSheetEntryPerActivities.${index}.projectId`} style={{ margin: 0 }}>
        <Select
          name={`timeSheetEntryPerActivities.${index}.projectId`}
          placeholder="Select project to show workpackages"
          options={showingListOfProjects}
          showSearch
          style={{ width: 350 }}
          optionFilterProp="label"
          optionLabelProp="label"
          filterSort={(optionA, optionB) => optionA.label.toLowerCase().localeCompare(optionB.label.toLowerCase())}
          disabled={isSelectionDisabled}
        />
      </Form.Item>
      {values.timeSheetEntryPerActivities[index].projectId > 0 && (
        <Form.Item name={`timeSheetEntryPerActivities.${index}.activityCascader`} style={{ margin: 0 }}>
          <Cascader
            name={`timeSheetEntryPerActivities.${index}.activityCascader`}
            placeholder="Work package and activity"
            showSearch
            style={{ width: 350 }}
            options={listOfActivityOptions}
            onBlur={() => {
              setFieldTouched(`timeSheetEntryPerActivities.${index}.activityCascader`);
            }}
            disabled={isSelectionDisabled}
            // disabled={!checkPrevControls(weekInfo, entry, timesheetInfo, master)}
          />
        </Form.Item>
      )}
    </Space>
  );
};
