import { mergeDaysArray, calculateWeekHours } from '.';

export const checkDaysChanges = (weekSettings, timesheet) => {
  const workedHours = calculateWeekHours.apply(timesheet).hoursPerDay.map((day) => ({ workedHours: day.manHours }));
  const weekDays = mergeDaysArray([weekSettings, workedHours]);

  return weekDays.some((day) => {
    return day.isOpen && !day.workedHours;
  });
};

export const checkEmployeeTimesheetEditable = (timesheet, weekSettings) => {
  if (!timesheet || timesheet.isDraft) return true;
  if (timesheet.isApproved === 0) return true;

  if (timesheet.isComplete) return false;

  return checkDaysChanges(weekSettings, timesheet);
};
