import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: [],
  byType: { entityTypes: [], entities: [] }
};

export const { actions: entityActions, reducer: entityReducer } = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getEntities: (state) => {
      state.loading = true;
    },
    getEntitiesFulfilled(state, action) {
      state.all = [...action.payload];
    },
    getEntitiesByTypeName: (state) => {
      state.loading = true;
    },
    getEntitiesByTypeNameFulfilled(state, action) {
      state.byType = action.payload;
    },
    postEntities(state) {
      state.loading = true;
    },
    postEntitiesFulfilled(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) => a.name.localeCompare(b.name));
    },
    patchEntities(state) {
      state.loading = true;
    },
    patchEntitiesFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      arr.sort((a, b) => a.name.localeCompare(b.name));
      state.all = arr;
    }
  }
});
