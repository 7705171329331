import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  toBeAdded: [],
  toBeRemoved: []
};

export const selectEmployeeToAddByUsername = (state, employeeUserName) => {
  const empToAdd = state.pendingEmployees.toBeAdded.find((emp) => emp.username === employeeUserName);
  return empToAdd;
};

export const { actions: pendingEmployeeActions, reducer: pendingEmployeeReducer } = createSlice({
  name: 'pendingEmployee',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getPendingEmployees: (state) => {
      state.loading = true;
    },
    getPendingEmployeesFulfilled(state, action) {
      (state.toBeAdded = action.payload.Ldap), (state.toBeRemoved = action.payload.Database);
    },
    setPendingEmployees: (state, action) => {
      (state.toBeAdded = action.payload.Ldap), (state.toBeRemoved = action.payload.Database);
    },
    registerEmployee: (state, navigate, payload) => {
      state.loading = true;
    },
    registerEmployeeFulfilled(state, action) {
      const newEmp = state.toBeAdded.filter((e) => e.username !== action.payload);
      state.toBeAdded = newEmp;
    },
    disactivateEmployee: (state) => {
      state.loading = true;
    },
    disactivateEmployeeFulfilled(state, action) {
      const newEmp = state.toBeRemoved.filter((e) => e.username !== action.payload.data);
      state.toBeRemoved = [...newEmp];
    }
  }
});
