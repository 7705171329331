import * as CASL from 'casl/constants';
import Can from 'casl/can';
import OBJECTS from 'casl/objects';
import config from 'config';
import { useRoutes } from 'react-router-dom';
// routes
import { GetAccessByPermissions } from 'utils/permissions';
import AdminSettingsRoutes from './admin-settings-routes';
import AppSettingsRoutes from './app-settings-routes';
import AuthenticationRoutes from './authentication-routes';
import CurrenciesRoutes from './currency-exchange';
import EntitiesRoutes from './entities-routes';
import ErrorRoutes from './error-routes';
import HumanResourcesRoutes from './human-resources-routes';
import InvoiceRoutes from './invoice-routes';
import MainRoutes from './main-routes';
import PersonalSpaceRoutes from './personal-space-routes';
import ProjectRoutes from './project-routes';
import TeamAdministrationRoutes from './team-administration-routes';
import { useSelector } from 'react-redux';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes(props) {
  const CASL_OBJECTS = OBJECTS({});
  const employeeResponsibilities = useSelector((state) => state.currentUser.responsibilities);

  return useRoutes(
    [
      ErrorRoutes,
      MainRoutes,
      ...(ProjectRoutes().children?.length !== 0 ? [ProjectRoutes()] : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.INVOICE_MANAGEMENT_PERMISSIONS.childrenObjects.INVOICE_MANAGEMENT.id) ||
      (employeeResponsibilities.projectDirector && InvoiceRoutes().children?.length !== 0)
        ? [InvoiceRoutes()]
        : []),
      ...(EntitiesRoutes().children?.length !== 0 ? [EntitiesRoutes()] : []),
      AuthenticationRoutes,
      ...(TeamAdministrationRoutes().children?.length !== 0 ? [TeamAdministrationRoutes()] : []),
      ...(HumanResourcesRoutes().children?.length !== 0 ? [HumanResourcesRoutes()] : []),
      PersonalSpaceRoutes(),
      CurrenciesRoutes(),
      GetAccessByPermissions(CASL_OBJECTS.APPLICATION_SETTINGS_GROUP) && AppSettingsRoutes(),
      GetAccessByPermissions(CASL_OBJECTS.ADMINISTRATION_SETTINGS_GROUP) && AdminSettingsRoutes()
    ],
    config.basename
  );
}
