import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const businessUnitServices = {
  getBusinessUnitsAll() {
    return axios.get(API_PATH + '/businessUnit');
  },
  postBusinessUnit(businessUnit) {
    return axios.post(API_PATH + '/businessUnit', businessUnit);
  },
  patchBusinessUnit(payload) {
    return axios.patch(API_PATH + '/businessUnit/', payload);
  },
  deleteBusinessUnit(payload) {
    return axios.delete(API_PATH + '/businessUnit', { headers: {}, data: { id: payload } });
  }
};
