import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { activityTypeServices } from './activity-type.services';
import { activityTypeActions } from './activity-type.slice';

export function* activityTypeRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getActivityTypesAll',
      actions: activityTypeActions,
      api: {
        method: activityTypeServices.getActivityTypesAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postActivityType',
      actions: activityTypeActions,
      api: {
        method: activityTypeServices.postActivityType,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchActivityType',
      actions: activityTypeActions,
      api: {
        method: activityTypeServices.patchActivityType,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteActivityType',
      actions: activityTypeActions,
      api: {
        method: activityTypeServices.deleteActivityType,
        callback: (res) => res.data.data
      }
    })
  ]);
}
