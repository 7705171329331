import { Button, Col, Divider, Popconfirm, Row, Space } from 'antd';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
import { useDispatch, useSelector } from 'react-redux';
import { timesheetActions } from 'store/time-sheet/time-sheet.slice';
import { useTimesheet } from '../../hooks';
import { mergeDaysArray } from '../../utils';
import { LineManagerFooter } from '../line-manager-footer';

export const MasterFooter = () => {
  const CASL_OBJECTS = OBJECTS({});
  const week = useSelector((state) => state.timesheet.days);

  const { timesheet, formRef } = useTimesheet();

  const dispatch = useDispatch();

  const handlePopupConfirm = () => {
    dispatch(timesheetActions.deleteTimesheet({ id: timesheet.id }));
  };

  const handleResetClick = () => {
    formRef.current.resetForm();
  };

  const submitForm = (isDraft) => {
    const timeSheetEntryPerActivities = formRef.current.values.timeSheetEntryPerActivities.map((entryPerActivity) => {
      const newObject = {
        ...entryPerActivity,
        activityId: entryPerActivity.activityCascader?.[1],
        comments: entryPerActivity.comments || '',
        timesheetEntry: timesheet?.id,
        dayActivityLinks: mergeDaysArray([
          week.days.map((day) => ({ date: day.theDate })),
          entryPerActivity.dayActivityLinks
        ]).map((day) => ({ ...day, timeSheetPerActivityId: entryPerActivity.id }))
      };

      return newObject;
    });

    const timesheetToSubmit = {
      employee: timesheet.employeeId,
      employeeId: timesheet.employee.id,
      weekId: timesheet.week.weekId,
      ...timesheet,
      isDraft,
      isMaster: true,
      timeSheetEntryPerActivities
    };

    if (!timesheet) {
      dispatch(timesheetActions.submitTimesheet(timesheetToSubmit));
    } else {
      dispatch(timesheetActions.patchTimesheet(timesheetToSubmit));
    }
  };

  const handleTimesheetSubmit = () => {
    formRef.current.validateForm().then((errors) => {
      if (Object.keys(errors).length) {
        return formRef.current.setTouched(errors);
      }
      submitForm(false);
    });
  };

  return (
    <Space style={{ display: 'flex' }} direction="vertical">
      <Row gutter={[20]}>
        <Col style={{ marginRight: 'auto' }} span={10}>
          {Can(
            CASL.CASL_DELETE,
            CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_MASTER_APPROVAL.id
          ) && (
            <Popconfirm title="Sure to delete this timesheet?" placement="top" onConfirm={handlePopupConfirm}>
              <Button block danger type="dashed">
                Delete
              </Button>
            </Popconfirm>
          )}
        </Col>
        <Col span={3}>
          <Button onClick={handleResetClick} block>
            Reset
          </Button>
        </Col>
        <Col span={3}>
          <Button onClick={handleTimesheetSubmit} type="primary" block>
            Save
          </Button>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 0 }} />
      <LineManagerFooter navigateBackAfterDecision={false} isMasterApproval={true} />
    </Space>
  );
};
