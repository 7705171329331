import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  casls: null,
  ready: false,
  loading: false,
  error: false,
  message: {
    status: '',
    message: ''
  },
  socketConnection: false
};

export const { actions: commonActions, reducer: commonReducer } = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setReady(state) {
      state.ready = false;
    },
    setError(state, action) {
      state.error, action;
    },
    setReadyFulfilled(state) {
      state.ready = true;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCasls(state) {
      state.loading = true;
    },
    setCaslsFulfilled(state, action) {
      state.casls = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    setSocketConnection(state, action) {
      state.socketConnection = action.payload;
    }
  }
});
