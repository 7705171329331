import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const activitiesServices = {
  patchDefaultActivity(activity) {
    return activity;
  },
  getActivitiesByProjectId(projectId) {
    return axios.get(API_PATH + `/activity?projectId=${projectId}`);
  },
  createDefaultActivity(defaultActivity) {
    return axios.post(API_PATH + '/activity', defaultActivity);
  },
  postActivity(activity) {
    return axios.post(API_PATH + '/activity', activity);
  },
  patchActivity(payload) {
    return axios.patch(API_PATH + '/activity', payload);
  },
  deleteActivity(payload) {
    return axios.delete(API_PATH + '/activity/' + payload);
  }
};
