import Can from 'casl/can';
import * as CASL from 'casl/constants';
import OBJECTS from 'casl/objects';
// project imports
import MainLayout from 'layout/main-layout';
import { lazy } from 'react';
import { useSelector } from 'react-redux';
import Loadable from 'ui-component/loadable';

// employees routing
const EmployeesLandingPage = Loadable(lazy(() => import('views/employees/employees-list')));
const EmployeesInfo = Loadable(lazy(() => import('views/employees/employee-details')));
const EmployeesLeaveRequests = Loadable(
  lazy(() => import('views/leave-requests/human-resources-leave-request-list/leave-requests-list'))
);
const EmployeesOvertimeRequests = Loadable(
  lazy(() => import('views/overtime-request/components/human-resources-table'))
);
const EmployeesSalaries = Loadable(lazy(() => import('views/employees/salaries')));
const DelegateManager = Loadable(lazy(() => import('views/employees/delegate-manager')));
const LeaveRequestDetails = Loadable(lazy(() => import('views/leave-requests/leave-request-details')));
const OvertimeRequestDetails = Loadable(lazy(() => import('views/overtime-request/overtime-request-details')));
const ManagePendingEmployee = Loadable(
  lazy(() => import('views/synchronize-employees/manage-employee/manage-employee'))
);
const MasterApproval = Loadable(lazy(() => import('views/general-timebooking/master-approval')));
const ImpersonateEmployee = Loadable(lazy(() => import('views/general-timebooking/impersonate-employee')));

// ==============================|| MAIN ROUTING ||============================== //
export default function EmployeesRoutes() {
  const { timesheetAutoReview, moduleEnableLeaves, moduleEnableOvertime } = useSelector(
    (state) => state.appConfiguration
  );
  const CASL_OBJECTS = OBJECTS({});
  return {
    path: '/employees-management',
    element: <MainLayout />,
    children: [
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.id)
        ? [
            {
              path: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.path,
              element: <EmployeesLandingPage />
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES_SALARIES.id)
        ? [
            {
              path: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES_SALARIES.path,
              element: <EmployeesSalaries />
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_DELEGATE_MANAGER.id)
        ? [
            {
              path: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_DELEGATE_MANAGER.path,
              element: <DelegateManager />
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.id)
        ? [
            {
              path: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.EMPLOYEES.path + '/:employeeId',
              element: <EmployeesInfo />
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.id)
        ? [
            {
              path: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.path + ':username',
              element: <ManagePendingEmployee />
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_LEAVE_REQUESTS_ALL.id)
        ? [
            {
              path: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_LEAVE_REQUESTS_ALL.path,
              element: <EmployeesLeaveRequests />,
              isHidden: !moduleEnableLeaves
            },
            {
              path: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_LEAVE_REQUEST_DETAILS.path,
              element: <LeaveRequestDetails />,
              isHidden: !moduleEnableLeaves
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_OVERTIME_REQUESTS.id)
        ? [
            {
              path: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_OVERTIME_REQUESTS.path,
              element: <EmployeesOvertimeRequests HR />,
              isHidden: !moduleEnableOvertime
            },
            {
              path: CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.HR_OVERTIME_REQUEST_DETAILS.path,
              element: <OvertimeRequestDetails />,
              isHidden: !moduleEnableOvertime
            }
          ]
        : []),
      ...(Can(CASL.CASL_READ, CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_IMPERSONATE.id)
        ? [
            {
              path: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_IMPERSONATE.path,
              element: <ImpersonateEmployee />
            }
          ]
        : []),
      ...(Can(
        CASL.CASL_READ,
        CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_MASTER_APPROVAL.id
      )
        ? [
            {
              path: CASL_OBJECTS.VARIOUS_GROUP_SETTINGS.childrenObjects.GENERAL_TIMEBOOKING_MASTER_APPROVAL.path,
              element: <MasterApproval />
            }
          ]
        : [])
    ].filter((route) => !route.isHidden)
  };
}
