import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: ismsClassificationActions, reducer: ismsClassificationReducer } = createSlice({
  name: 'ismsClassification',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getIsmsClassifications: (state) => {
      state.loading = true;
    },
    getIsmsClassificationsFulfilled(state, action) {
      state.all = action.payload;
    }
  }
});
