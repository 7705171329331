import { message } from 'antd';
import axios from 'axios';
import { ssoUrl } from 'store/service';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.config.method !== 'get' && response.config.url.indexOf('entities/byEntityType') === -1) {
      if (response.status === 200 || response.status === 201 || response.status === 204) {
        if (response?.data?.success || !response?.data?.errorMessage) {
          message.success({
            content: response?.data?.message || 'Success!'
          });
        } else {
          message.error({
            content: response?.data?.message || 'A Problem Occured!'
          });
        }
      } else {
        message.error(response.message);
      }
    }
    return response;
  },
  (error) => {
    if (error.response?.status === 403 || error.response?.status === 401) {
      window.location.assign(ssoUrl);
    } else if (error.response?.status === 503) {
      // show error page
    } else {
      const mess = error?.response?.data?.data?.message || error?.response?.data?.errorMessage;
      if (mess) {
        message.error(mess);
      } else {
        message.error(error.message);
      }
    }
  }
);

axiosInstance.interceptors.request.use((request) => {
  const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, '$1');
  request.headers.common['Content-Type'] = 'application/json';
  request.headers.common['X-XSRF-TOKEN'] = csrfToken;

  return request;
});

export default axiosInstance;
