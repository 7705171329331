import { Checkbox } from 'antd';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import { default as OBJECTS } from 'casl/objects';
import { useDispatch } from 'react-redux';
import { caslRolesPrivilegesActions } from 'store/casl-roles-privileges/casl-roles-privileges.slice';

const OnePrivilegeOneRoleCheckbox = ({ checked, groupKey, record, permission }) => {
  const dispatch = useDispatch();

  const handleCheckboxChange = (evt) => {
    const checked = evt.target.checked;
    const relationId = evt.target['data-relationid'];
    const finalObject = { object: groupKey, caslRoleId: record.role, privilege: permission.privilege };
    if (checked) {
      dispatch(caslRolesPrivilegesActions.postCaslRolesPrivileges([finalObject]));
    } else {
      dispatch(caslRolesPrivilegesActions.deleteCaslRolesPrivilege([relationId]));
    }
  };

  return (
    <Checkbox
      checked={checked}
      disabled={
        record.caslRoleName === 'ROLE_SUPERADMIN' && (groupKey === 'rolesSettings' || groupKey === 'permissionSettings')
      }
      onChange={
        Can(CASL.CASL_UPDATE, OBJECTS({}).ADMINISTRATION_SETTINGS_GROUP.childrenObjects.PERMISSION_SETTINGS.id)
          ? handleCheckboxChange
          : () => {}
      }
      data-relationid={
        record?.permissions
          .find((p) => p.object === groupKey)
          ?.privileges.find((pr) => permission.privilege === pr.privilege)?.id
      }
    />
  );
};

export default OnePrivilegeOneRoleCheckbox;
