import { Divider, List, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { notificationsActions } from 'store/notifications';
import useNotificationHook from '../hooks/notificationHook';
import { renderNotificationAvatar } from '../utils/avatar';
import { checkWeek } from '../utils/checkWeek';
import { notificationsTypes } from '../utils/notification-objects';

export const ListItems = ({ item, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentWeekId, settings } = useNotificationHook();
  const notificationObject = notificationsTypes.find((nt) => nt.id === item.notificationType.name);
  const notificationContent = JSON.parse(item.content);

  return (
    <>
      <List.Item
        style={{
          marginRight: '5px',
          borderLeft: !item.isReviewed ? '5px solid #789637' : '5px solid #999999'
        }}
        onClick={() => {
          if (!item.isReviewed) {
            dispatch(notificationsActions.reviewNotification({ ids: [item.id] }));
            navigate(notificationObject.path, {
              state: notificationObject?.locationState(
                notificationContent,
                checkWeek(currentWeekId, notificationContent),
                settings
              )
            });
            handleClose(true);
          }
        }}
      >
        <List.Item.Meta
          style={{ margin: 0 }}
          avatar={renderNotificationAvatar(item.notificationType.name, item.isReviewed)}
          title={<Typography.Text style={{ fontSize: 14 }}>{item.notificationType.description}</Typography.Text>}
          description={
            <>
              {
                <Typography.Text strong style={{ fontSize: 11.5 }}>
                  {item.notificationType.name === 'TIMESHEET_REVIEWED_BY_PROJECT_MANAGERS' ||
                  item.notificationType.name === 'TIMESHEET_SETTINGS_CHANGED'
                    ? ''
                    : item.employeeFullName}
                </Typography.Text>
              }{' '}
              {item.message}
            </>
          }
        />
      </List.Item>
      <Divider style={{ margin: 0 }} />
    </>
  );
};
