import { InfoCircleOutlined } from '@ant-design/icons';
import { IconBriefcase, IconChevronRight, IconCreditCard, IconCreditCardOff } from '@tabler/icons';
import { Badge, Tooltip } from 'antd';

const leaveRequestsPath = '/my-personal-space/my-leave-requests';

export const cardsdata = ({ pendingBusinessTrips, pendingUnpaidLeaves, pendingPaidLeaves, isPaidLeaveAllowed }) => [
  {
    key: 'business-trip',
    title: 'Business Trip',
    icon: <IconBriefcase color="blue" />,
    listdata: [
      {
        key: 'submit',
        title: 'Submit',
        icon: <IconChevronRight />,
        description: 'Submit a new Business Trip Request',
        link: leaveRequestsPath + '/submit-business-trip'
      },
      {
        //todo fix this
        key: 'history',
        title: (
          <Badge dot={pendingBusinessTrips?.length > 0} offset={[10, 8]}>
            History
          </Badge>
        ),
        icon: <IconChevronRight />,
        description: 'See the history of Business Trip requests',
        link: leaveRequestsPath + '/business-trip-history'
      }
    ]
  },
  {
    key: 'unpaid-leave',
    title: 'Unpaid Leave',
    icon: <IconCreditCardOff color="black" />,
    listdata: [
      {
        key: 'submit',
        title: 'Submit',
        icon: <IconChevronRight />,
        description: 'Submit a new Unpaid Leave Request',
        link: leaveRequestsPath + '/submit-unpaid-leave'
      },
      {
        key: 'history',
        title: (
          <Badge dot={pendingUnpaidLeaves?.length > 0} offset={[10, 8]}>
            History
          </Badge>
        ),
        icon: <IconChevronRight />,
        description: 'See the history of Unpaid Leave requests',
        link: leaveRequestsPath + '/unpaid-leave-history'
      }
    ]
  },
  {
    key: 'paid-leave',
    title: 'Paid Leave',
    icon: <IconCreditCard color="green" />,
    listdata: [
      {
        key: 'submit',
        title: 'Submit',
        icon: <IconChevronRight />,
        description: isPaidLeaveAllowed
          ? 'You cannot take Paid Leave if it has been less than 6 months since you started working in the company'
          : 'Submit a new Paid Leave Request',
        link: leaveRequestsPath + '/submit-paid-leave',
        disabled: isPaidLeaveAllowed
      },
      {
        key: 'history',
        title: (
          <Badge dot={pendingPaidLeaves?.length > 0} offset={[10, 8]}>
            History
          </Badge>
        ),
        icon: <IconChevronRight />,
        description: 'See the history of Paid Leave requests',
        link: leaveRequestsPath + '/paid-leave-history'
      }
    ]
  }
  // uncomment on new requests developement
  // ,{
  //     title: 'Medical Leave',
  //     icon: <IconMedicalCross color="red" />,
  //     listdata: [
  //         {
  //             title: 'Submit',
  //             icon: <IconChevronRight />,
  //             description: 'Submit a new Medical Leave Request',
  //             link: leaveRequestsPath + '/submit-medical-leave'
  //         },
  //         {
  //             title: 'History',
  //             icon: <IconChevronRight />,
  //             description: 'See the history of Medical Leave requests',
  //             link: leaveRequestsPath + '/history-medical-leave'
  //         }
  //     ]
  // },
  // {
  //     title: 'Compensation Leave',
  //     icon: <IconReportMoney color="orange" />,
  //     listdata: [
  //         {
  //             title: 'Submit',
  //             icon: <IconChevronRight />,
  //             description: 'Submit a new Compensation Leave Request',
  //             link: leaveRequestsPath + '/submit-compensation-leave'
  //         },
  //         {
  //             title: 'History',
  //             icon: <IconChevronRight />,
  //             description: 'See the history of Compensation Leave Requests',
  //             link: leaveRequestsPath + '/compensation-leave-history'
  //         }
  //     ]
  // }
];

export const makeNewBusinessTripArray = (arr) => {
  return arr.map((item) => {
    return {
      ...item,
      projectManagerId: 0,
      subprojectId: item.businessTripRequest?.subprojectId,
      status: 0,
      operation: 0
    };
  });
};

export const makeNewUnpaidLeaveArray = (arr) => {
  return arr.map((item) => {
    return {
      ...item,
      status: 0,
      operation: 0
    };
  });
};

export const makeNewPaidLeaveArray = (arr) => {
  return arr.map((item) => {
    return {
      ...item,
      status: 0,
      operation: 0
    };
  });
};
