import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: [],
  lastSequence: 0
};

export const selectActivityByWorkpackageId = (state, workpackageId) => {
  const abs = state.activities.all
    .filter((ac) => ac.subprojectId === workpackageId)
    .sort((a, b) => a.code.localeCompare(b.code));
  const lastSequence = abs.at(-1)?.code?.slice(-3) || 0;
  const lastSequencePlusOne = (+lastSequence + 1 + '').padStart(3, '0');
  return { activities: abs, lastSequence: lastSequencePlusOne };
};

export const selectActivityById = (state, activityId) => {
  const abs = state.activities.all.find((ac) => ac.id === activityId);
  return abs;
};

export const { actions: activitiesActions, reducer: activitiesReducer } = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getActivitiesByProjectId: (state) => {
      state.loading = true;
    },
    getActivitiesByProjectIdFulfilled: (state, action) => {
      state.all = [...action.payload];
    },
    postActivity: (state) => {
      state.loading = true;
    },
    postActivityFulfilled(state, action) {
      const data = { ...action.payload };
      state.all = [...state.all, data];
    },
    patchActivity: (state) => {
      state.loading = true;
    },
    patchActivityFulfilled(state, action) {
      let act = [...state.all];
      const index = act.findIndex((item) => item.id === action.payload.id);
      act.splice(index, 1, {
        ...action.payload
      });
      state.all = [...act];
    },
    patchDefaultActivity(state) {
      state.loading = true;
    },
    patchDefaultActivityFulfilled(state, action) {
      let newDefAct = [...state.all, action.payload];
      state.all = newDefAct;
    },
    patchActivityStatus(state, action) {
      state.loading = true;
    },
    patchActivityStatusFulfilled(state, action) {
      const allActivities = [];
      action.payload.map((wp) => {
        const acts = state.all.filter((ac) => ac.subprojectId === wp.id);
        allActivities.push(...acts);
      });
      const newAct = [...state.all];
      newAct.map((act) => {
        if (allActivities.indexOf(act) > -1) {
          act.isDisabled = 1;
        }
      });
      state.all = [...newAct];
    },
    deleteActivity: (state) => {
      state.loading = true;
    },
    deleteActivityFulfilled(state, action) {
      const updatedActivities = state.all.filter((act) => act.id !== action.payload);
      state.all = [...updatedActivities];
    }
  }
});
