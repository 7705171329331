import { Avatar, ButtonBase } from '@mui/material';
import { IconUsers } from '@tabler/icons';
import { Badge, Tooltip } from 'antd';
import Can from 'casl/can';
import * as CASL from 'casl/constants';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pendingEmployeeActions } from 'store/pending-employees/pending-employees.slice';
import SynchronizeEmployees from 'views/synchronize-employees/pending-employees-list';
import { useHeaderHook } from '../useHeaderHook';

export const SyncEmployeesButton = () => {
  const { CASL_OBJECTS, theme } = useHeaderHook();
  const dispatch = useDispatch();
  const employeesToBeAdded = useSelector((state) => state.pendingEmployees.toBeAdded);
  const employeesToBeRemoved = useSelector((state) => state.pendingEmployees.toBeRemoved);

  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return Can(CASL.CASL_READ, CASL_OBJECTS.EMPLOYEE_SETTINGS_GROUP.childrenObjects.SYNC_EMPLOYEES.id) ? (
    <Tooltip title="Pending Employees">
      <Badge dot={Math.max(employeesToBeAdded.length, employeesToBeRemoved.length) !== 0}>
        <ButtonBase sx={{ borderRadius: '12px' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.main,
              color: theme.palette.secondary.dark,
              '&[aria-controls="menu-list-grow"],&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light
              }
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={() => {
              handleToggle();
              if (!open) {
                dispatch(pendingEmployeeActions.getPendingEmployees());
              }
            }}
            color="inherit"
          >
            <IconUsers stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Badge>
      <SynchronizeEmployees handleClose={handleClose} anchorRef={anchorRef} open={open} setOpen={setOpen} />
    </Tooltip>
  ) : (
    <></>
  );
};
