import { IconArrowBigLeftLine } from '@tabler/icons';
import { Typography } from 'antd';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';

export const BackLink = ({ to }) => {
  console.log('to is',to)
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Typography.Link>
      <IconArrowBigLeftLine
        style={{ verticalAlign: 'middle' }}
        color={theme.palette.secondary.main}
        onClick={() => navigate(to || -1)}
        size={22}
      />
    </Typography.Link>
  );
};
