import { createSlice } from '@reduxjs/toolkit';
import { compareAsc, parseISO } from 'date-fns';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const selectLastSellingPrice = (state) => {
  const sellingPrice = state.sellingPrices.all[0];
  return sellingPrice;
};

export const { actions: sellingPriceActions, reducer: sellingPriceReducer } = createSlice({
  name: 'sellingPrice',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getSellingPriceByworkpackageId: (state) => {
      state.loading = true;
    },
    getSellingPriceByworkpackageIdFulfilled(state, action) {
      state.all = action.payload.sort((a, b) => compareAsc(parseISO(b.validFrom), parseISO(a.validFrom)));
    },
    createSellingPrice: (state) => {
      state.loading = true;
    },
    createSellingPriceFulfilled(state, action) {
      const sellingPrice = action.payload;
      const newPrices = [...state.all, sellingPrice];
      return {
        ...state,
        all: newPrices.sort((a, b) => compareAsc(parseISO(b.validFrom), parseISO(a.validFrom)))
      };
    },
    patchSellingPrice: (state) => {
      state.loading = true;
    },
    patchSellingPriceFulfilled(state, action) {
      const sellingPrice = action.payload;
      let newSellingPrices = state.all.filter((sel) => sel.id !== sellingPrice.id);
      const index = newSellingPrices.findIndex(
        (p) => compareAsc(parseISO(sellingPrice.validFrom), parseISO(p.validFrom)) > 0
      );
      if (index === -1) {
        newSellingPrices.splice(newSellingPrices.length, 0, sellingPrice);
      } else {
        newSellingPrices.splice(index, 0, sellingPrice);
      }
      return {
        ...state,
        all: [...newSellingPrices]
      };
    },
    deleteSellingPrice: (state) => {
      state.loading = true;
    },
    deleteSellingPriceFulfilled(state, action) {
      const newPrices = state.all.filter((prog) => prog.id !== action.payload);
      return {
        ...state,
        all: [...newPrices]
      };
    }
  }
});
