import { all, put, takeLatest } from 'redux-saga/effects';
import { store } from 'store';
import { selectWorkpackageByProjectId, workpackageActions } from 'store/workpackage/workpackage.slice';
import { fetchSaga } from 'utils/fetch-saga';
import { activitiesServices } from './activities.services';
import { activitiesActions } from './activities.slice';

export function* activitiesRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getActivitiesByProjectId',
      actions: activitiesActions,
      api: {
        method: activitiesServices.getActivitiesByProjectId,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postActivity',
      actions: activitiesActions,
      api: {
        method: activitiesServices.postActivity,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchActivity',
      actions: activitiesActions,
      api: {
        method: activitiesServices.patchActivity,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchDefaultActivity',
      actions: activitiesActions,
      api: {
        method: activitiesServices.patchDefaultActivity,
        callback: (res) => res
      }
    }),
    fetchSaga({
      pattern: 'patchActivityStatus',
      actions: activitiesActions,
      api: {
        method: activitiesServices.patchDefaultActivity,
        callback: (res) => res
      }
    }),
    fetchSaga({
      pattern: 'deleteActivity',
      actions: activitiesActions,
      api: {
        method: activitiesServices.deleteActivity,
        callback: (res) => res.data.data
      }
    }),
    takeLatest(workpackageActions.postWorkpackageFulfilled.type, dispatchPatchDefaultActivity),
    takeLatest(
      workpackageActions.patchDefaultWorkpackageFulfilled.type,
      dispatchPatchDefaultActivityForDefaultWorkpackage
    ),
    takeLatest(workpackageActions.patchChangingStatusFulfilled.type, dispatchPatchChangingStatus),
    takeLatest(workpackageActions.changeWorkpackageStatusFulfilled.type, dispatchPatchChangingStatus)
  ]);
}

// default activity on workpackage creation
function* dispatchPatchDefaultActivity(action) {
  const data = action.payload;
  yield put(activitiesActions.patchDefaultActivity(data.activities[0]));
}

// default activity on project creation
function* dispatchPatchDefaultActivityForDefaultWorkpackage(action) {
  const data = action.payload;
  yield put(activitiesActions.patchDefaultActivity(data.activities[0]));
}

// for changing status
function* dispatchPatchChangingStatus(action) {
  let workpackages = [];
  if (action.payload.code) {
    workpackages = selectWorkpackageByProjectId(store.getState(), action.payload.id);
    if (action.payload.projectStatus.name !== 'Ongoing') {
      yield put(activitiesActions.patchActivityStatus(workpackages));
    }
  } else {
    const workpackage = store.getState().workpackages.all.find((wp) => wp.id === action.payload.id);
    workpackages = [workpackage];
    if (action.payload.projectStatus.name !== 'Ongoing') {
      yield put(activitiesActions.patchActivityStatus(workpackages));
    }
  }
}
