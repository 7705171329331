import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const caslRolesPrivilegesServices = {
  getCaslRolesPrivilegesAll() {
    return axios.get(API_PATH + '/caslRolesPrivileges');
  },
  postCaslRolesPrivileges(payload) {
    return axios.post(API_PATH + '/caslRolesPrivileges', { caslRolePrivileges: payload });
  },
  deleteCaslRolesPrivilege(payload) {
    return axios.delete(API_PATH + '/caslRolesPrivileges', { headers: {}, data: { ids: payload } });
  }
};
