import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: jobTitlesActions, reducer: jobTitlesReducer } = createSlice({
  name: 'jobTitles',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getJobTitlesAll(state) {
      state.loading = true;
    },
    getJobTitlesAllFulfilled(state, action) {
      state.all = action.payload.sort((a, b) => a.name.localeCompare(b.name));
    },
    postJobTitle(state) {
      state.loading = true;
    },
    postJobTitleFulfilled(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) => a.name.localeCompare(b.name));
    },
    patchJobTitle(state) {
      state.loading = true;
    },
    patchJobTitleFulfilled(state, action) {
      const arr = [...state.all];
      const foundIndex = arr.findIndex((item) => item.id === action.payload.id);
      arr.splice(foundIndex, 1, action.payload);
      state.all = arr;
    },
    deleteJobTitle(state) {
      state.loading = true;
    },
    deleteJobTitleFulfilled(state, action) {
      state.all = state.all.filter((d) => d.id !== action.payload);
    }
  }
});
