import { useEffect, useLayoutEffect, useState } from 'react';

const useTableScrollHeight = ({ statusName, ...props }) => {
  const [size, setSize] = useState([0, 0]);
  const selector = statusName ? '.' + statusName + ' .ant-table-body' : '.ant-table-body';
  useLayoutEffect(() => {
    const y = document.querySelector(selector)?.getBoundingClientRect();
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight - 81 - y?.top]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [statusName]);
  return size;
};

export default useTableScrollHeight;
