import { createSlice } from '@reduxjs/toolkit';
import { compareAsc, parseISO } from 'date-fns';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const selectLastProgress = (state) => {
  return state.progresses.all[0];
};

export const { actions: workpackageProgressActions, reducer: workpackageProgressReducer } = createSlice({
  name: 'workpackageProgress',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getProgressByworkpackageId: (state) => {
      state.loading = true;
    },
    getProgressByworkpackageIdFulfilled(state, action) {
      state.all = action.payload.sort((a, b) => compareAsc(parseISO(b.validFrom), parseISO(a.validFrom)));
    },
    createProgress: (state) => {
      state.loading = true;
    },
    createProgressFulfilled(state, action) {
      state.all = [...state.all, action.payload].sort((a, b) =>
        compareAsc(parseISO(b.validFrom), parseISO(a.validFrom))
      );
    },
    patchProgress: (state) => {
      state.loading = true;
    },
    patchProgressFulfilled(state, action) {
      const progress = action.payload;
      let newProgresses = state.all.filter((sel) => sel.id !== progress.id);
      const index = newProgresses.findIndex((p) => compareAsc(parseISO(progress.validFrom), parseISO(p.validFrom)) > 0);
      if (index === -1) {
        newProgresses.splice(newProgresses.length, 0, progress);
      } else {
        newProgresses.splice(index, 0, progress);
      }
      state.all = [...newProgresses];
    },
    deleteProgress: (state) => {
      state.loading = true;
    },
    deleteProgressFulfilled(state, action) {
      const newProgresses = state.all.filter((prog) => prog.id !== action.payload);
      state.all = [...newProgresses];
    }
  }
});
