import { useSelector } from 'react-redux';

const useNotificationHook = () => {
  const currentWeekId = useSelector((state) => state.timesheet.currentWeekId);
  const settings = useSelector((state) => state.timesheetSettings.myTimesheetSettings);

  const readNotifications = useSelector((state) => state.notifications.readNotifications);
  const unreadNotificationsAll = useSelector((state) => state.notifications.unreadNotifications);
  const unreadNotifications = unreadNotificationsAll.filter(
    (notification) => notification.notificationType?.name !== 'TIMESHEET_SUBMIT_TO_LINE_MANAGER'
  );
  const notificationsPaging = useSelector((state) => state.notifications.paging);
  const sortedNotificationsForLineManager = unreadNotificationsAll.filter(
    (notification) => notification.notificationType?.name === 'TIMESHEET_SUBMIT_TO_LINE_MANAGER'
  );
  const teamPendingTimesheetsPath = '/my-team-administration/team-timesheet-requests/pending';

  return {
    currentWeekId,
    settings,
    readNotifications,
    unreadNotificationsAll,
    unreadNotifications,
    notificationsPaging,
    sortedNotificationsForLineManager,
    teamPendingTimesheetsPath
  };
};

export default useNotificationHook;
