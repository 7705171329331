import * as yup from 'yup';

export const exchangeScheme = yup.object().shape({
  fromCurrency: yup.number().typeError('Please select a from currency!').required('Please select a from currency!'),
  toCurrency: yup.number().typeError('Please select a to currency!').required('Please select a to currency!'),
  conversionRate: yup
    .number()
    .max(999999999, 'Maximum digits length is 8')
    .typeError('Please enter a rate!')
    .required('Please enter a rate!'),
  date: yup.date().typeError('Please select a date!').required('Please select a date!'),
  isActive: yup.bool()
});
