import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  allTimesheetSettings: null,
  addingWeekSettings: null,
  editingWeekSettings: null,
  myTimesheetSettings: null,
  impersonateTimesheetSettings: null,
  masterTimesheetSettings: null,
  changingWeek: null,
  deletingSetting: null
};

export const { actions: timesheetSettingsActions, reducer: timesheetSettingsReducer } = createSlice({
  name: 'timesheet-settings',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    getAllTimesheetSettings: (state) => {
      state.loading = true;
    },
    getAllTimesheetSettingsFulfilled(state, { payload }) {
      state.allTimesheetSettings = payload;
    },
    setAddingWeekSettings(state, { payload }) {
      state.addingWeekSettings = payload;
    },
    postNewSettings(state) {
      state.loading = true;
    },
    postNewSettingsFulfilled(state, { payload }) {
      state.addingWeekSettings = false;

      state.allTimesheetSettings = state.allTimesheetSettings.map((item) => ({
        ...item,
        timesheetSettings:
          payload.weekType.name === item.weekType.name
            ? [...item.timesheetSettings, payload]
            : [...item.timesheetSettings]
      }));
    },
    patchTimesheetSettingDays(state, { payload }) {
      state.loading = true;
      state.changingWeek = payload.weekType.name;
    },
    patchTimesheetSettingDaysFulfilled(state, { payload }) {
      state.changingWeek = null;

      state.allTimesheetSettings = state.allTimesheetSettings.map((week) => ({
        ...week,
        timesheetSettings: week.timesheetSettings.map((setting) => ({
          ...setting,
          timesheetSettingDays:
            payload.find((payloadSetting) => payloadSetting?.id === setting.id)?.timesheetSettingDays ||
            setting.timesheetSettingDays
        }))
      }));
    },
    getMyTimesheetSettings(state) {
      state.loading = true;
    },
    getMyTimesheetSettingsFulfilled(state, { payload }) {
      state.myTimesheetSettings = payload;
    },
    getImpersonateTimesheetSettings(state) {
      state.loading = true;
    },
    getImpersonateTimesheetSettingsFulfilled(state, { payload }) {
      state.impersonateTimesheetSettings = payload;
    },
    getMasterTimesheetSettings(state) {
      state.loading = true;
    },
    getMasterTimesheetSettingsFulfilled(state, { payload }) {
      state.masterTimesheetSettings = payload;
    },
    setEditingWeekSettings(state, { payload }) {
      state.editingWeekSettings = payload;
    },
    patchTimesheetSetting(state) {
      state.loading = true;
    },
    patchTimesheetSettingFulfilled(state, { payload }) {
      state.editingWeekSettings = null;

      state.allTimesheetSettings = state.allTimesheetSettings.map((week) => ({
        ...week,
        timesheetSettings: week.timesheetSettings.map((setting) => (setting.id === payload.id ? payload : setting))
      }));
    },
    toggleDeleteSetting(state, { payload }) {
      state.deletingSetting = payload;
    },
    deleteSetting(state) {
      state.loading = true;
    },
    deleteSettingFulfilled(state, { payload }) {
      state.allTimesheetSettings = state.allTimesheetSettings.map((week) => ({
        ...week,
        timesheetSettings: week.timesheetSettings.filter((setting) => setting.id !== payload)
      }));
      state.deletingSetting = null;
      state.loading = false;
    },
    updateMyTimesheetSettingsFromNotificationResponse(state, action) {
      const newSettings = { ...action.payload };
      state.myTimesheetSettings = newSettings;
    }
  }
});
