import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const budgetServices = {
  getAllBudgetsByWorkpackageId(subprojectId) {
    return axios.get(API_PATH + `/subprojectBudget?subprojectId=${subprojectId}`);
    // return [];
  },
  submitNewBudget(payload) {
    return {
      data: axios.post(API_PATH + '/subprojectBudget', payload.data),
      nav: payload.nav,
      setStep: payload.setStep
    };
  },
  deleteBudget(id) {
    return axios.delete(API_PATH + '/subprojectBudget/' + id);
  },
  postSubcontractorTechnicalProgress(payload) {
    return axios.post(API_PATH + '/subprojectBudget/technicalProgress', payload);
  },
  post3rdPartyRealValue(payload) {
    return axios.post(API_PATH + '/subprojectBudget/realValue', payload);
  }
};
