import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';
export const projectWorpakacageManagerDelegationServices = {
  getAllProjectManagers() {
    return axios.get(API_PATH + '/employee/all/active/projectDirectors');
  },
  getAllWorkpackageManagers() {
    return axios.get(API_PATH + '/employee/all/active/subprojectManagers');
  },
  getAllProjectsByManagerId(projectDirectorId) {
    return axios.get(API_PATH + `/project/byProjectDirector?projectDirectorId=${projectDirectorId}`);
  },
  getAllWorkpackagesByManagerId(managerId) {
    return axios.get(API_PATH + `/subproject/bySubprojectManager?managerId=${managerId}`);
  },
  permanentlyDelegateManager(payload) {
    return axios.post(API_PATH + `/project/permanently-delegate-manager`, payload);
  }
};
