import { Space, Typography } from 'antd';

const { Text } = Typography;

export const activityProps = {
  render({ project, workPackage, subproject, activity }) {
    return (
      <Space direction="vertical" size={'small'}>
        <span>
          <Text strong>{project?.code}</Text> {' - '}
          <Text>{project?.name}</Text>
        </span>
        <span>
          {workPackage && !subproject ? (
            <>
              <Text strong>{workPackage?.sequence}</Text> {' - '} <Text>{workPackage?.description}</Text>
            </>
          ) : (
            <>
              {' - '}
              <Text>{subproject.name}</Text>
            </>
          )}
        </span>
        <span>
          {activity.description ? (
            <>
              <Text strong>{activity?.code}</Text> {' - '} <Text>{activity?.description}</Text>
            </>
          ) : (
            <>
              <Text strong>{activity?.code}</Text> {' - '} <Text>{activity?.name}</Text>
            </>
          )}
        </span>
      </Space>
    );
  }
};

const hoursCell = {
  render(_, dayIndex, day, weekInfo) {
    return (
      <Text strong type={weekInfo[dayIndex].dayTargetHours === day?.manHours && day?.manHours && 'success'}>
        {day?.manHours || '-'}
      </Text>
    );
  }
};

const commentsProps = {
  width: 250,
  dataIndex: 'comments',
  render(comments) {
    return comments;
  }
};

export const nonEditableTimesheetCols = {
  activityProps,
  commentsProps,
  dayProps: hoursCell
};
