import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const currencyServices = {
  getCurrenciesAll() {
    return axios.get(API_PATH + '/currency');
  },
  postCurrency(currency) {
    return axios.post(API_PATH + '/currency', currency);
  }
};
