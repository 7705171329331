import { Menu, Space } from 'antd';
import OneRoleAllGroupPermissionsCheckbox from './components/one-role-all-group-permissions-checkbox';
import OneRoleAllObjectPermissionsCheckbox from './components/one-role-all-object-permissions-checkbox';

export const RoleOptions = ({ record, groupKeys, data }) => {
  return (
    <Menu
      onClick={(e) => e.preventDefault()}
      items={[
        {
          key: 'all',
          label: (
            <Space>
              <OneRoleAllGroupPermissionsCheckbox groupKeys={groupKeys} record={record} data={data} />
            </Space>
          ),
          children: groupKeys
            .filter((groupKey) => !groupKey.hidden)
            .map((group) => {
              return {
                label: (
                  <Space>
                    <OneRoleAllObjectPermissionsCheckbox
                      group={group}
                      record={record}
                      data={data}
                      groupKeys={groupKeys}
                    />
                  </Space>
                ),
                key: group.id
              };
            })
        }
      ]}
    />
  );
};
