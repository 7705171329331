import { createAction } from '@reduxjs/toolkit';

// action - customization reducer
export const menuToggle = createAction('@customization/MENU_TOGGLE');
export const menuOpen = createAction('@customization/MENU_OPEN');
export const setMenu = createAction('@customization/SET_MENU');
export const setCollapse = createAction('@customization/SET_COLLAPSE');
export const setFontFamily = createAction('@customization/SET_FONT_FAMILY');
export const setBorderRadius = createAction('@customization/SET_BORDER_RADIUS');

// initialise store
export const initializeStore = createAction('@app/INITIALIZE_STORE');
export const finishStoreInitialisation = createAction('@app/FINISH_STORE_INITIALISATION');

// app settings and common parameters
export const getPositionCategories = createAction('@app/GET_POSITION_CATEGORIES');
export const getProjectManagers = createAction('@app/GET_PROJECT_MANAGERS');

// project related
export const getProjects = createAction('@projects/GET_PROJECTS');
export const getActivities = createAction('@projects/GET_ACTIVITIES');
export const getWorkpackagesByProjectId = createAction('@projects/GET_SUBPROJECTS_BY_PROJECT_ID');
export const getWorkpackagesAll = createAction('@projects/GET_SUBPROJECTS_ALL');
export const patchProject = createAction('@projects/PATCH_PROJECT');
export const postProject = createAction('@projects/POST_PROJECT');
export const patchSubproject = createAction('@projects/PATCH_SUBPROJECT');
export const postSubproject = createAction('@projects/POST_SUBPROJECT');
export const patchActivity = createAction('@projects/PATCH_ACTIVITY');
export const postActivity = createAction('@projects/POST_ACTIVITY');
export const changeActiveTabProjectsList = createAction('@projects/CHANGE_ACTIVE_TAB_PROJECTS_LIST');

// subproject related
export const getProgressByworkpackageId = createAction('@subproject/GET_PROGRESSES_BY_SUBPROJECT_ID');
export const patchProgress = createAction('@subproject/PATCH_PROGRESS');
export const postProgress = createAction('@subproject/POST_PROGRESS');
export const deleteProgress = createAction('@subproject/DELETE_PROGRESS');
export const getSellingPricesByworkpackageId = createAction('@subproject/GET_SELLING_PRICES_BY_SUBPROJECT_ID');
export const patchSellingPrice = createAction('@subproject/PATCH_SELLING_PRICE');
export const postSellingPrice = createAction('@subproject/POST_SELLING_PRICE');
export const deleteSellingPrice = createAction('@subproject/DELETE_SELLING_PRICE');

// timesheet stuff
export const submitTimesheet = createAction('@timesheet/SUBMIT_TIMESHEET');
export const getTimesheet = createAction('@timesheet/GET_TIMESHEET');
export const getMyTimesheets = createAction('@timesheet/GET_MY_TIMESHEETS');
export const getTimesheetSettings = createAction('@timesheet/GET_TIMESHEET_SETTINGS');
export const patchTimesheetSettings = createAction('@timesheet/PATCH_TIMESHEET_SETTINGS');
export const setWeekSettings = createAction('@timesheet/SET_WEEK_SETTINGS');
export const setTimesheetSettingsTab = createAction('@timesheet/SET_TIMESHEET_SETTINGS_TAB');
export const setAllTtmesheetActivities = createAction('@timesheet/SET_ALL_TTMESHEET_ACTIVITIES');
export const setAllSelectedActivities = createAction('@timesheet/SET_ALL_SELECTED_ACTIVITIES');
export const getTeamPendingTimesheets = createAction('@timesheet/GET_TEAM_PENDING_TIMESHEETS');
export const approveTimesheet = createAction('@timesheet/APPROVE_TIMESHEET');
export const deleteTimesheet = createAction('@timesheet/DELETE_TIMESHEET');
export const getAllTimesheetsMaster = createAction('@timesheet/GET_ALL_TIMESHEETS_MASTER');
export const setTimesheetMasterPage = createAction('@timesheet/SET_TIMESHEET_MASTER_PAGE');
export const setTimesheetMasterFilters = createAction('@timesheet/SET_TIMESHEET_MASTER_FILTERS');

// leave requests stuff
export const startFetching = createAction('@leave_requests/START_FETCHING');
export const getAllLeaveRequestsTypes = createAction('@leave_requests/GET_ALL_LEAVE_REQUESTS_TYPES');
export const getAllLeaveRequests = createAction('@leave_requests/GET_ALL_LEAVE_REQUESTS');
export const getMyLeaveRequests = createAction('@leave_requests/GET_MY_LEAVE_REQUESTS');
export const postLeaveRequest = createAction('@leave_requests/POST_LEAVE_REQUEST');
export const setRequestForDetails = createAction('@leave_requests/SET_REQUEST_FOR_DETAILS');
export const getBusinessTripRequestsByProjectManagerId = createAction(
  '@leave_requests/GET_BUSINESS_TRIP_REQUESTS_BY_PROJECT_MANAGER_ID'
);

export const postDirectManagerConfirmation = createAction('@leave_requests/POST_DIRECT_MANAGER_CONFIRMATION');
export const postProjectManagerConfirmation = createAction('@leave_requests/POST_PROJECT_MANAGER_CONFIRMATION');
export const patchLeaveRequest = createAction('@leave_requests/PATCH_LEAVE_REQUEST');

// budget stuff
export const getAllBudgetsByWorkpackageId = createAction('@subproject_budget/GET_ALL_BUDGETS_BY_SUBPROJECT_ID');
export const postBudget = createAction('@subproject_budget/POST_BUDGET');
export const startPostingBudget = createAction('@subproject_budget/START_POSTING_BUDGET');
export const endPostingBudget = createAction('@subproject_budget/END_POSTING_BUDGET');
export const deleteBudget = createAction('@subproject_budget/DELETE_BUDGET');

// employees stuff
export const getAllEmployees = createAction('@employees/GET_ALL_EMPLOYEES');
export const patchEmployee = createAction('@employees/PATCH_EMPLOYEE');
export const postEmployee = createAction('@employees/POST_EMPLOYEE');
export const getEmployeePositionCategoryHistory = createAction('@employees/GET_EMPLOYEE_POSITION_CATEGORY_HISTORY');
export const patchEmployeePositionCategoryHistory = createAction('@employees/PATCH_EMPLOYEE_POSITION_CATEGORY_HISTORY');
export const postEmployeePositionCategoryHistory = createAction('@employees/POST_EMPLOYEE_POSITION_CATEGORY_HISTORY');
export const deleteEmployeePositionCategoryHistory = createAction(
  '@employees/DELETE_EMPLOYEE_POSITION_CATEGORY_HISTORY'
);
export const changeActiveTabEmployeeList = createAction('@employees/CHANGE_ACTIVE_TAB_EMPLOYEE_LIST');

// pending employees stuff (newcomers/leavers)
export const getAllPendingEmployees = createAction('@pending-employees/GET_ALL_PENDING_EMPLOYEES');
export const registerEmployee = createAction('@pending-employees/REGISTER_EMPLOYEE');
export const disactivateEmployee = createAction('@pending-employees/DISACTIVATE_EMPLOYEE');

// position categories stuff
export const getAllPositionCategories = createAction('@position_categories/GET_ALL_POSITION_CATEGORIES');
export const getAllPositionCategoriesHistory = createAction('@position_categories/GET_ALL_POSITION_CATEGORIES_HISTORY');
export const deletePositionCategoryHistory = createAction('@position_categories/DELETE_POSITION_CATEGORY_HISTORY');
export const patchPositionCategory = createAction('@position_categories/PATCH_POSITION_CATEGORY');
export const postPositionCategoryHistory = createAction('@position_categories/POST_POSITION_CATEGORY_HISTORY');
export const patchPositionCategoryHistory = createAction('@position_categories/PATCH_POSITION_CATEGORY_HISTORY');
export const postPositionCategory = createAction('@position_categories/POST_POSITION_CATEGORY');

// disciplines stuff
export const postDiscipline = createAction('@disciplines/POST_DISCIPLINE');
export const patchDiscipline = createAction('@disciplines/PATCH_DISCIPLINE');
export const getAllDisciplines = createAction('@disciplines/GET_ALL_DISCIPLINES');

// currencies stuff
export const getAllCurrencies = createAction('@currency/GET_ALL_CURRENCIES');
export const postCurrency = createAction('@currency/POST_CURRENCY');

// entities stuff
export const getAllEntities = createAction('@entities/GET_ALL_ENTITIES');
export const postEntity = createAction('@entities/POST_ENTITY');
export const patchEntity = createAction('@entities/PATCH_ENTITY');
export const getAllEntitiesAndTypes = createAction('@entities/GET_ALL_ENTITIES_AND_TYPES');
export const postEntityTypeConnection = createAction('@entitiesTypes/POST_ENTITY_TYPE_CONNECTION');
export const deleteEntityTypeConnection = createAction('@entitiesTypes/DELETE_ENTITY_TYPE_CONNECTION');

// entity types stuff
export const getAllEntityTypes = createAction('@entities/GET_ALL_ENTITY_TYPES');
export const postEntityType = createAction('@entities/POST_ENTITY_TYPE');
export const patchEntityType = createAction('@entities/PATCH_ENTITY_TYPE');

// quality risk levels stuff
export const getQualityRiskLevel = createAction('@quality_risk_levels/GET_QUALITY_RISK_LEVEL');
export const postQualityRiskLevel = createAction('@quality_risk_levels/POST_QUALITY_RISK_LEVEL');
export const patchQualityRiskLevel = createAction('@quality_risk_levels/PATCH_QUALITY_RISK_LEVEL');

// activity types stuff
export const getActivityTypes = '@activity_types/GET_ACTIVITY_TYPES';
export const postActivityType = '@activity_types/POST_ACTIVITY_TYPE';
export const patchActivityType = '@activity_types/PATCH_ACTIVITY_TYPE';

// contract types stuff
export const getContractTypes = '@contract-types/GET_CONTRACT_TYPES';
export const postContractType = '@contract-types/POST_CONTRACT_TYPE';
export const patchContractType = '@contract-types/PATCH_CONTRACT_TYPE';

// project statuses stuff
export const getProjectStatus = '@project-status/GET_PROJECT_STATUS';
export const postProjectStatus = '@project-status/POST_PROJECT_STATUS';
