import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false
};

export const { actions: appConfigurationActions, reducer: appConfigurationReducer } = createSlice({
  name: 'appConfiguration',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getAppConfiguration: (state) => {
      state.loading = true;
    },
    getAppConfigurationFulfilled: (state, action) => {
      const newState = {
        ...state,
        ...JSON.parse(action.payload.data.data.details),
        entityCode: action.payload.data.data.entityCode
      };
      return newState;
    }
  }
});
