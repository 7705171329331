import { ismsClassificationServices } from './isms-classification.services';
import { fetchSaga } from 'utils/fetch-saga';
import { all } from 'redux-saga/effects';
import { ismsClassificationActions } from './isms-classification.slice';

export function* ismsClassificationRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getIsmsClassifications',
      actions: ismsClassificationActions,
      api: {
        method: ismsClassificationServices.getIsmsClassifications,
        callback: (res) => res.data.data
      }
    })
  ]);
}
