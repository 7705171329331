import axios from 'axios';
import { API_PATH } from 'store/service';

const logout = () => {
  axios.get(`${API_PATH}/auth/logout`).then(() => {
    window.location.replace('https://sso2.assystem.com/cas/logout');
  });
};

export default {
  logout
};
