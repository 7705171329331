import { IconCheck } from '@tabler/icons';
import { Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { myProjectsTeamsTimesheetsActions } from 'store/my-projects-teams-timesheets';

export const PDComment = ({ tpaid: tpaId, initialComment }) => {
  const dispatch = useDispatch();

  const handleFormSubmit = ({ comments }) => {
    dispatch(
      myProjectsTeamsTimesheetsActions.reviewOnePendingProjectTeamsTimesheet({
        tpaId,
        comments
      })
    );
  };

  return (
    <Form
      onFinish={handleFormSubmit}
      initialValues={{ comments: initialComment || '' }}
      style={{ position: 'relative' }}
    >
      <Form.Item initialValue={initialComment} name="comments">
        <Input.TextArea name="comments" showCount maxLength={500} />
      </Form.Item>
      <Button
        htmlType="submit"
        style={{ position: 'absolute', top: 3, right: 3, width: 18, height: 18 }}
        type="primary"
        ghost
        icon={<IconCheck style={{ width: 14, height: 14 }} />}
      />
    </Form>
  );
};
