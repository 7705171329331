import { combineReducers } from 'redux';
// import entityTypesSlice from './reducer/entity-type-slice';
import { entityTypeReducer } from 'store/entity-type/entity-type.slice';
import { activitiesReducer } from './activities';
import { activityTypeReducer } from './activity-type';
import { appConfigurationReducer } from './app-configuration';
import { budgetReducer } from './budget';
import { businessUnitReducer } from './business-unit';
import { caslRolesReducer } from './casl-roles';
import { caslRolesPrivilegesReducer } from './casl-roles-privileges/casl-roles-privileges.slice';
import { commonReducer } from './common/common.slice';
import { contractTypesReducer } from './contract-types';
import { currencyReducer } from './currency';
import { currencyExchangeReducer } from './currency-exchange/currency-exchange.slice';
import { currentUserReducer } from './current-user/current-user.slice';
import { customizationReducer } from './customization';
import { disciplinesReducer } from './disciplines';
import { employeeReducer } from './employee/employee.slice';
import { entityReducer } from './entity/entity.slice';
import { forecastReducer } from './forecast';
import { invoiceManagementReducer } from './invoice-management';
import { invoicePaymentsReducer } from './invoice-payments';
import { ismsClassificationReducer } from './isms-classification/isms-classification.slice';
import { jobTitlesReducer } from './job-titles';
import { leaveRequestReducer } from './leave-request/leave-request.slice';
import { managerDelegationReducer } from './manager-delegation';
import { myProjectsTeamsTimesheetsReducer } from './my-projects-teams-timesheets';
import { notificationsReducer } from './notifications';
import { overtimeRequestReducer } from './overtime-request/overtime-request.slice';
import { pendingEmployeeReducer } from './pending-employees/pending-employees.slice';
import { positionCategoryReducer } from './position-categories/position-category.slice';
import { powerBiParamsReducer } from './power-bi';
import { projectStatusReducer } from './project-status/project-status.slice';
import { projectTeamMemberReducer } from './project-team-employees/project-team-employees.slice';
import { projectVerticalOptionsReducer } from './project-vertical-option';
import { projectWorpakacageManagerDelegationReducer } from './project-workpackages-managers-delegation';
import { projectReducer } from './project/project.slice';
import { qualityRiskLevelReducer } from './quality-risk-level/quality-risk-level.slice';
import { salariesReducer } from './salaries';
import { sellingPriceReducer } from './selling-price/selling-price.slice';
import { workpackageProgressReducer } from './subproject-progress/subproject-progress.slice';
import { timesheetReducer } from './time-sheet/time-sheet.slice';
import { timesheetSettingsReducer } from './timesheet-settings';
import { vatsReducer } from './vat';
import { workpackageReducer } from './workpackage/workpackage.slice';
import { maxBudgetReducer } from './budget-settings';

// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
  customization: customizationReducer,
  appConfiguration: appConfigurationReducer,
  currentUser: currentUserReducer,
  // initialValues: initializeStoreReducer,
  activities: activitiesReducer,
  workpackages: workpackageReducer,
  currencyExchanges: currencyExchangeReducer,
  projects: projectReducer,
  projectTeamsEmployees: projectTeamMemberReducer,
  employees: employeeReducer,
  timesheet: timesheetReducer,
  progresses: workpackageProgressReducer,
  sellingPrices: sellingPriceReducer,
  leaveRequests: leaveRequestReducer,
  positionCategories: positionCategoryReducer,
  disciplines: disciplinesReducer,
  currencies: currencyReducer,
  pendingEmployees: pendingEmployeeReducer,
  entities: entityReducer,
  entityTypes: entityTypeReducer,
  qualityRiskLevels: qualityRiskLevelReducer,
  activityTypes: activityTypeReducer,
  contractTypes: contractTypesReducer,
  vats: vatsReducer,
  timesheetSettings: timesheetSettingsReducer,
  projectStatuses: projectStatusReducer,
  workpackageBudgets: budgetReducer,
  maxBudgets: maxBudgetReducer,
  common: commonReducer,
  salaries: salariesReducer,
  ismsClassifications: ismsClassificationReducer,
  jobTitles: jobTitlesReducer,
  managerDelegations: managerDelegationReducer,
  caslRoles: caslRolesReducer,
  caslRolesPrivileges: caslRolesPrivilegesReducer,
  overtimeRequests: overtimeRequestReducer,
  myProjectsTeamsTimesheets: myProjectsTeamsTimesheetsReducer,
  projectInvoices: invoiceManagementReducer,
  invoicePayments: invoicePaymentsReducer,
  powerBiParams: powerBiParamsReducer,
  notifications: notificationsReducer,
  businessUnits: businessUnitReducer,
  projectVerticalOptions: projectVerticalOptionsReducer,
  projectWorkpackageManagerDelegation: projectWorpakacageManagerDelegationReducer,
  forecasts: forecastReducer
});

export default rootReducer;
