import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: []
};

export const { actions: projectStatusActions, reducer: projectStatusReducer } = createSlice({
  name: 'projectStatus',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getProjectStatuses: (state) => {
      state.loading = true;
    },
    getProjectStatusesFulfilled(state, action) {
      state.all = action.payload.sort((a, b) => a.id - b.id);
    },
    setProjectStatuses: (state, action) => {
      state.all = action.payload;
    }
  }
});
