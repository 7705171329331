import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: false,
  all: [],
  teamEmployees: [],
  manageEmployeesVisible: false
};

export const { actions: projectTeamMemberActions, reducer: projectTeamMemberReducer } = createSlice({
  name: 'projectTeamMembers',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error, action;
    },
    getProjectTeamMembersByProjectId: (state) => {
      state.loading = true;
    },
    getProjectTeamMembersByProjectIdFulfilled(state, action) {
      state.teamEmployees = action.payload;
    },
    getProjectTeamMembersByEmployeeId: (state) => {
      state.loading = true;
    },
    getProjectTeamMembersByEmployeeIdFulfilled(state, action) {
      state.teamEmployees = action.payload;
    },
    removeAllTeamMembers: (state) => {
      state.loading = true;
    },
    removeAllTeamMembersFulfilled(state, action) {
      state.teamEmployees = action.payload;
    },
    getProjectTeamMembersByTeamId: (state) => {
      state.loading = true;
    },
    getProjectTeamMembersByTeamIdFulfilled(state, action) {
      state.teamEmployees = action.payload;
    },
    postProjectTeamMember(state) {
      state.loading = true;
    },
    postProjectTeamMemberFulfilled(state, action) {
      state.teamEmployees.employees = [...state.teamEmployees.employees, ...action.payload.employees];
    },
    patchProjectTeamMembers(state) {
      state.loading = true;
    },
    patchProjectTeamMembersFulfilled(state, action) {},
    deactivateProjectTeamMember(state) {
      state.loading = true;
    },
    deactivateProjectTeamMemberFulfilled(state, action) {
      const newState = state.teamEmployees.employees;
      const index = newState.findIndex((te) => te.projectTeamEmployeeId === action.payload.projectTeamEmployeeId);
      const changedItem = { ...newState[index], ...action.payload };
      state.teamEmployees.employees = [...newState.slice(0, index), changedItem, ...newState.slice(index + 1)];
    },
    deleteProjectTeamMembers(state) {
      state.loading = true;
    },
    deleteProjectTeamMembersFulfilled(state, action) {
      // const newTeamMembers = state.teamEmployees.employees.filter(
      //   (prog) => prog.employeeId !== action.payload.projectTeamEmployeeId
      // );
      const newState = state.teamEmployees.employees;
      const index = newState.findIndex((te) => te.projectTeamEmployeeId === action.payload.projectTeamEmployeeId);
      state.teamEmployees.employees = [...newState.slice(0, index), ...newState.slice(index + 1)];
    },
    toggleManageEmployeesVisible(state, action) {
      state.manageEmployeesVisible = !state.manageEmployeesVisible;
    }
  }
});
