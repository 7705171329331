import { caslHook } from 'casl/can';
import { all, put, take, takeLatest } from 'redux-saga/effects';
import { store } from 'store';
import { fetchSaga } from 'utils/fetch-saga';
import { commonServices } from './common.services';
import { commonActions } from './common.slice';
import { appRelatedData } from './data-loading';

export function* commonRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'setCasls',
      actions: commonActions,
      api: {
        method: commonServices.setCasls,
        callback: (res) => res.data.data
      }
    }),
    takeLatest(commonActions.setCaslsFulfilled.type, commonSaga)
  ]);
}

function* commonSaga(action) {
  const responsibility = store.getState().currentUser.responsibilities;
  const { moduleEnableLeaves } = store.getState().appConfiguration;

  caslHook(action.payload, responsibility);

  const data = yield all([...appRelatedData(moduleEnableLeaves)]);

  yield all(data.filter((d) => d !== false).map((dt) => take(dt?.type + 'Fulfilled')));
  yield put(commonActions.setReadyFulfilled());
}
