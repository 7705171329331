import { all } from 'redux-saga/effects';
import { fetchSaga } from 'utils/fetch-saga';
import { businessUnitServices } from './business-unit.services';
import { businessUnitActions } from './business-unit.slice';

export function* businessUnitsRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getBusinessUnitsAll',
      actions: businessUnitActions,
      api: {
        method: businessUnitServices.getBusinessUnitsAll,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postBusinessUnit',
      actions: businessUnitActions,
      api: {
        method: businessUnitServices.postBusinessUnit,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'patchBusinessUnit',
      actions: businessUnitActions,
      api: {
        method: businessUnitServices.patchBusinessUnit,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'deleteBusinessUnit',
      actions: businessUnitActions,
      api: {
        method: businessUnitServices.deleteBusinessUnit,
        callback: (res) => res.data.data
      }
    })
  ]);
}
