import { useLocation } from 'react-router';
import { useFetchWeekByDate } from '../../hooks';

import { useSelector } from 'react-redux';

import { EmployeeTimesheetWithDays } from '../../components';
import { EmployeeButtons } from '../../components/employee-buttons/employee-buttons';
import { checkEmployeeTimesheetEditable, getTimesheetDisabledActivities, getWeekInfo } from '../../utils';

export const EmployeeTimesheet = () => {
  const {
    state: { weekSettings, theDay }
  } = useLocation();

  const singleApproval = useSelector((state) => state.appConfiguration.timesheetAutoReview);

  const employee = useSelector((state) => state.currentUser.user);

  useFetchWeekByDate(employee.id, theDay);

  const contractType = employee.typeOfContract;
  const dayTargetHours = contractType?.hoursPerDay || 0;

  const week = useSelector((state) => state.timesheet.days);
  const isInOvertime = week?.isInOvertime ?? false;

  const weekInfo = getWeekInfo(weekSettings?.timesheetSettingDays, isInOvertime, dayTargetHours);

  const isEditable = (timesheet) => {
    return checkEmployeeTimesheetEditable(timesheet, weekSettings?.timesheetSettingDays);
  };

  return (
    <EmployeeTimesheetWithDays
      employeeId={employee.id}
      weekInfo={weekInfo}
      dayTargetHours={dayTargetHours}
      footer={<EmployeeButtons employeeId={employee.id} weekSettings={weekSettings?.timesheetSettingDays} withDraft />}
      editable={isEditable}
      pdCommentShown={(timesheet) => {
        return !singleApproval && timesheet && !timesheet.isDraft;
      }}
      disabledActivities={(timesheet) => getTimesheetDisabledActivities(timesheet, weekInfo, isInOvertime)}
    />
  );
};
