import { createContext, useRef, useState } from 'react';
import { calculateWeekHours, calculateWeekTargetHours } from '../utils';

export const TimesheetContext = createContext();

export const TimesheetProvider = ({
  defaultTimesheet,
  timesheet,
  setTimesheet,
  weekInfo,
  dayTargetHours,
  pdCommentShown,
  disabledActivities,
  children
}) => {
  const formRef = useRef();

  const [values, setTimesheetValues] = useState({});

  return (
    <TimesheetContext.Provider
      value={{
        values,
        setTimesheetValues,
        timesheet,
        setTimesheet,
        weekInfo,
        calculateWeekHours: calculateWeekHours.bind(formRef.current?.values || values || {}),
        weekTargetHours: calculateWeekTargetHours(weekInfo, timesheet?.timeSheetEntryPerActivities),
        dayTargetHours,
        pdCommentShown,
        defaultTimesheet,
        disabledActivities,
        formRef
      }}
    >
      {children}
    </TimesheetContext.Provider>
  );
};
