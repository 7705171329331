import axios from 'services/axios-interceptors';
import { API_PATH } from 'store/service';

export const caslRolesServices = {
  getCaslRolesAll() {
    return axios.get(API_PATH + '/caslRole/all');
  },
  postCaslRole(payload) {
    return axios.post(API_PATH + '/caslRole', payload);
  },
  patchCaslRoleName(payload) {
    return axios.patch(API_PATH + '/caslRole', payload);
  },
  patchCaslRoleEmployees(payload) {
    return axios.patch(API_PATH + '/caslRole/caslRoleEmployees', payload);
  },
  patchEmployeeCaslRoles(payload) {
    return axios.patch(API_PATH + '/caslRole/employeeCaslRoles', payload);
  },
  deleteCaslRole(payload) {
    return axios.delete(API_PATH + '/caslRole', { data: { ...payload } });
  }
};
