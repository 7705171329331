// max-budget.slice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  deleteupdate:{},
  user: [],
  year:[],
  updatedUser:[],
};

export const { actions: maxBudgetActions, reducer: maxBudgetReducer } = createSlice({
  name: 'maxBudgets',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    getAllEmployeesAll(state) {
      state.loading = true;
    },
    getAllEmployeesAllFulfilled(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    setSelectedEmployees(state, action) {
      state.loading = true;
      // Other logic if needed
    },
    setSelectedEmployeesFulfilled(state, action) {
      state.loading = false;
      // Other logic if needed
    },
    getYearAll(state){
      state.loading=true;
    },
    getYearAllFulfilled(state,action){
      state.loading=false;
      state.year=action.payload;
    },
    postDataAll(state){
      state.loading=true;
    },
    postDataAllFulfilled(state,action){
      state.loading=false;
      //state.user.push(action.payload);
    },
    deleteData:(state)=>{
      state.loading=true;
    },
    deleteDataFulfilled(state,action){
      //const updatedActivities = state.user.filter((act) => act.id !== action.payload);
      //state.user = [...updatedActivities];
      state.loading=false
      state.deleteupdate=action.payload.message;
    },
    updateUserdata:(state)=>{
      
      state.loading=true
    },
    updatedUserFulfilled(state,action){
      state.loading=false
      state.updatedUser=action.payload
      
    },


    
  },
});

export default maxBudgetReducer;



