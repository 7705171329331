import { entityTypeServices } from './entity-type.services';
import { fetchSaga } from 'utils/fetch-saga';
import { all } from 'redux-saga/effects';
import { entityTypeActions } from './entity-type.slice';

export function* entityTypeRootSaga() {
  yield all([
    fetchSaga({
      pattern: 'getEntityTypes',
      actions: entityTypeActions,
      api: {
        method: entityTypeServices.getEntityTypes,
        callback: (res) => res.data.data
      }
    }),
    fetchSaga({
      pattern: 'postEntityTypes',
      actions: entityTypeActions,
      api: {
        method: entityTypeServices.postEntityTypes,
        callback: (res) => res.data.data
      }
    })
  ]);
}
