import { Divider, List, Typography } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import NavCollapse from '../nav-collapse';
// project imports
import NavItem from '../nav-item';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();

  // menu list collapse & items
  const items = item.children
    ?.filter((item) => !item.isHidden)
    .map((menu) => {
      if (menu) {
        switch (menu.type) {
          case 'collapse':
            return <NavCollapse key={menu.id} menu={menu} level={1} />;
          case 'item':
            return <NavItem key={menu.id} item={menu} level={1} />;
          case 'hidden':
            return '';
          default:
            return (
              <Typography key={menu.id} variant="h6" color="error" align="center">
                Group Items Error
              </Typography>
            );
        }
      }
    });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography
              variant="subtitle2"
              sx={{ ...theme.typography.menuCaption, paddingLeft: '10px' }}
              display="block"
            >
              {item.title}
              {item.caption && (
                <Typography
                  variant="subtitle2"
                  sx={{ ...theme.typography.subMenuCaption }}
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.1, mb: 0.1 }} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
